import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen'
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'slide-menu',
  templateUrl: 'slide-menu.component.html',
  styleUrls: ['slide-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideMenuViewComponent implements OnInit {
  plt;
  constructor(private navCtrl: NavController ) {
    this.plt = localStorage.getItem('platform');
  }

  ngOnInit() {
  }
  goBack() {
    this.navCtrl.back();
  }
}