<ion-header mode="ios">
  <ins *ngIf="iswebMobile" class="adsbygoogle"
            style="display: block;
            height: 50px;
            width: 400px;position: absolute;
            top: 0;"
            data-ad-client="ca-pub-5860375149131207"
            data-ad-slot="6886080818"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
  <ion-img src="assets/imgs/logoreklama.png" class="img-logo" ></ion-img>
  <ion-toolbar>
      <ion-title class="titleHide">Ulubione</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content style="height: 78vh;">
  <div *ngIf="!(showLikedProducts | async)" class="no-liked-products">
    <ion-icon name="close-outline"></ion-icon> Nie masz żadnego ulubionego produktu?
  </div>
  <div *ngIf="showLikedProducts | async">
    <div class="main_content_div">
      <div>
          <div class="card_box" *ngFor="let item of (productList | async)">
              <div class="back_image" [ngStyle]="{'backgroundImage': item?.image?.length < 100 ? 'url(assets/imgs/szoppop.jpg)' : item?.image?.includes('data:image') ? 'url('+ item?.image +')' : 'url(data:image/png;base64,'+ item?.image +')'}" (click)="openProduct(item)"></div>
              <div class="detail_div" (click)="openProduct(item)">
                  <ion-label class="bold_name">{{item.title | slice:0:80}}</ion-label>
                  <ion-label class="addr_lbl" *ngIf="item.site != 'amazon'">{{item.site}}</ion-label>
                  <ion-label class="addr_lbl" *ngIf="(item.site) == 'amazon'">{{item.site}} - {{item.lowest.country | uppercase}}</ion-label>
              </div>
              <div class="last_div">
                  <div class="color_box" (click)="openProduct(item)"><ion-label>{{item.price ?? item.lowest.price}}zł</ion-label></div>
                  <div *ngIf="item.site != 'amazon' && item?.discount != 0" class="color_box_discount" (click)="openProduct(item)"><ion-label>-{{(item.discount * 100).toFixed(0)}}%</ion-label></div>
                  <div *ngIf="item.site == 'amazon' && item?.lowest?.discount != 0" class="color_box_discount" (click)="openProduct(item)"><ion-label>-{{(item.lowest.discount * 100).toFixed(0)}}%</ion-label></div>
                  <ion-icon [name]="iconSelect(item)" class="icon-like" (click)="addLike(item)"></ion-icon>
              </div>
          </div>
          <ion-infinite-scroll (ionInfinite)="doInfinite($event)" style="margin-bottom: 30px;">
              <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Ładuję kolejne produkty...">
              </ion-infinite-scroll-content>
            </ion-infinite-scroll>
      </div>
    </div>
    <ion-refresher slot="fixed" (ionRefresh)="getNextItems(true, $event)" class="refresh_box">
      <ion-refresher-content
        pullingIcon="chevron-down-circle-outline"
        pullingText="Pull to refresh"
        refreshingSpinner="circles"
        refreshingText="Refreshing..."
        class="background_content">
      </ion-refresher-content>
    </ion-refresher>
  </div>
  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button (click)="scrollUp()" class="button-float">
      <ion-icon name="chevron-up-circle"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>