import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'header-view-browser',
  templateUrl: 'header.component.html',
  styleUrls: ['../assets/css/main.css','../assets/css/libs.min.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderBrowserViewComponent {
  plt;
  public user_Username = new BehaviorSubject<string>(localStorage.getItem('UserDetails_USERNAME'));
  @Input() public onClick = () => {};
  constructor() {
  }
}