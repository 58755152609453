<div class="page-wrapper">
    <header class="page-header">
        <div class="page-header__inner">
            <div class="page-header__sidebar">
                <div class="page-header__menu-btn"><button class="menu-btn ico_menu"></button></div>
                <div class="page-header__logo" style="cursor: pointer;" [routerLink]="['/dashboard']"><img src="../../assets/imgs/logoDark.png" alt="logo"></div>
            </div>
            <div class="page-header__content">
                <div class="page-header__search">
                    <div class="search">
                        </div>
                </div>
                <div class="page-header__action">
                    <a class="action-btn" href="https://www.facebook.com/profile.php?id=100087825745624">
                        <ion-icon name="logo-facebook"></ion-icon>
                    </a>
                    <a class="action-btn" href="#!" [routerLink]="['/help']">
                        <i class="ico_help"></i>
                    </a>
                    <a class="action-btn" href="#!" [routerLink]="['/liked']">
                        <i class="ico_favourites"></i>
                    </a>
                    <a [routerLink]="['/profile']" class="profile" href="#!"><img src="assets/imgs/profile.png" alt="profile"></a>
                </div>
          </div>
        </div>
    </header>
    <div class="page-content">
        <aside class="sidebar is-show" id="sidebar">
            <div class="sidebar-box">
              <ul class="uk-nav">
                <li [routerLink]="['/dashboard']"><a href=""><i class="ico_home"></i><span>Strona Główna</span></a></li>
                <li class="uk-nav-header">Popularne</li>
                <li><a href="09_games-store.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnie', main: ''}"><i class="ico_store"></i><span>Ostatnio znalezione</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnidzien', main: 'undefined'}"><i class="ico_report"></i><span>Ostatni dzień</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne3dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 3 dni</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne7dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 7 dni</span></a></li>
                <li class="uk-nav-header">Sklepy</li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaExpert', main: 'undefined'}"><i class="ico_market"></i><span>MediaExpert</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'EuroRtvAgd', main: 'undefined'}"><i class="ico_market"></i><span>EuroRtvAgd</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaMarkt', main: 'undefined'}"><i class="ico_market"></i><span>MediaMarkt</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'XKom', main: 'undefined'}"><i class="ico_market"></i><span>X-Kom</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Amazon', main: 'undefined'}"><i class="ico_market"></i><span>Amazon</span></a></li>
                <li class="uk-nav-header">Pomoc</li>
                <li><a href="11_market.html" [routerLink]="['/help']" [queryParams]=""><i class="ico_market"></i><span>Kontakt</span></a></li>
            </ul>
            </div>
        </aside>
      <main class="page-main">
        <div class="uk-grid" data-uk-grid>
            <div class="uk-width-2-3@l">
                <div class="widjet --profile">
                    <div class="widjet__head">
                        <h3 class="uk-text-lead">Profile</h3>
                    </div>
                    <div class="widjet__body">
                        <div class="user-info">
                            <div class="user-info__avatar" style="background-size: cover;" [style.backgroundImage]="'url(assets/imgs/profile.png)'"></div>
                            <div class="user-info__box">
                                <div class="user-info__title">{{(userData | async).nick}}</div>
                                <div class="user-info__text">{{(userData | async).email}}</div>
                                <div class="user-info__text" style="cursor: pointer;" (click)="logout()">Wyloguj</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="widjet --bio">
                    <div class="widjet__head">
                        <h3 class="uk-text-lead">Generalne</h3>
                    </div>
                    <div class="widjet__body">
                        <div class="widjet__body" (click)="goToEditProfile()">
                            <div class="widjet-game" style="cursor: pointer;">
                                <div class="color_box">
                                    <img src="assets/imgs/settings.png"/>
                                </div>
                                <div class="widjet-game__info">Edytuj profil
                                    <div class="widjet-game__last-played">Edytuj podstawowe funkcje profilu</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="widjet__body">
                        <div class="widjet__body" (click)="goToChangePassword()">
                            <div class="widjet-game" style="cursor: pointer;">
                                <div class="color_box">
                                    <img src="assets/imgs/privacy.png"/>
                                </div>
                                <div class="widjet-game__info"> Zmiana hasła
                                    <div class="widjet-game__last-played">Zmień swoje hasło</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="widjet__body">
                        <div class="widjet__body" (click)="goToInvideCodes()">
                            <div class="widjet-game" style="cursor: pointer;">
                                <div class="color_box">
                                    <img src="assets/imgs/badge_3.png"/>
                                </div>
                                <div class="widjet-game__info"> Kody zaproszenia
                                    <div class="widjet-game__last-played">Kody dla znajomych</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </main>
  </div>