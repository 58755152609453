import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'header-view',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderViewComponent {
  plt;
  public user_Username = new BehaviorSubject<string>(localStorage.getItem('UserDetails_USERNAME'));
  @Input() public onClick = () => {};
  constructor() {
  }
}