import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AdMobService } from '../services/admob/admob.service';
import { AuthService } from '../services/auth/auth.service';
import { PlatformService } from '../services/platform/platform.service';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.component.html',
  styleUrls: ['tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsPage {
  public isMobile = true;
    public subscriptionEvents: Subscription;
    public showTabs = new BehaviorSubject(this.authService.isAuth.getValue() === true);
    constructor(private authService: AuthService, public ad: AdMobService, private platformService: PlatformService) {
        this.subscriptionEvents = this.authService.isAuth.subscribe(item => {
            this.showTabs.next(item);
        });

        this.isMobile = this.platformService.isMobile.getValue();
    }

    ionViewDidLeave(): void {
        this.subscriptionEvents.unsubscribe();
      }
}
