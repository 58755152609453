<ion-content>
  <div class="main_content_div">

      <div class="header_div">
          <div class="flex_header">
              <ion-icon name="arrow-back-outline" (click)="goToBack()"></ion-icon>
              <ion-label>Order Process</ion-label>
          </div>
      </div>

      <div class="content_div">

        <div class="service_box" style="margin-top: -65px;">
            <div class="user_back user_back2" [style.backgroundImage]="'url(assets/payment/payment.png)'"></div>
            <div class="user_detail">
                <ion-label class="name_lbl" style="color: var(--ion-color-primary);">Premium account</ion-label>
                <ion-label class="text_lbl">Upgrade account to PREMIUM status: 1 month</ion-label>
            </div>
        </div>

        <ion-label class="title_lbl">Plans</ion-label>

        <ion-radio-group name="auto" value="ford1" mode="md" [(ngModel)]="planSelected" (ionChange)="checkValue($event)">

            <div class="location_box" style="margin-bottom: 20px;">
                <div class="loc_detail">
                    <div class="light_box light_box2">
                        <ion-icon name="wallet" style="color: #47e77a;"></ion-icon>
                    </div>
                    <div class="loc_content">
                        <ion-label class="bold_lbl">1 month</ion-label>
                        <ion-label class="simp_lbl">Buy to our cat some snack</ion-label>
                    </div>
                    <ion-radio [value]="1"></ion-radio>
                </div>
            </div>

            <div class="location_box" style="margin-bottom: 20px;">
                <div class="loc_detail">
                    <div class="light_box light_box2">
                        <ion-icon name="card" style="color: #26baff;"></ion-icon>
                    </div>
                    <div class="loc_content">
                        <ion-label class="bold_lbl">2 month</ion-label>
                        <ion-label class="simp_lbl">Make our cat happy</ion-label>
                    </div>
                    <ion-radio [value]="2"></ion-radio>
                </div>
            </div>

        </ion-radio-group>

      </div>

  </div>
</ion-content>

<ion-footer>
    <div class="footer_div">
        <div class="left_div">
            <ion-label>Total Payment</ion-label>
            <ion-label>{{planPrice | async}} EUR</ion-label>
        </div>
        <div class="right_div">
            <ngx-paypal [config]="createOrder()"></ngx-paypal>
        </div>
    </div>
</ion-footer>


