<ion-content>
  <div class="main_content_div">

      <div class="white_box">
          <ion-label>hi</ion-label>
      </div>
      <ion-img class="img-background" src="assets/imgs/funny-cat.jpg"></ion-img>
      <div class="abs_div">
          <ion-label>Chcesz zostać rekinem <br> PROMOCJI? <br> Zacznij już teraz :D </ion-label>
          <ion-button (click)="goLogin()">
            Zacznij łowy
          </ion-button>
          <ion-label class="small-label">* jest to wersja Alfa (w czasie testów)</ion-label>
      </div>
      
  </div>
</ion-content>
