import { NavController, Platform } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Category } from 'src/app/models/category/models/categorysEnum.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CategoryFactory } from 'src/app/models/category/category.model';
import { ShopSubCategoryResponse } from 'src/app/models/category/models/dbCategory.model';

@Component({
  selector: 'shops-collections',
  templateUrl: './shops-collections.component.html',
  styleUrls: ['./shops-collections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionsMobileViewComponent implements OnInit, OnDestroy {

  @Input() public category: Category;
  public iswebMobile = this.platform.is('mobileweb');
  public categoryList = new BehaviorSubject<ShopSubCategoryResponse[]>([]);
  public categoryTitle = new BehaviorSubject("");
  public subscriptionEvents: Subscription;
  public loading = new BehaviorSubject<boolean>(true);
    constructor(private platform: Platform, private navCtrl: NavController, private _route: ActivatedRoute, private categoryFactory: CategoryFactory) { }
  
    ngOnDestroy(): void {
      this.subscriptionEvents.unsubscribe();
    }
  
    ngOnInit(): void {
      this.prepare();
    }

    async prepare(){
      this.loading.next(true);
      this.subscriptionEvents = this._route.queryParamMap
        .subscribe((params: any) => {
          this.subscriptionEvents = this.categoryFactory.category.subscribe(item => {
            if(!item){
              return;
            }

            this.categoryFactory.getCategory(params.params.type).then(x =>{
              if(params.params.type == "Popular"){
                this.categoryList.next(x);
              }else{
                var newCatAll: ShopSubCategoryResponse = {
                  title: "Wszystko",
                  Category: "Wszystko",
                  img: "Wszystko"
                }
                this.categoryList.next([newCatAll,...x]);
              }
  
              this.loading.next(false);
            });
            
            this.categoryTitle.next(params.params.type);
          });
        }
      );
    }

    goToBack() {
        this.navCtrl.back();
    }

    prepareImage(item){
      return item.img ?? item.Category?.replace(/\s/g, "");
    }
}
