import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonContent, ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Sites, Countries } from 'src/app/models/dashboard/filter.model';
import { ProductsModel } from 'src/app/models/products/product.model';
import { AdMobService } from 'src/app/services/admob/admob.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { ProductMobileComponent } from 'src/mobile/product-modal/product.component';
@Component({
  selector: 'liked-products',
  templateUrl: 'liked-products.component.html',
  styleUrls: ['../assets/css/main.css','../assets/css/libs.min.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LikedProductsBrowserViewComponent implements OnInit {
  public productList = new BehaviorSubject<ProductsModel[]>([]);
  public connectionCrash = new BehaviorSubject<boolean>(false);
  @ViewChild(IonContent) content: IonContent;
  public showLikedProducts = new BehaviorSubject<boolean>(this.productList.getValue().length > 0 ? true : false);
  page_limit = 40;
  constructor(private route: Router, private admob: AdMobService ,public loaderService: LoaderService, private navCtrl: NavController, private productService: ProductsService, 
    public modalCtrl: ModalController,private alertController: AlertController){
    
  }

  ngOnInit(): void {
    this.getNextItems(true, undefined);
  }

  getPrice(product: ProductsModel){
    if(product.site == Sites.Amazon){
      switch(product.lowest.country){
        case Countries.PL:
          return product.prices.pl.price;
        case Countries.DE:
          return product.prices.de.price;
        case Countries.ES:
          return product.prices.es.price;
        case Countries.FR:
          return product.prices.fr.price;
        case Countries.IT:
          return product.prices.it.price;
        case "co." + Countries.UK:
          return product.prices.uk.price;
      }
    }
    else if(product.site == Sites.MediaMarkt){
      return product.price;
    }else if(product.site == Sites.MediaExpert){
      return product.price;
    }else if(product.site == Sites.xkom){
      return product.price;
    }else if(product.site == Sites.Euro){
      return product.price;
    }
  }

  doInfinite(event: any) {
    this.getNextItems(false, event);
  }

  async getNextItems(isRefresh: boolean, event: any) {
    this.loaderService.createLoader();
    var productsLength = isRefresh ? 0 : this.productList.getValue().length;
    await this.productService.getLikedProduct(productsLength, this.page_limit).then((response) =>{
      if(response == null){
        this.connectionCrash.next(true);
        return;
      }
    
      this.connectionCrash.next(false);
      if(response.length > 0){
        if(isRefresh){
          this.productList.next([...response]);
        }
        else{
          this.productList.next([...this.productList.getValue(), ...response]);
        }
      }else{
        this.productList.next([]);
      }
      this.showLikedProducts.next(this.productList.getValue().length > 0);
      this.loaderService.dimissLoader();
      event?.target?.complete();
    });
  }

  async openProduct(product: ProductsModel){
    this.route.navigate(['product', product._id], { queryParams: { liked: 1 } });
  }

  async addLike(product: ProductsModel){
    await this.productService.addLikedProduct(product).then((response) =>{ 
      if(response >= 0){
        this.productList.next(this.productList.getValue().filter(x => x._id != product._id));
        this.showLikedProducts.next(this.productList.getValue().length > 0);
      }
    });
  }

  goToBack() {
    this.navCtrl.back();
  }

  async ErrorWithSending(info?: string) {
    const alert = await this.alertController.create({
      header: 'Something is wrong',
      message: info,
      buttons: ['Try again'],
    });

    await alert.present();
  }

  iconSelect(product: ProductsModel){
    if(product.liked == 1){
      return "heart-outline";
    }
    return "heart-dislike-outline";
  }

  scrollUp(){
    this.content.scrollToTop(400);
  }
}