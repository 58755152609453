<ion-content>
  <div class="main_content_div">   
    <ion-label class="main_head">Change Password</ion-label>
    
    <div class="form_div" *ngIf="isSending$ | async;else isSending">

      <div class="tri"></div>

      <ion-label>Code</ion-label>
      <ion-input type="text" placeholder="Code" [(ngModel)]="code"></ion-input>
      <ion-label>New Password</ion-label>
      <ion-input type="password" placeholder="Password" [(ngModel)]="password"></ion-input>
      <ion-button expand="block" (click)="sendForgotRequest()">
        Change
      </ion-button>

      <ion-label class="lbl">Don't have an account ? <span (click)="goRegister()" class="signup_span">Sign Up</span></ion-label>
    </div>
    <ng-template #isSending>
      <div class="form_div">
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </div>
    </ng-template>
    
  </div>
</ion-content>