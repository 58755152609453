import { AffiliateModel } from "../models/affiliate.model";

export class KomputronikAffiliate implements AffiliateModel {
    private url: string;
    private pattern = 'https://redirecting2.eu/p/e0N3/gX2H/tI7k?dl_url=$s';
    
    constructor(url: string){
        this.url = url;
    }

    public generateUrl(){
        window.open(this.pattern.replace("$s", encodeURI(this.url)), "_blank");
    }
}