import { Component, NgZone } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen'
import { SocketIOService } from './services/socket-io/socket-io-client.service';
import { Storage } from '@ionic/storage-angular';
import { AlertController, MenuController, NavController, Platform } from '@ionic/angular';
import { AuthService } from './services/auth/auth.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { LoaderService } from './services/loader/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { FcmService } from './services/FTM/ftm.service';
import { AdMobService } from './services/admob/admob.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { DisallowedPage } from './services/admob/page.model';
import { PlatformService } from './services/platform/platform.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  loading = true;
  isMobile = true;
  constructor(
    private socketService: SocketIOService,
    private navCtrl: NavController, 
    public socketIO: SocketIOService, 
    private storage: Storage,
    private platform: Platform,
    private authService: AuthService,
    private so: ScreenOrientation,
    private alertCtrl: AlertController,
    private router: Router,
    private platformService: PlatformService,
    private fcmService: FcmService,
    public loaderService: LoaderService,
    private admob: AdMobService,
    private menuCtrl: MenuController, 
    private zone: NgZone) {
    if(this.platform.is("android") || this.platform.is("ios")){
      this.so.lock(this.so.ORIENTATIONS.PORTRAIT);
    }

    /*App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {
				const domain = 'amazpep.com';

				const pathArray = event.url.split(domain);
				const appPath = pathArray.pop();
				if (appPath) {
					this.router.navigateByUrl(appPath);
				}
			});
		});*/

    if(this.authService.isAuth.getValue() === true){
      this.socketService.createConnection();
      this.authService.autoLoginRequest();
    }

    this.platform.resume.subscribe(async () => {
      this.socketIO.pause = false;
      this.socketIO.newConnect();
    });

    this.platform.pause.subscribe(async () => {
      this.socketIO.pause = true;
    });

    this.platform.backButton.subscribeWithPriority(10, () => {
      if(this.loaderService.getStatus()){
        this.loaderService.dimissLoader();
        return;
      }else if(this.router.url == "/dashboard"){
        this.presentAlert();
        return;
      }

      if(this.platformService.isMobile.getValue()){
        var routeIndex = DisallowedPage.includes(this.router.url);
        if(routeIndex){
          this?.admob?.hideBanner();
        }else{
          if(this?.admob?.showingBanner?.getValue() == false){
              this?.admob?.showBaner();
          }
        }
      }

      this.navCtrl.back();
    });

    this.platform.ready().then(() => {
      this.fcmService.initPush();
      this.initializeApp();
    });
    
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: "Informacja",
      message: "Czy na pewno chcesz zamknąć aplikacje?",
      buttons: [
        {
          text: 'Nie',
          handler: (Cancle) => {
          }
        }, {
          text: 'Tak',
          handler: () => {
            navigator['app'].exitApp();
          }
        }
      ]
    });

    await alert.present();
  }

  async initializeApp() {
    SplashScreen.hide();
    await this.storage.create();
    this.menuCtrl.enable(false, 'menu4');
  }

  private initNotification(){
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        alert('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }
}
