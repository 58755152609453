import { PayPalService } from '../../services/paypal/paypal.service';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommentModel } from 'src/app/models/products/product.model';
import { ProductsService } from 'src/app/services/products/products.service';
import { AlertHelper } from 'src/app/helpers/alert-helper';
@Component({
  selector: 'comment-menu',
  templateUrl: 'comment-menu.component.html',
  styleUrls: ['comment-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentMenuViewComponent implements OnInit {
  plt;
  @Input() public onDelete = (comment: CommentModel) => {};
  @Input() public onEdit = (comment: CommentModel) => {};
  @Input() public comment: CommentModel;
  constructor(private productService: ProductsService, private navCtrl: NavController, public authService: AuthService,
    public viewCtrl: ModalController, public paypal: PayPalService, private alertHelper: AlertHelper) {
    this.plt = localStorage.getItem('platform');

  }

  ngOnInit() {
  }

  goBack() {
    this.navCtrl.back();
  }

  async deleteComment(){
    this.productService.deleteComment(this.comment).then(x =>{
      if(!x){
        this.alertHelper.presentDefaultAlert("Delete error", "Comment couldn't be deleted");
        return;
      }
      
      this.onDelete(this.comment);
    });
  }

  async editComment(){
    this.onEdit(this.comment);
  }
}