import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
@Component({
  selector: 'payment-error',
  templateUrl: 'payment-error.component.html',
  styleUrls: ['payment-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentErrorViewComponent implements OnInit {
  constructor(private navCtrl: NavController) { }

  goOrder() {
    this.navCtrl.navigateForward('payment');
  }

  ngOnInit() {
  }

}