import { Injectable, ViewChild } from '@angular/core';
import {
  Plugins,
  Capacitor
} from '@capacitor/core';
import { NavigationExtras, Router } from '@angular/router';
import { ActionPerformed, Token } from '@capacitor/push-notifications';
import { IonNav, NavController } from '@ionic/angular';
import { AuthService } from '../auth/auth.service';
import { SocketIOService } from '../socket-io/socket-io-client.service';

const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(private router: Router, 
    private navCtrl: NavController, 
    private auth: AuthService,
    private socket: SocketIOService) { }
    @ViewChild(IonNav) nav: IonNav;
  initPush() {
    if (Capacitor.platform !== 'web') {
        if(this.auth.isAuth.getValue()){
            this.registerPush();
        }else{
            this.auth.isAuth.subscribe(x => {
                if(x){
                    this.registerPush();
                }
            });
        }
    }
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
        var tokenFcm = localStorage.getItem('FCM_TOKEN');
        if(!tokenFcm){
            PushNotifications.register();
        }
    });

    PushNotifications.addListener(
      'registration',
      (token: Token) => {
        var tokenFCM = JSON.stringify(token);
        this.socket.getSocket().emit('initFcmToken', tokenFCM);
        localStorage.setItem('FCM_TOKEN', tokenFCM);
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: ActionPerformed) => {
      }
    );

    PushNotifications.addListener(
        'pushNotificationActionPerformed',
        async (notification: ActionPerformed) => {
          const data = notification.notification.data;
          if (data.id) {
            let navigationExtras: NavigationExtras = {
                queryParams: {
                  prod: data.id
                }
              };
            this.navCtrl.navigateRoot(['share'], navigationExtras);
          }
        }
      );
  }
}