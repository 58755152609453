<div class="comment-main-content">
  <div *ngIf="!(showComments | async)" class="no-comments">
    <ion-icon name="close-outline"></ion-icon> Jeszcze nikt nie zostawił komentarza, bądź pierwszy.
  </div>
  <div *ngIf="showComments | async">
    <ion-content style="height: 46vh;">
      <div class="main_content_div" style="height: 50vh;">
          <div class="timeline">
            <li *ngFor="let comment of (commentsList | async)">
              <div class="last_div">
                <ion-button (click)="sendCommentVote(comment, 1)" fill="clear">
                  <ion-icon (click)="sendCommentVote(comment, 1)" slot="icon-only" name="add" [class.vote-up]="comment.votes.voted == 1"></ion-icon>
                </ion-button>
                <div class="text_div">
                  <div class="lbl_div">{{comment.votes.ups - comment.votes.downs}}</div>
                </div>
        
                <ion-button (click)="sendCommentVote(comment, -1)" fill="clear">
                  <ion-icon (click)="sendCommentVote(comment, -1)" slot="icon-only" name="remove" [class.vote-down]="comment.votes.voted == -1"></ion-icon>
                </ion-button>
              </div>
              <div>
                <ion-button class="edit-comment" slot="start" fill="clear" (click)="showPopover($event, comment)">
                  <ion-icon name="ellipsis-vertical-outline" style="color: black;"></ion-icon>
                </ion-button>
              </div>
              <div class="timeline-item">
                <h5>{{comment.userId}}</h5>
                <p style="  max-width: 80%;">{{comment.message | slice:0:400}}</p>
                <div class="timeline-stats">
                  <span><i class="ion-ios-clock"></i>{{comment.postDate | dateAsAgo}} {{comment.isEdited ? "(Edytowany)" : ""}}</span>
                </div>
              </div>
            </li>
        </div>
      </div>
      <ion-infinite-scroll (ionInfinite)="doInfinite($event)" style="height: 10px;">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Ładuję kolejne komentarze...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
      <ion-refresher slot="fixed" (ionRefresh)="getNextItems(true, $event)" class="refresh_box">
        <ion-refresher-content
          pullingIcon="chevron-down-circle-outline"
          pullingText="Pull to refresh"
          refreshingSpinner="circles"
          refreshingText="Refreshing..."
          class="background_content">
        </ion-refresher-content>
      </ion-refresher>
    </ion-content>
  </div>
</div> 
