import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { AdMobService } from 'src/app/services/admob/admob.service';
import { CategoryFactory } from 'src/app/models/category/category.model';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { SearchTags, ShopSubCategoryResponse } from 'src/app/models/category/models/dbCategory.model';
import { BannerList } from 'src/app/models/dashboard/banner.model';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { ValidationHelper } from 'src/app/helpers/validation-helper';

@Component({
  selector: 'help',
  templateUrl: 'help.component.html',
  styleUrls: ['../assets/css/main.css','../assets/css/libs.min.css', "./help.components.scss"],
})
export class HelpBrowserViewComponent implements OnInit {
  constructor(
    public categoryFactory: CategoryFactory,
    private admob: AdMobService,
    public loaderService: LoaderService,
    private navCtrl: NavController,
    private productService: ProductsService,
    private alertHelper: AlertHelper,
    private validationHelper: ValidationHelper) { }
  
  public searchTagsList = new BehaviorSubject<SearchTags[]>([]);
  public searchTags = new BehaviorSubject<SearchTags[]>([]);
  public searchTagsLoading = new BehaviorSubject<boolean>(true);
  public startSearching = new BehaviorSubject<boolean>(false);
  public searchCategoryLoading = new BehaviorSubject<boolean>(true);
  public popularCategory = new BehaviorSubject<ShopSubCategoryResponse[]>([]);
  public bannerList = new BehaviorSubject<BannerList[]>([]);
  public searchTextValue = "";

  public NameAndSurname: string = "";
  public Email: string = "";
  public Message: string = "";

  ngOnInit(): void {
    this.startSearching.next(false);
    this.searchTextValue = "";
    
    if(this.popularCategory.getValue().length <= 0){
      this.searchCategoryLoading.next(true);
      this.loaderService.createLoader();
      this.prepareStart();
    }else{
      this.searchTags.next(this.searchTagsList.getValue().slice(0, 10));
    }
    
    //document.getElementById('searchList').style.visibility = "hidden";
  }

  openApp(){
    window.open("https://play.google.com/store/apps/details?id=io.amazpep.version", "_blank");
  }

  tagInfinite(ev){
    var len = this.searchTags.getValue().length;
    this.searchTags.next([...this.searchTags.getValue(),...this.searchTagsList.getValue().filter(x => x.Tag.toLowerCase().includes(this.searchTextValue.toLowerCase())).slice(len, len + 10)]);
    (ev as any).target.complete()
  }
  
  prepareStart(){
    this.searchTextValue = "";
    this.categoryFactory.category.subscribe(x => {
      if(x.length <= 0){
        return;
      }

      var tegs = this.categoryFactory.getAllTags();
      this.searchTagsList.next(tegs);
      this.searchTags.next(tegs.slice(0, 10));
      this.searchTagsLoading.next(false);
      this.loaderService.dimissLoader();
    });
    
    this.searchCategoryLoading.next(false);
    this.productService.getBannerList().then(x => {
      this.bannerList.next(x);
      this.loaderService.dimissLoader();
    });
    this.popularCategory.next(this.categoryFactory.getPopularCategory());
  }

  prepareImage(item){
    return (item.Category ?? item.img).replace(/\s/g, "");
  }

  startSearch(){
    document.getElementById("searchBar").classList.add("active");
  }

  focusSearch(){
    this.startSearching.next(true);
    document.getElementById('searchList').style.visibility = "visible";
  }

  unfocusSearch(){
    this.startSearching.next(false);
    document.getElementById('searchList').style.visibility = "hidden";
  }

  public onKeySearch(event: any) {
    this.searchTag(event.target.value);
  }

  public onEnter(){
    this.startSearching.next(true);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        type: "Wszystko",
        main: this.searchTextValue,
        tag: this.searchTextValue
      }
    };
    this.navCtrl.navigateForward(['list'], navigationExtras)
  }

  private searchTag(search: string){
    this.searchTags.next(this.searchTagsList.getValue().filter(x => x.Tag.toLowerCase().includes(search.toLowerCase())));
    if(this.searchTags.getValue().length <= 0){
      this.searchTags.next([{
        Category: "Wszystko",
        Tag: this.searchTextValue,
        Main: this.searchTextValue,
      }]);
    }
  }

  public async sendMessage(){
    var isSenderValid = this.validationHelper.validateSender(this.NameAndSurname);
    if(!isSenderValid) return;
    var isEmailValid = this.validationHelper.validateEmail(this.Email);
    if(!isEmailValid) return;
    var isMessageValid = this.validationHelper.validateMessage(this.Message);
    if(!isMessageValid) return;

    if(isSenderValid && isEmailValid && isMessageValid){
      var sendMessageResponse = await this.productService.sendMessage(this.NameAndSurname, this.Email, this.Message);
      switch(sendMessageResponse){
        case -1: 
          this.alertHelper.presentDefaultAlert("", "Nieudało się wysłać wiadomości, spróbuj ponownie za chwilę.", ["Ok"]);
        break;
        case -2: 
          this.alertHelper.presentDefaultAlert("", "Podczas wysyłania wiadomości wystąpił błąd, spróbuj ponownie za chwilę.", ["Ok"]);
        break;
        case 1: 
          this.alertHelper.presentDefaultAlert("", "Wiadomość została wysłana, Dziękujemy za kontakt.", ["Ok"]);
          this.NameAndSurname = "";
          this.NameAndSurname = "";
          this.NameAndSurname = "";
        break;
        default:
          this.alertHelper.presentDefaultAlert("Błąd wiadomości", sendMessageResponse.toString(), ["Ok"]);
      }
    }
  }
}
