import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'payment-success',
  templateUrl: 'payment-success.component.html',
  styleUrls: ['payment-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSuccessViewComponent implements OnInit {
  constructor(private navCtrl: NavController, private authService: AuthService) { }

  goDashboard() {
    this.authService.isPremium.next(1);
    this.navCtrl.navigateForward('dashboard');
  }

  ngOnInit() {
  }

}