export class CountryFilter {
    public country: string = "";
    public checked: boolean = true;
}

export class SiteFilter {
    public site: string = "";
    public checked: boolean = true;
}

export class CategoryModel {
    public id: string = "";
    public name: string = "";
}

export class SortByCategory {
    public id: number = 0;
    public name: string = "";
}

export const getSiteFilter = () :SiteFilter[] => {
    return [
        {  site: Sites.Amazon, checked: true},
        {  site: Sites.MediaMarkt, checked: true},
        {  site: Sites.MediaExpert, checked: true},
        {  site: Sites.xkom, checked: true},
        {  site: Sites.Euro, checked: true},
    ]
}

export const getSiteFilterWithAll = () :SiteFilter[] => {
    return [
        {  site: Sites.Amazon, checked: true},
        {  site: Sites.MediaMarkt, checked: true},
        {  site: Sites.MediaExpert, checked: true},
        {  site: Sites.xkom, checked: true},
        {  site: Sites.Euro, checked: true},
    ]
}

export const getSortBy = () :SortByCategory[] => {
    return [
        {id: 0, name: "Brak"},
        {id: 1, name: "Zniżka malejąco"},
        {id: 2, name: "Zniżka rosnąco"},
        {id: 3, name: "Najwyższej ceny"},
        {id: 4, name: "Najniższej ceny"}
    ]
}

export const getCountryFilter = () :CountryFilter[] => {
    return [
        {country: Countries.PL, checked: true}, 
        {country: Countries.DE, checked: true},
        {country: Countries.ES, checked: true},
        {country: Countries.FR, checked: true},
        {country: Countries.IT, checked: true},
        {country: Countries.UK, checked: true},
    ]
}

export const getCategories  = () :CategoryModel[] => {
    return [
        {  id: "all", name: "All"},
        {  id: "none", name: "None"},
        {  id: "electronics", name: "Electronics"},
        {  id: "it", name: "IT"},
        {  id: "rtvagd", name: "RtvAgd"},
        {  id: "food", name: "Food"},
        {  id: "cosmetics", name: "Cosmetics"},
        {  id: "accesories", name: "Accesories"},
        {  id: "headphones", name: "Headphones"},
        {  id: "cleaningproducts", name: "Cleaning products"},
    ]
}

export const getCategoriesExceptAll  = () :CategoryModel[] => {
    let categories = getCategories();
    return categories.slice(1,categories.length);
}

export enum Sites {
    All = "all",
    Amazon = 'amazon',
    MediaMarkt = "mediamarkt",
    MediaExpert = "mediaexpert",
    Euro = "rtveuroagd",
    xkom = "xkom",
};

export enum Countries {
    PL = "pl",
    DE = "de",
    ES = "es",
    FR = "fr",
    IT = "it",
    UK = "uk",
}