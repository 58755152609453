<ion-header mode="ios">
    <ins *ngIf="iswebMobile" class="adsbygoogle"
            style="display: block;
            height: 50px;
            width: 400px;position: absolute;
            top: 0;"
            data-ad-client="ca-pub-5860375149131207"
            data-ad-slot="6886080818"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
    <ion-img src="assets/imgs/logoreklama.png" class="img-logo" ></ion-img>
    <ion-toolbar>
        <ion-button (click)="goToBack()" slot="start" fill="clear">
            <ion-icon name="arrow-back-sharp" style="color: black;"></ion-icon>
        </ion-button>
        <ion-title>{{categoryTitle | async}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="main_content_div">

        <div class="content_div" style="margin-bottom: 100px;">
            <ion-row *ngIf="!(loading | async);else loadingScreen">
                <ion-col size="6" 
                *ngFor="let item of (categoryList | async); let i = index" 
                [class.left-pad]="i % 2 == 1" [class.right-pad]="i % 2 == 0"
                >
                    <div class="back_img" 
                    [style.backgroundImage]="'url(assets/imgs/'+ prepareImage(item) +'.jpg)'"
                    [routerLink]="['/list']" 
                    [queryParams]="{ type: ''+item.Category+'', main: ''+categoryTitle.getValue()+''}">
                        <div class="grey_div">
                            <div class="abs_div">
                                <div class="yellow_div"></div>
                                <ion-label class="bold_lbl">{{item.Category}}</ion-label>
                            </div>
                        </div>
                    </div>
                </ion-col>
            </ion-row>

            <ng-template #loadingScreen>
                <div class="loadingSpinner">
                    <ion-spinner></ion-spinner>
                  </div>
            </ng-template>
        </div>

    </div>
</ion-content>
