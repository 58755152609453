import { Component } from "@angular/core"

export enum GuardEnum {
    Full,
    Guest,
    Auth,
    Share
}

export class Guard {
    type: GuardEnum;
    serviceRef: any;
}

export class RouteModel {
    id: string;
    componentRef: any;
    guard: GuardEnum
}