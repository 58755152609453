import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { ValidationHelper } from 'src/app/helpers/validation-helper';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'forgot',
  templateUrl: 'forgot.component.html',
  styleUrls: ['forgot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotMobileViewComponent implements OnInit {

  plt;
  public email: string;
  public isSending = new BehaviorSubject<boolean>(true);
  public isSending$ = new Observable<boolean>();
  public isLoged = this.authService.isAuth;
  constructor(private navCtrl: NavController,  
    public authService: AuthService,
    private alertHelper: AlertHelper,
    private validationHelper: ValidationHelper) {

    this.plt = localStorage.getItem('platform');
  }

  ngOnInit() {
    this.isSending$ = this.isSending.asObservable();
  }

  async sendForgotRequest(){
    if(!this.checkInputs()){
      return;
    }

    this.isSending.next(!this.isSending.getValue());
    var result = await this.authService.createSendCodeForgotRequest(this.email);
    
    /*if(!!result === true){
      this.navCtrl.navigateRoot('password-change');
    }else{
      this.alertHelper.presentDefaultAlert("Insufficient data", result as string, ["Try again"]);
    }*/

    if(result === true){
      this.navCtrl.navigateRoot('password-change');
    }else if(result === false || result === null){
      this.alertHelper.presentDefaultAlert("Something is wrong", "Check all data or connection", ["Try again"]);
    }
    else{
      this.alertHelper.presentDefaultAlert("Insufficient data", result as string, ["Try again"]);
    }

    this.isSending.next(!this.isSending.getValue());
  }
  
  goRegister()  {
    this.navCtrl.navigateForward('register');
  }

  goLogin()  {
    this.navCtrl.navigateForward('login');
  }

  async handleButtonClick() {
    this.alertHelper.presentDefaultAlert("Something broken", "Your username or password seem to be broken :(", ["Register again"]);
  }

  checkInputs(){
    if(!this.validationHelper.validateEmail(this.email))
    {
      return false;
    }

    return true;
  }
}