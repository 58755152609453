<!-- <ion-header>
  <ion-toolbar color="secondary">
    <ion-title>login-ex1</ion-title>
  </ion-toolbar>
</ion-header> -->

<ion-content>
  <ng-template #processContainer></ng-template>
  <div class="main_content_div" *ngIf="isMobile">
    <ion-label class="main_head">Sign Up</ion-label>
    <div class="form_div" *ngIf="!(isSending$ | async);else isSending">

      <div class="tri"></div>

      <ion-label>Nazwa konta</ion-label>
      <ion-input type="text" [(ngModel)]="userModel.nickname" placeholder="Nickname"></ion-input>
      <ion-label>Email</ion-label>
      <ion-input type="text" [(ngModel)]="userModel.email" placeholder="Email"></ion-input>
      <ion-label>Hasło</ion-label>
      <ion-input type="password" [(ngModel)]="userModel.password" placeholder="Password"></ion-input>
      <ion-label>Kod zaproszenia</ion-label>
      <ion-input type="text" [(ngModel)]="userModel.inviteCode" placeholder="Invite Code"></ion-input>
      <ion-button expand="block" (click)="sendRegisterRequest()">Sign Up</ion-button>

      <ion-label class="lbl">Masz już konto ? <span style="cursor: pointer;" (click)="goLogin()" class="signup_span">Login</span></ion-label>
    </div>

    <ng-template #isSending>
      <div class="form_div">
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </div>
    </ng-template>
  </div>
</ion-content>