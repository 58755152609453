<div class="page-wrapper">
    <header class="page-header">
        <div class="page-header__inner">
            <div class="page-header__sidebar">
                <div class="page-header__menu-btn"><button class="menu-btn ico_menu"></button></div>
                <div class="page-header__logo" style="cursor: pointer;" [routerLink]="['/dashboard']"><img src="../../assets/imgs/logoDark.png" alt="logo"></div>
            </div>
            <div class="page-header__content">
                <div class="page-header__search">
                    <div class="search">
                    </div>
                </div>
                <div class="page-header__action">
                    <a class="action-btn" href="https://www.facebook.com/profile.php?id=100087825745624">
                        <ion-icon name="logo-facebook"></ion-icon>
                    </a>
                    <a class="action-btn" href="#!" [routerLink]="['/help']">
                        <i class="ico_help"></i>
                    </a>
                    <a class="action-btn" href="#!" [routerLink]="['/liked']">
                        <i class="ico_favourites"></i>
                    </a>
                    <a [routerLink]="['/profile']" class="profile" href="#!"><img src="assets/imgs/profile.png" alt="profile"></a>
                </div>
            </div>
        </div>
    </header>
    <div class="page-content">
        <aside class="sidebar is-show" id="sidebar">
            <div class="sidebar-box">
              <ul class="uk-nav">
                <li [routerLink]="['/dashboard']"><a href=""><i class="ico_home"></i><span>Strona Główna</span></a></li>
                <li class="uk-nav-header">Popularne</li>
                <li><a href="09_games-store.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnie', main: ''}"><i class="ico_store"></i><span>Ostatnio znalezione</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnidzien', main: 'undefined'}"><i class="ico_report"></i><span>Ostatni dzień</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne3dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 3 dni</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne7dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 7 dni</span></a></li>
                <li class="uk-nav-header">Sklepy</li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaExpert', main: 'undefined'}"><i class="ico_market"></i><span>MediaExpert</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'EuroRtvAgd', main: 'undefined'}"><i class="ico_market"></i><span>EuroRtvAgd</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaMarkt', main: 'undefined'}"><i class="ico_market"></i><span>MediaMarkt</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'XKom', main: 'undefined'}"><i class="ico_market"></i><span>X-Kom</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Amazon', main: 'undefined'}"><i class="ico_market"></i><span>Amazon</span></a></li>
                <li class="uk-nav-header">Pomoc</li>
                <li><a href="11_market.html" [routerLink]="['/help']" [queryParams]=""><i class="ico_market"></i><span>Kontakt</span></a></li>
            </ul>
            </div>
        </aside>
        <main class="page-main">
            <ul class="uk-breadcrumb">
                <li (click)="goBack()" style="cursor: pointer;"><ion-icon name="chevron-back-outline"></ion-icon><span>Cofnij</span></li>
            </ul>
            <div *ngIf="(product | async)">
                <h3 class="uk-text-lead">{{(product | async).title}}</h3>
                <div class="uk-grid uk-grid-small" data-uk-grid>
                    <div class="uk-width-3-3@xl">
                        <div class="gallery">
                            <div class="js-gallery-big gallery-big">
                                <img class="bg-img" style="object-fit: scale-down;" defaultImage="assets/imgs/szoploading.gif" [lazyLoad]="(product | async)?.image">
                            </div>
                        </div>
                    </div>

                    <div class="uk-width-2-3@xl">
                        <div *ngIf="(product | async)?.site != 'amazon'">
                            <div *ngIf="isGuest;else showGuestDiagram">
                                <div class="month_div">
                                    <ion-select interface="popover" [(ngModel)]="selectedChartFilter"
                                        (ionChange)="changeFilterChart()" class="popover_div">
                                        <ion-select-option value="w">2 Tygodnie</ion-select-option>
                                        <ion-select-option value="m">Miesiąc</ion-select-option>
                                        <!--<ion-select-option value="6m">6 Miesięcy</ion-select-option>
                          <ion-select-option value="r">Rok</ion-select-option>-->
                                    </ion-select>
                                </div>
                                <div *ngIf="isGuest;else showGuestDiagram">
                                    <div *ngIf="!(loadingChart | async);else chartLoading">
                                        <canvas baseChart style="width: 100%;" height="250" [data]="lineChartData"
                                            [labels]="lineChartLabels" [options]="lineChartOptions"
                                            [legend]="lineChartLegend" [type]="lineChartType">
                                        </canvas>
                                    </div>
                                    <ng-template #chartLoading>
                                        <div class="loadingSpinner">
                                            <ion-spinner></ion-spinner>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        
                        <div class="container" id="app" *ngIf="this.countryPrices?.length > 0">
                            <div class="board">
                                <h2 class="ion-row-header">Leaderboard</h2>
                                <div *ngFor="let countryItem of this.countryPrices">
                                    <li class="cat-item"
                                        *ngIf="countryItem.price > 0 && countryItem.price < 100000000000"
                                        (click)="openUrlAmazon(product, countryItem.name)">
                                        <div class="cat-item__photo">
                                            <ion-img [src]="getCountrFlag(countryItem.name)"></ion-img>
                                        </div>
                                        <div class="cat-item__info">
                                            <h4>{{ countryItem.fullName }}</h4>
                                        </div>
                                        <div class="cat-item__points">
                                            <p>{{ countryItem.price }}</p>
                                        </div>
                                    </li>
                                </div>
                            </div>
                        </div>  
                        <ng-template #showGuestDiagram>
                            <div *ngIf="!(showComments | async)" class="no-comments" style="padding: 50px;">
                                <ion-icon name="close-outline"></ion-icon> Wykres cen ukryty-Zaloguj się!
                            </div>
                        </ng-template>
                    </div>
                    <div class="uk-width-1-3@xl" *ngIf="(product | async)?.site != 'amazon';else amazonsite">
                        <div class="game-profile-card">
                            <ul class="game-profile-card__list">
                                <li>
                                    <div>Strona:</div>
                                    <div>{{getSite()}}</div>
                                </li>
                                <li>
                                    <div>Zniżka:</div>
                                    <div style="color: red;" *ngIf="(product | async)?.discount != 0">-{{((product | async)?.discount * 100).toFixed(0)}}%</div>
                                    <div style="color: red;" *ngIf="(product | async)?.discount == 0">Brak promocji</div>
                                </li>
                                <li>
                                    <div>Aktualizacja:</div>
                                    <div>{{(product | async)?.searchDate | date: 'dd/MM/yyyy'}}</div>
                                </li>
                                <ul class="game-profile-card__type">
                                    <li>
                                        <ion-button *ngIf="isGuest" (click)="generateToken()" slot="end" fill="clear">
                                            <ion-icon name="share-social-outline"></ion-icon>
                                        </ion-button>
                                        <ion-button *ngIf="isGuest" (click)="addLike()" fill="clear">
                                            <ion-icon (click)="addLike()" [name]="likeIcon | async"
                                                style="color: black;"></ion-icon>
                                        </ion-button>
                                    </li>
                                </ul>
                            </ul>

                            <div class="detail_flex votes-panel">
                                <div class='voting'>
                                  <ion-buttons (click)="sendVote(0)">
                                    <ion-icon style="color: red;" [class.active-down]="(voteModel | async).voted == 0" class='upButton vote' name="remove-outline"></ion-icon>
                                  </ion-buttons>
                                  <p style="margin-top: 2px;" class='votesCounter'>{{ (voteModel | async)?.voteSum?.sum ?? 0 }}</p>
                                  <ion-buttons (click)="sendVote(1)">
                                    <ion-icon style="color: green;" [class.active-up]="(voteModel | async).voted == 1" class='active-up downButton vote' name="add"></ion-icon>
                                  </ion-buttons>
                                </div>
                              </div>
                        </div>
                        <div class="game-profile-price">
                            <span *ngIf="(product | async)?.available == 1;else noavailable">
                                <div>Cena promocyjna</div>
                                <div class="game-profile-price__value">{{((product | async).price).toFixed(2)}}zł</div>
                                <div>Cena bez promocji</div>
                                <div class="game-profile-price__value">{{((product | async).oryginalPrice).toFixed(2)}}zł
                                </div>
                                <button class="uk-button uk-button-danger uk-width-1-1" type="button"
                                    (click)="openUrl(product)"><span
                                        class="ico_shopping-cart"></span><span>Otwórz</span></button>
                            </span>
                            <ng-template #noavailable>
                                <div style="color: red;">Produkt niedostępny</div>
                                <div>Ostatnia cena</div>
                                <div class="game-profile-price__value">{{((product | async).price).toFixed(2)}}zł</div>
                                <button class="uk-button uk-button-danger uk-width-1-1" type="button"
                                (click)="openUrl(product)"><span
                                    class="ico_shopping-cart"></span><span>Otwórz</span></button>
                            </ng-template>
                            
                        </div>
                    </div>
                    <ng-template #amazonsite>
                        <div class="uk-width-1-3@xl">
                            <div class="game-profile-card">
                                <ul class="game-profile-card__list">
                                    <li>
                                        <div>Strona:</div>
                                        <div>{{getSite()}}</div>
                                    </li>
                                    <li>
                                        <div>Zniżka:</div>
                                        <div style="color: red;">-{{((product | async)?.lowest.discount *
                                            100).toFixed(0)}}%</div>
                                    </li>
                                    <li>
                                        <div>Aktualizacja:</div>
                                        <div>{{(product | async)?.searchDate | date: 'dd/MM/yyyy'}}</div>
                                    </li>
                                    <ul class="game-profile-card__type">
                                        <li>
                                            <ion-button *ngIf="isGuest" (click)="generateToken()" slot="end" fill="clear">
                                                <ion-icon name="share-social-outline"></ion-icon>
                                            </ion-button>
                                            <ion-button *ngIf="isGuest" (click)="addLike()" fill="clear">
                                                <ion-icon (click)="addLike()" [name]="likeIcon | async"
                                                    style="color: black;"></ion-icon>
                                            </ion-button>
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                            <div class="game-profile-price">
                                <div>Cena</div>
                                <div class="game-profile-price__value">{{((product |
                                    async)?.lowest?.price).toFixed(2)}}zł</div>
                                <button class="uk-button uk-button-danger uk-width-1-1" type="button"
                                    (click)="openUrlAmazon(product)"><span
                                        class="ico_shopping-cart"></span><span>Otwórz</span></button>
                            </div>
                        </div>
                    </ng-template>
                    <div class="game-profile-price" style="width: 65.6%;
                    margin-left: 1%;">
                        <div style="font-size: large;">Komentarze</div>
                        <div class="uk-width-2-3@l" style="width: 100%;" *ngIf="isGuest;else isGuessComment">
                            <div class="chat-messages-box">
                                <div class="chat-messages-head">
                                    <div class="user-item">
                                    </div>
                                    <div style="cursor: pointer;">
                                        <ion-icon (click)="getComments(true, undefined);" style="font-size: 25px;margin-bottom: 15px;" name="refresh-outline"></ion-icon>
                                    </div>
                                </div>
                                <div class="chat-messages-body">
                                    <ion-content style="height: 50vh;" *ngIf="(commentsList | async)?.length > 0;else showGuestInfo">
                                        <div *ngFor="let comment of (commentsList | async)">
                                            <div class="messages-item --your-message" style="margin-top: 10px;" *ngIf="comment.userId != (userData | async).nick;else userCurrent">
                                                <div class="messages-item__text">
                                                    {{comment.message | slice:0:400}}
                                                    <div style="white-space: nowrap;
                                                    font-size: 10px;">
                                                        <span class="bold">{{comment.userId}}</span>, 
                                                        <span>{{comment.postDate | dateAsAgo}} {{comment.isEdited ? "(Edytowany)" : ""}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-template #userCurrent>
                                                <div class="messages-item --friend-message" style="margin-right: 30px;margin-top: 10px;">
                                                    <div class="messages-item__text">
                                                    {{comment.message | slice:0:400}}
                                                    <div style="white-space: nowrap;
                                                    font-size: 10px;">
                                                        <span class="bold">{{comment.userId}}</span>, 
                                                        <span>{{comment.postDate | dateAsAgo}} {{comment.isEdited ? "(Edytowany)" : ""}}</span>
                                                    </div>
                                                    </div>
                                                    <ion-button class="edit-comment" slot="start" fill="clear" (click)="showPopover($event, comment)">
                                                        <ion-icon name="ellipsis-vertical-outline" style="color: black;"></ion-icon>
                                                      </ion-button>
                                                </div>
                                            </ng-template>

                                        </div>
                                        <ion-infinite-scroll (ionInfinite)="doInfinite($event)" style="height: 10px;">
                                            <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Ładuję kolejne komentarze...">
                                            </ion-infinite-scroll-content>
                                          </ion-infinite-scroll>
                                    </ion-content>
                                    
                                <ng-template #showGuestInfo>
                                    <div *ngIf="!(showComments | async)" class="no-comments">
                                    <ion-icon name="close-outline"></ion-icon> Brak komentarzy
                                    </div>
                                </ng-template>
                               
                                </div>
                                
                                <div class="chat-messages-footer">
                                    <div class="chat-messages-form">
                                        <div class="chat-messages-form-controls"><ion-input (keyup.enter)="addComment()" type="text" [attr.maxlength]="200" [(ngModel)]="messageComment" placeholder="Napisz komentarz.."></ion-input></div>
                                        <div class="chat-messages-form-btn"><button style="width: 35px;" (click)="addComment()" type="button"><ion-icon style="font-size: 25px;" name="navigate"></ion-icon></button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <ng-template #isGuessComment>
                        <div class="uk-width-2-3@l" style="width: 100%;">
                            <div class="chat-messages-box">
                                <div class="chat-messages-head">
                                    <div>Zaloguj się by zobaczyć komentarze</div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    
                    </div>
                </div>
            </div>

        </main>
    </div>