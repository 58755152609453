import { Injectable } from "@angular/core";
import { ModalController, ModalOptions } from "@ionic/angular";
import { ChartConfiguration, ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { BehaviorSubject } from "rxjs";
import { ArchivProduct } from "src/app/models/products/archivProduct.model";

@Injectable()
export class GlobalDiagramService {
    public loadingChart = new BehaviorSubject<boolean>(true);
    public lineChartData: ChartConfiguration<'line'>['data'] = {
        labels: [
          'Orginalna cena', 'Promocyjna cena'
        ],
        datasets: [],
        };
    public lineChartLegend = {
        display: true,
        position: 'top',
        labels: {
            pointStyle: 'circle'
        }
        };
    public lineChartType = 'line';
    public lineChartLabels: Array<any> = ['0', '1', '2', '3', '4', '5', '6'];
    public lineChartOptions: ChartOptions<'line'> = {
        responsive: false,
        plugins: {
        title: {
            display: true,
            text: 'Wykres cen'
        }
        },
        scales: {
        y: {
            title: {
            display: true,
            text: 'Cena w zł'
            }
        },
        x: {
            title: {
            display: true,
            text: 'dni'
            }
        }
        }
    };
    public searchRange = [14, 30, 183, 356];

    constructor(public modalCtrl: ModalController){}
    public changeFilterChart(chart: BaseChartDirective, selectedChartFilter: string,archivProduct: ArchivProduct[]){
        this.loadingChart.next(true);
        var current = new Date();
        var chartData = this.getFilterMainData(current, chart, selectedChartFilter, archivProduct);
        this.lineChartData.labels = chartData.labels;
        chart.chart.data.datasets[0].data = chartData.normal;
        chart.chart.data.datasets[1].data = chartData.discounts;
        
        this.loadingChart.next(false);
        chart.chart.update();
    }

    public getFilterMainData(current: Date, chart: BaseChartDirective, selectedChartFilter: string,archivProduct: ArchivProduct[]){
        var range = 0;
        var searchDateBorder =  new Date(current);
        var priceNormalArray: number[] = [];
        var priceDiscountArray: number[] = [];
        var labels: string[] = [];
        switch(selectedChartFilter){
          case "w":
            range = this.searchRange[0];
            searchDateBorder =  new Date(current.setMonth(current.getMonth()-1));
            break;
          case "m":
            range = this.searchRange[1];
            searchDateBorder =  new Date(current.setMonth(current.getMonth()-1));
            break;
          case "6m":
            range = this.searchRange[2];
            searchDateBorder =  new Date(current.setMonth(current.getMonth()-6));
            break;
          case "r":
            range = this.searchRange[3];
            searchDateBorder =  new Date(current.setMonth(current.getMonth()-12));
            break;
        }
      
        var searchDateBorder =  new Date(current.setMonth(current.getMonth()-1));
        var lastDay = current?.getUTCDate();
        var position = range;
        var lastPriceAsignmentPosition = range;
    
        var iterationLastDay = current.getDate();
        var iterationDate = new Date(current);
    
        for(var q=0; q < range; q++){
          priceNormalArray.push(0);
          priceDiscountArray.push(0);
          labels.push(iterationLastDay.toString());
          iterationLastDay--;
          if(iterationLastDay <= 0){
            iterationDate = new Date(iterationDate.setMonth(iterationDate.getMonth()-1))
            iterationLastDay = this.daysInMonth(current.getFullYear(), current.getMonth() + 1);
          }
        }
        labels = labels.reverse();
    
        archivProduct
        .filter(x => new Date(x.searchDate) > searchDateBorder)
        .sort((a,b) => new Date(b.searchDate).getTime() - new Date(a.searchDate).getTime())
        .slice(0, range)
        .forEach(item =>{ 
          if(position <= 0) return;
          var day = new Date(item.searchDate)?.getUTCDate();
          var diverenceBetweenDays = lastDay - day;
          while(position > 0 && diverenceBetweenDays > 0){
            priceNormalArray[position - 1] = item.available == 0 ? 0 : item.oryginalPrice;
            priceDiscountArray[position - 1] = item.available == 0 ? 0 : item.price;
            position--;
            diverenceBetweenDays--;
          }
          if(position <= 0) return;
          lastPriceAsignmentPosition = position - 1
          priceNormalArray[lastPriceAsignmentPosition] = item.available == 0 ? 0 : item.oryginalPrice;
          priceDiscountArray[lastPriceAsignmentPosition] = item.available == 0 ? 0 : item.price;
          position--;
          lastDay = day;
        });
    
        return { discounts: priceDiscountArray, normal: priceNormalArray, labels: labels};
      }

      private daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
      }

      public clean(){
        
      }
}