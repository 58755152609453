import { Injectable } from '@angular/core';
import { AdMob, BannerAdOptions, BannerAdSize, BannerAdPosition, BannerAdPluginEvents, InterstitialAdPluginEvents, AdLoadInfo, AdOptions } from '@capacitor-community/admob';
import { BehaviorSubject } from 'rxjs';

@Injectable()
  export class AdMobService {
    public showingBanner = new BehaviorSubject(false);
    public firstLoad = true;
    constructor(){
        this.initAdMob();
    }

    async initAdMob(){
        const { status } = await AdMob.trackingAuthorizationStatus();
        if (status === 'notDetermined') {
            console.log("Initial AdMob");
          }

          AdMob.initialize({
            requestTrackingAuthorization: true,
          });
    }

    showBaner(){
      AdMob.addListener(BannerAdPluginEvents.FailedToLoad, (error: any) => {
        this.showingBanner.next(false);
      });
      if(this.firstLoad == true){
        this.firstLoad = false;
        const options: BannerAdOptions = {
          adId: 'ca-app-pub-5860375149131207/2109944045',
          adSize: BannerAdSize.BANNER,
          position: BannerAdPosition.TOP_CENTER,
          margin: 0,
          // isTesting: true
          npa: true,
          
        };
        AdMob.showBanner(options);
      }else{
        AdMob.resumeBanner();
      }
      
      this.showingBanner.next(true);
    }

    async hideBanner(){
      if(this.showingBanner.getValue() == true){
        this.showingBanner.next(false);
        AdMob.hideBanner();
      }
    }

    async showInterstitial(){
        AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
            // Subscribe prepared interstitial
          });
        
          const options: AdOptions = {
            adId: 'ca-app-pub-5860375149131207/9535926351',
            // isTesting: true
            npa: true
          };
          await AdMob.prepareInterstitial(options);
          await AdMob.showInterstitial();
    }
  }