import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ProductsService } from 'src/app/services/products/products.service';
import { ProductsModel, CommentModel, CommentVote } from '../models/products/product.model';
import { AlertHelper } from '../helpers/alert-helper';
import { ValidationHelper } from '../helpers/validation-helper';
import { CommentMenuViewComponent } from './comment-menu/comment-menu.component';
import { EditCommandViewComponent } from './edit-comment/edit-comment.component';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'comment-list',
  templateUrl: 'commentList.component.html',
  styleUrls: ['commentList.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentListComponent implements OnInit, OnDestroy {
  commnets_limit: number = 10;
  public commentsList = new BehaviorSubject<CommentModel[]>([]);
  plt;
  
  @Input() public product: ProductsModel;
  @Input() public addNotify: BehaviorSubject<boolean>;
  @Input() public addCommentMain: Observable<void>;
  @Input() public showComments = new BehaviorSubject<boolean>(this.commentsList.getValue().length > 0 ? true : false);
  public message: string = "";
  public connectionCrash = new BehaviorSubject<boolean>(false);
  private lockVoting = false;
  private lockAdding = false;
  public subscriptionEvents: Subscription;
  constructor(public loaderService: LoaderService, private popoverCtrl: PopoverController, private productService: ProductsService, 
    private alertHelper: AlertHelper, private validationHelper: ValidationHelper) {
    this.plt = localStorage.getItem('platform');
  }

  ngOnInit() {
    this.subscriptionEvents = this.addCommentMain.subscribe((text) => {
      this.addComment(text);
    });
    this.getComments(true, undefined);
  }

  ionViewDidLeave(): void {
    this.subscriptionEvents.unsubscribe();
  }

  ionViewDidEnter(){
    
  }

  public async addComment(text: any){
    if(text.length <= 0){
      return;
    }
    let comment: CommentModel = {
      _id: "",
      message: text,
      userId: "",
      productId: this.product._id,
      postDate: new Date(),
      blocked: false,
      votes: new CommentVote(),
      isEdited: false,
      editDate: undefined,
    };

    if(this.validationHelper.validateComment(comment.message)){
      await this.productService.addComment(comment).then((response: boolean | string) =>{
        if(response == true){
          this.message = "";
          this.getComments(true, undefined);
        }
        else if(response == false){
          this.alertHelper.presentDefaultAlert("Błąd dodania komentaż",  "Cos poszlo nie tak...",  ["Spróbuj ponownie"]);
        }
        else{
          this.alertHelper.presentDefaultAlert("Błąd dodania komentaż", response);
        }
      });
    }
  }

  ngOnDestroy(): void { 
    this.subscriptionEvents.unsubscribe();   
  }

  doInfinite(event: any) {
    this.getNextItems(false, event);
  }

  async getNextItems(isRefresh: boolean, event: any) {
    await this.getComments(isRefresh, event);
  }

  public async getComments(isRefresh: boolean, event: any){
    this.loaderService.createLoader();
    var commentsLength = isRefresh ? 0 : this.commentsList.getValue().length;
    await this.productService.getComments(this.product._id, commentsLength, this.commnets_limit).then((response) =>{
      if(response == null){
        this.connectionCrash.next(true);
        return;
      }
    
      this.connectionCrash.next(false);
      if(response.length > 0){
        if(isRefresh){
          this.commentsList.next([...response]);
        }
        else{
          this.commentsList.next([...this.commentsList.getValue(), ...response]);
        }
        this.showComments.next(this.commentsList.getValue().length > 0);
      }
      this.loaderService.dimissLoader();
      event?.target?.complete();
    });
  }

  public async sendCommentVote(comment: CommentModel, vote: number){
    if(!this.lockVoting){
      this.lockVoting = true;
  
      var oldVote = comment.votes.voted;
      if(comment.votes.voted == vote){
        if(vote == 1){
          comment.votes.ups -= 1;
        }else if(vote == -1){
          comment.votes.downs -= 1;
        }
  
        comment.votes.voted = 0;
      }else{
        if(comment.votes.voted != 0){
          if(vote == 1){
            comment.votes.downs -= 1;
            comment.votes.ups += 1;
          }else if(vote == -1){
            comment.votes.downs += 1;
            comment.votes.ups -= 1;
          }
        }else{
          if(vote == 1){
            comment.votes.ups += 1;
          }else if(vote == -1){
            comment.votes.downs += 1;
          }
        }
  
        comment.votes.voted = vote;
      }
  
      await this.productService.addCommentVote( comment._id, vote).then((response) => {
        if(!response){
          if(oldVote == 1){
            comment.votes.ups += 1;
          }else if(vote == -1){
            comment.votes.downs += 1;
          }
  
          comment.votes.voted = oldVote;
        }
  
        this.lockVoting = false;
      });
    }
  }

  public async getCommnetVotes(comment: CommentModel){
    await this.productService.getCommentVoteCount(comment._id).then((response) => {
      if(response != undefined){
        comment.votes = response as CommentVote;
      }
      else{
        
      }
    });
  }

  deleteComment(comment: CommentModel){
    this.commentsList.next(this.commentsList.getValue().filter(x => x != comment));
    this.showComments.next(this.commentsList.getValue().length > 0);
  }

  editComment(comment: CommentModel){
    this.commentsList.next(this.commentsList.getValue().map(x => {
      if(x._id == comment._id){
        x.message = comment.message;
        x.isEdited = comment.isEdited;
        x.editDate = comment.editDate;
      }

      return x;
    }));
  }

  async showPopover(event, comment: CommentModel) {
    const popover = await this.popoverCtrl.create({
      component: CommentMenuViewComponent,
      event,
      componentProps: {
        comment: comment,
        onEdit: (comment: CommentModel) => {
          this.showPopoverEdit(event, comment);
          popover.dismiss();
        },
        onDelete: (comment: CommentModel) => {
          this.deleteComment(comment);
          popover.dismiss();
        },
      },
    });
    await popover.present();
  }

  async showPopoverEdit(event, comment: CommentModel) {
    const popover = await this.popoverCtrl.create({
      component: EditCommandViewComponent,
      event,
      componentProps: {
        comment: comment,
        onSuccess: (comment: CommentModel) => {
          this.editComment(comment);
          popover.dismiss();
        },
        onBack: () => {
          popover.dismiss();
        },
      },
    });
    await popover.present();
  }
}