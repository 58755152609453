import { ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ProductsModel } from 'src/app/models/products/product.model';

@Component({
  selector: 'product-amazon',
  templateUrl: 'product.amazon.component.html',
  styleUrls: ['product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductAmazonComponent implements OnInit {
  @Input() public product: ProductsModel;
  public countryPrices;
  constructor() {
  }

  ngOnInit() {
    this.countryPrices = [{
      price: this.product.prices.de.price,
      name: 'DE',
      fullName: "Germany"
      },
      {
      price: this.product.prices.es.price,
      name: 'ES',
      fullName: "Spain"
      },
      {
      price: this.product.prices.fr.price,
      name: 'FR',
      fullName: "France"
      },
      {
      price: this.product.prices.it.price,
      name: 'IT',
      fullName: "Italy"
      },
      {
      price: this.product.prices.pl.price,
      name: 'PL',
      fullName: "Poland"
      },
      {
      price: this.product.prices.uk.price,
      name: 'UK',
      fullName: "United Kingdom"
      }
      ].sort((a, b) => a.price - b.price);
  }

  public getCountrFlag(country: string){
    return 'assets/icon/country/' + country + '.svg';
  }

  public openUrl(url: string, country: string){
    if(country.toLocaleLowerCase() === "uk"){
      window.open("https://amazon.co." + country + url, "_blank");
    }else{
      window.open("https://amazon." + country + url, "_blank");
    }
  }
}