<div class="page-wrapper">
    <header class="page-header">
        <div class="page-header__inner">
            <div class="page-header__sidebar">
                <div class="page-header__menu-btn"><button class="menu-btn ico_menu"></button></div>
                <div class="page-header__logo" style="cursor: pointer;" [routerLink]="['/dashboard']"><img src="../../assets/imgs/logoDark.png" alt="logo"></div>
            </div>
            <div class="page-header__content">
                <div class="page-header__search">
                    
                </div>
                <div class="page-header__action">
                    <a class="action-btn" href="https://www.facebook.com/profile.php?id=100087825745624">
                        <ion-icon name="logo-facebook"></ion-icon>
                    </a>
                    <a class="action-btn" href="#!" [routerLink]="['/help']">
                        <i class="ico_help"></i>
                    </a>
                    <a class="action-btn" href="#!" [routerLink]="['/liked']">
                        <i class="ico_favourites"></i>
                    </a>
                    <a [routerLink]="['/profile']" class="profile" href="#!"><img src="assets/imgs/profile.png" alt="profile"></a>
                </div></div>
        </div>
    </header>
    <div class="page-content">
        <aside class="sidebar is-show" id="sidebar">
            <div class="sidebar-box">
              <ul class="uk-nav">
                <li [routerLink]="['/dashboard']"><a href=""><i class="ico_home"></i><span>Strona Główna</span></a></li>
                <li class="uk-nav-header">Popularne</li>
                <li><a href="09_games-store.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnie', main: ''}"><i class="ico_store"></i><span>Ostatnio znalezione</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnidzien', main: 'undefined'}"><i class="ico_report"></i><span>Ostatni dzień</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne3dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 3 dni</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne7dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 7 dni</span></a></li>
                <li class="uk-nav-header">Sklepy</li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaExpert', main: 'undefined'}"><i class="ico_market"></i><span>MediaExpert</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'EuroRtvAgd', main: 'undefined'}"><i class="ico_market"></i><span>EuroRtvAgd</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaMarkt', main: 'undefined'}"><i class="ico_market"></i><span>MediaMarkt</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'XKom', main: 'undefined'}"><i class="ico_market"></i><span>X-Kom</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Amazon', main: 'undefined'}"><i class="ico_market"></i><span>Amazon</span></a></li>
                <li class="uk-nav-header">Pomoc</li>
                <li><a href="11_market.html" [routerLink]="['/help']" [queryParams]=""><i class="ico_market"></i><span>Kontakt</span></a></li>
            </ul>
            </div>
        </aside>

        <main class="page-main">
            <ul class="uk-breadcrumb">
                <li (click)="goToBack()" style="cursor: pointer;"><ion-icon name="chevron-back-outline"></ion-icon><span>Cofnij</span></li>
            </ul>
            <div *ngIf="!(loading | async);else loadingScreen">
                <div class="uk-grid uk-child-width-1-6@xl uk-child-width-1-3@l uk-child-width-1-2@s uk-flex-middle uk-grid-small" data-uk-grid>
                    <div *ngFor="let item of (categoryList | async); let i = index">
                        <div class="game-card" [routerLink]="['/list']" style="cursor: pointer"
                        [queryParams]="{ type: ''+item.Category+'', main: ''+categoryTitle.getValue()+''}">
                            <div class="game-card__box">
                                <div class="game-card__media" style="background-size: cover;" [style.backgroundImage]="'url(assets/imgs/'+ prepareImage(item) +'.jpg)'"></div>
                                <div class="game-card__info">{{item.Category}}</div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <ng-template #loadingScreen>
                <div class="loadingSpinner">
                    <ion-spinner></ion-spinner>
                  </div>
              </ng-template>
        </main>
    </div>