<div>
    <ion-grid>
        <ion-row justify-content-center align-items-end style="margin: 15px 15px 0 15px;">
            <ion-col col-12 class="button-vertical">
            <ion-button expand="block" (click)="openUrl(product.url, product.lowest.country)">
                <p class="text-p-style">Open Lowest</p>
            </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
    <div class="main_container_div">
        <div class="flex_div">
            <div class="left_div">
                <div *ngIf="product.discount != 0">
                    <ion-label class="simple_lbl" style="color: #c34448;margin-bottom:5px">Najniższa cena</ion-label>
                    <ion-label class="price" style="color: #c34448;">{{(product.lowest.price).toFixed(2)}}zł</ion-label>
                </div>
            </div>
            
            <div class="right_div">
                <div style="display: flex;align-items: center;">
                    <div class="color_div" style="background-color: rgba(41, 101, 191, 0.2);" >
                        <ion-icon name="calendar-outline" style="color: #3f74c8;font-size: 16px;"></ion-icon>
                    </div>
                    <ion-label style="color: #3f74c8; font-size: 15px;">{{product.searchDate | date: 'dd/MM/yyyy'}}</ion-label>
                </div>
                <div style="display: flex;align-items: center;margin-top: 15px;">
                    <div class="color_div" style="background-color: rgba(181, 165, 0, 0.2);">
                        <ion-icon name="arrow-down-sharp" style="color: #7e780c;font-size: 16px;"></ion-icon>
                    </div>
                    <ion-label style="color: #7e780c">-{{(product.lowest.discount * 100).toFixed(0)}}%</ion-label>
                </div>
            </div>
        </div>
    </div>
    <div class="container" id="app">
        <div class="board">
        <h2 class="ion-row-header">Leaderboard</h2>
        <div *ngFor="let countryItem of this.countryPrices">
            <li class="cat-item" *ngIf="countryItem.price > 0 && countryItem.price < 100000000000" (click)="openUrl(product.url, countryItem.name)">
            <div class="cat-item__photo">
                <ion-img [src]="getCountrFlag(countryItem.name)"></ion-img>
            </div>
            <div class="cat-item__info">
                <h4>{{ countryItem.fullName }}</h4>
            </div>
            <div class="cat-item__points">
                <p>{{ countryItem.price }}</p>
            </div>
            </li>
        </div>
        </div>
    </div>  
</div>