import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ModalManagerService } from '../modal/modal-manager.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardGuestService {  
    constructor(private modal: ModalManagerService, private navCtrl: NavController, public auth: AuthService, public router: Router) {}  
    canActivate(): boolean {
        console.log("22", this.modal.isRunning);
        
        if(this.modal.isRunning){
            this.modal.execute();
            return false;
        }
        if (!this.auth.isAuth.getValue()) {
            return true;
        }
        
        this.navCtrl.navigateRoot(['dashboard']);
        return false;
    }
}