import { CountryPrice, OtherSellerPrice } from "./country-price.model";
import { LikedModel } from "./liked.model";
import { ProductsLowest } from "./product-lowest.model";

export class ProductsModel {
    public _id: string = "";
    public image: string = "";
    public title: string = "";
    public url: string = "";
    public type: string = "";
    public category: string = "";
    public lowest: ProductsLowest = new ProductsLowest();
    public prices: CountryPrice = new CountryPrice();
    public otherSellers: OtherSellerPrice = new OtherSellerPrice();
    public liked: number = 0;
    public voteSum: number = 0;
    public searchDate: Date;
    public site: string = ""
    public available: number;
    //MM
    public price: number = 0;
    public discount: number = 0;
    public oryginalPrice: number = 0;
    public additionalPromo: MediaMarktPromoCode = new MediaMarktPromoCode();

    constructor(){}
}

export class MediaMarktPromoCode{
    public money50: boolean = false;
    public promoCode: string | null = null;
}

export class CommentModel {
    public _id: string = ""
    public productId: string = "";
    public userId: string = "";
    public message: string = "";
    public postDate: Date = new Date();
    public blocked: boolean = false;
    public votes: CommentVote = new CommentVote();
    public editDate: Date = new Date();
    public isEdited: boolean = false;
}

export class CommentVote {
    public ups: number = 0;
    public downs: number = 0;
    public voted: number = 0;
}