<ion-content>
  <div class="main_content_div">

      <div class="gradient_div">

          <div class="flex_header">
              <ion-label>Profile</ion-label>
              <ion-icon class="logout-icon" name="log-out-outline" (click)="logout()"></ion-icon>
          </div>
      </div>

      <div class="container_div">
          <div class="white_box">
              
              <div [style.backgroundImage]="'url(assets/imgs/user.jpg)'" class="user_back"></div>

              <div class="flex_div">
                  <ion-label>{{(userData | async).nick}}</ion-label>
                  <ion-img src="assets/imgs/verified.png"></ion-img>
              </div>

              <ion-label class="grey_lbl">{{(userData | async).email}}</ion-label>

              <div class="grey_box">
                  <ion-img src="assets/imgs/badge_1.png" style="opacity: 0.4;"></ion-img>
                  <ion-img src="assets/imgs/badge_2.png" style="opacity: 0.4;"></ion-img>
                  <ion-img src="assets/imgs/badge_3.png" style="opacity: 0.4;"></ion-img>
                  <ion-img src="assets/imgs/badge_4.png" style="opacity: 0.4;"></ion-img>
              </div>

          </div>

          <ion-label class="title_lbl">GENERAL</ion-label>
            
          <div class="shopping_div" (click)="goToEditProfile()">
              <div class="color_box">
                  <img src="assets/imgs/settings.png"/>
              </div>
              <div class="content_div">
                  <ion-label class="name">Edytuj profil</ion-label>
                  <ion-label class="date">Edytuj podstawowe funkcje profilu</ion-label>
              </div>
              <ion-icon name="chevron-forward-sharp"></ion-icon>
          </div>

          <div class="shopping_div" (click)="goToChangePassword()">
              <div class="color_box">
                  <img src="assets/imgs/privacy.png"/>
              </div>
              <div class="content_div">
                  <ion-label class="name">Zmiana hasła</ion-label>
                  <ion-label class="date">Zmień swoje hasło</ion-label>
              </div>
              <ion-icon name="chevron-forward-sharp"></ion-icon>
          </div>

          <div class="shopping_div" (click)="goToInvideCodes()">
            <div class="color_box">
                <img src="assets/imgs/badge_3.png"/>
            </div>
            <div class="content_div">
                <ion-label class="name">Kody zaproszenia</ion-label>
                <ion-label class="date">Kody dla znajomych</ion-label>
            </div>
            <ion-icon name="chevron-forward-sharp"></ion-icon>
        </div>
      </div>
  </div>
</ion-content>
