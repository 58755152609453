export class CountryPrice {
    public pl: CountryPriceData = new CountryPriceData();
    public de: CountryPriceData = new CountryPriceData();
    public es: CountryPriceData = new CountryPriceData();
    public it: CountryPriceData = new CountryPriceData();
    public fr: CountryPriceData = new CountryPriceData();
    public uk: CountryPriceData = new CountryPriceData();
}

export class OtherSellerPrice {
    public pl: OtherSellerPriceData = new OtherSellerPriceData();
    public de: OtherSellerPriceData = new OtherSellerPriceData();
    public es: OtherSellerPriceData = new OtherSellerPriceData();
    public it: OtherSellerPriceData = new OtherSellerPriceData();
    public fr: OtherSellerPriceData = new OtherSellerPriceData();
    public uk: OtherSellerPriceData = new OtherSellerPriceData();
}

export class CountryPriceData{
    public price: number = 0;
    public delivery: number = 0;
    public discount: number = 0;
    public sender: number = 0;
    public seller: number = 0;
}

export class OtherSellerPriceData{
    public price: number = 0;
    public delivery: number = 0;
    public discount: number = 0;
    public sender: number = 0;
    public seller: number = 0;
    public status: number = 0;
}