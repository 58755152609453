import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor(private spinner: NgxSpinnerService) { }
  private isOn = new BehaviorSubject(false);
  createLoader(){
    this.isOn.next(true);
    this.spinner.show();
  }

  getStatus(){
    return this.isOn.getValue();
  }

  dimissLoader(){
    this.isOn.next(false);
    this.spinner.hide();
  }
}