<aside class="sidebar is-show" id="sidebar">
  <div class="sidebar-box">
    <ul class="uk-nav">
      <li class="uk-active"><a href="03_home.html"><i class="ico_home"></i><span>Strona Główna</span></a></li>
      <li class="uk-nav-header">Konto</li>
      <li><a href="04_profile.html"><i class="ico_profile"></i><span>Profil</span></a></li>
      <li><a href="05_favourites.html"><i class="ico_favourites"></i><span>Ulubione</span><span class="count">15</span></a></li>
      <li class="uk-nav-header">Popularne</li>
      <li><a href="09_games-store.html"><i class="ico_store"></i><span>Kategorie</span></a></li>
      <li><a href="11_market.html"><i class="ico_market"></i><span>Amazon</span></a></li>
      <li class="uk-nav-header">Pomoc</li>
      <li><a href="11_market.html" [routerLink]="['/help']" [queryParams]=""><i class="ico_market"></i><span>Kontakt</span></a></li>
  </ul>
  </div>
</aside>