// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAXDRKuUCumaUlPW4xLijOPg4G4jcCZ1eY",
    authDomain: "amazpep-60c57.firebaseapp.com",
    projectId: "amazpep-60c57",
    storageBucket: "amazpep-60c57.appspot.com",
    messagingSenderId: "370981295814",
    appId: "1:370981295814:web:82bb81a47eeefaf406a9c7",
    measurementId: "G-10055PVSQB"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
