<ion-content>
  <div class="main_content_div">

    <div class="waveWrapper waveAnimation">
      <ion-icon name="arrow-back-outline" class="back_btn" [class.ios_pad]="plt == 'ios'" (click)="goBack()"></ion-icon>
      <div class="waveWrapperInner bgTop">
        <div class="wave waveTop" style="background-image: url('assets/imgs/wave-mid.png')"></div>
      </div>
      <div class="waveWrapperInner bgMiddle">
        <div class="wave waveMiddle" style="background-image: url('assets/imgs/wave-mid.png')"></div>
      </div>
      <div class="waveWrapperInner bgBottom">
        <div class="wave waveBottom" style="background-image: url('assets/imgs/wave-mid.png')"></div>
      </div>
    </div>

    <div class="up_div">
      <div class="user_image" [style.backgroundImage]="'url(assets/imgs/user2.jpg)'"></div>
      <ion-label class="username">Jonh Doe</ion-label>
      <ion-label class="small_lbl">Web Designer</ion-label>
    </div>

    <div class="lower_div">
      

    </div>
  </div>
</ion-content>