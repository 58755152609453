import { Injectable } from "@angular/core";
import { ModalController, ModalOptions } from "@ionic/angular";

@Injectable()
export class ModalManagerService {
    constructor(public modalCtrl: ModalController){}
    private modalOptions: HTMLIonModalElement;
    public isRunning = false;
    public async create(options: ModalOptions){
        if(this.modalOptions){
            await this.execute();
        }

        this.modalOptions = await this.modalCtrl.create(options);
        await this.execute();
    }

    public async execute(){
        console.log(this.isRunning);
        
        if(this.isRunning){
            await this.modalOptions.dismiss();
            this.modalOptions = undefined;
            this.isRunning = false;
            return;
        }

        await this.modalOptions.present();
        this.isRunning = true;
    }
}