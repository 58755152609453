<ion-content>
  <div class="main_content_div">

      <div class="gradient_div">

          <div class="flex_header">
              <ion-label>Info</ion-label>
          </div>
      </div>

      <div class="container_div">
          <div class="white_box">
              
              <ion-label>Funkcjonalności:</ion-label>
              <ion-list>
                <ion-item>
                    <ion-icon name="caret-forward-outline"></ion-icon>
                  <ion-label text-wrap>Codziennie aktualizowane ceny produktów</ion-label>
                </ion-item>
                <ion-item>
                    <ion-icon name="caret-forward-outline"></ion-icon>
                  <ion-label text-wrap>Pewność podanych cen w diagramie (historii produktu)</ion-label>
                </ion-item>
                <ion-item>
                    <ion-icon name="caret-forward-outline"></ion-icon>
                  <ion-label text-wrap>Oceny produktów resetowane przy zmianie ceny</ion-label>
                </ion-item>
                <ion-item>
                    <ion-icon name="caret-forward-outline"></ion-icon>
                  <ion-label text-wrap>Powiadomienie gdy zapisany produkt stanieje!</ion-label>
                </ion-item>
                <ion-item>
                    <ion-icon name="caret-forward-outline"></ion-icon>
                  <ion-label text-wrap>Monitorujemy ponad <b>300 000</b> tys produktów, i ciągle liczba ta rośnie!</ion-label>
                </ion-item>
              </ion-list>
              <div class="grey_box">
                <ion-label>Masz pytania lub uwagę? Napisz do nas!</ion-label>
                <ion-label color="tertiary" (click)="copyEmail()">biuro@darkfusion.pl</ion-label>
              </div>
              
              <ion-button expand="block" (click)="openStart()">
                Zobacz jakie funkcje<br> kryje aplikacja
              </ion-button>
              <ion-icon (click)="openFb()" style="font-size: 30px;margin-top:10px" name="logo-facebook"></ion-icon>
          </div>
      </div>
  </div>
</ion-content>
