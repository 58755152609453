import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable()
export class ToastService {
    constructor(private toastController: ToastController){
    }

    public async presentToast(position: 'top' | 'middle' | 'bottom', text: string, delay = 1500) {
        const toast = await this.toastController.create({
          message: text,
          duration: delay,
          position: position
        });
    
        await toast.present();
      }
}