<div class="page-wrapper">
  <header class="page-header">
      <div class="page-header__inner">
          <div class="page-header__sidebar">
              <div class="page-header__menu-btn"><button class="menu-btn ico_menu"></button></div>
              <div class="page-header__logo" style="cursor: pointer;" [routerLink]="['/dashboard']"><img src="../../assets/imgs/logoDark.png" alt="logo"></div>
          </div>
          <div class="page-header__content">
              <div class="page-header__search">
                  <div class="search">
                      </div>
              </div>
              <div class="page-header__action">
                <a class="action-btn" href="https://www.facebook.com/profile.php?id=100087825745624">
                    <ion-icon name="logo-facebook"></ion-icon>
                </a>
                <a class="action-btn" href="#!" [routerLink]="['/help']">
                    <i class="ico_help"></i>
                </a>
                <a class="action-btn" href="#!" [routerLink]="['/liked']">
                    <i class="ico_favourites"></i>
                </a>
                <a [routerLink]="['/profile']" class="profile" href="#!"><img src="assets/imgs/profile.png" alt="profile"></a>
            </div></div>
      </div>
  </header>
  <div class="page-content">
    <aside class="sidebar is-show" id="sidebar">
        <div class="sidebar-box">
          <ul class="uk-nav">
            <li [routerLink]="['/dashboard']"><a href=""><i class="ico_home"></i><span>Strona Główna</span></a></li>
            <li class="uk-nav-header">Popularne</li>
            <li><a href="09_games-store.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnie', main: ''}"><i class="ico_store"></i><span>Ostatnio znalezione</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnidzien', main: 'undefined'}"><i class="ico_report"></i><span>Ostatni dzień</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne3dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 3 dni</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne7dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 7 dni</span></a></li>
            <li class="uk-nav-header">Sklepy</li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaExpert', main: 'undefined'}"><i class="ico_market"></i><span>MediaExpert</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'EuroRtvAgd', main: 'undefined'}"><i class="ico_market"></i><span>EuroRtvAgd</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaMarkt', main: 'undefined'}"><i class="ico_market"></i><span>MediaMarkt</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'XKom', main: 'undefined'}"><i class="ico_market"></i><span>X-Kom</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Amazon', main: 'undefined'}"><i class="ico_market"></i><span>Amazon</span></a></li>
            <li class="uk-nav-header">Pomoc</li>
            <li><a href="11_market.html" [routerLink]="['/help']" [queryParams]=""><i class="ico_market"></i><span>Kontakt</span></a></li>
        </ul>
        </div>
    </aside>

      <main class="page-main">
        <button class="uk-button uk-button-danger" type="button" (click)="getNextItems(true, undefined)">Odśwież</button>
          <ion-content style="height: 100%;">
              <div *ngIf="(productList | async).length > 0">
                  <div class="uk-grid uk-child-width-1-6@xl uk-child-width-1-3@l uk-child-width-1-2@s uk-flex-middle uk-grid-small" data-uk-grid>
                      <div *ngFor="let item of (productList | async)">
                          <div class="game-card">
                              <div class="game-card__box" (click)="openProduct(item)">
                                <img class="game-card__media" style="object-fit: scale-down;" defaultImage="assets/imgs/szoploading.gif" [lazyLoad]="item?.image">
                                  <div class="game-card__info"><a class="game-card__title"> {{item.title | slice:0:80}}</a>
                                      <div class="game-card__genre" *ngIf="item.site != 'amazon'">{{item.site}}</div>
                                      <div class="game-card__genre" *ngIf="item.site == 'amazon'">{{item.site}} - {{item.lowest.country | uppercase}}</div>
                                      <div class="game-card__rating-and-price">
                                          <div class="game-card__rating" *ngIf="item.site != 'amazon' && item?.discount != 0"><span>-{{(item.discount * 100).toFixed(0)}}%</span></div>
                                          <div class="game-card__rating" *ngIf="item.site == 'amazon' && item?.lowest?.discount != 0"><span>-{{(item?.lowest?.discount * 100).toFixed(0)}}%</span></div>
                                          <div class="game-card__rating" *ngIf="(item?.discount == 0 || item?.discount == undefined) && (item?.lowest?.discount == 0 || item?.lowest?.discount == undefined)"><span>Brak promocji</span></div>
                                          <div class="game-card__price"><span>{{item.price ?? item.lowest.price}}zł</span></div>
                                      </div>
                                      <div class="game-card__bottom">
                                          <div class="game-card__platform" style="font-size: 15px">
                                              <p>{{ item.voteSum }}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              
              <ion-infinite-scroll  (ionInfinite)="doInfinite($event)">
                  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Ładuję kolejne produkty...">
                  </ion-infinite-scroll-content>
                </ion-infinite-scroll>

                <ion-fab slot="fixed" vertical="bottom" horizontal="end">
                  <ion-fab-button (click)="scrollUp()" class="button-float">
                    <ion-icon name="chevron-up-circle"></ion-icon>
                  </ion-fab-button>
                </ion-fab>
          </ion-content>
      </main>
  </div>
  