import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ModalManagerService } from '../modal/modal-manager.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardFullGuestService {  
    constructor(private modal: ModalManagerService,public auth: AuthService, public router: Router, private navCtrl: NavController) {}  
    canActivate(route: ActivatedRouteSnapshot): boolean {
        console.log("33", this.modal.isRunning);
        if(this.modal.isRunning){
            this.modal.execute();
            return false;
        }
        var routeIndex = route.url.findIndex(x => x.path.includes("product") || x.path.includes("share"));
        
        if(routeIndex != -1){
            if(this.auth.isAuth.getValue()){
                let navigationExtras: NavigationExtras = {
                    queryParams: {
                      prod: route.queryParams?.prod
                    }
                  };
                this.navCtrl.navigateRoot(['list'], navigationExtras);
            }else{
                return true;
            }
        }
        
        return true;
    }
}