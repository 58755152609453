import { InfoViewComponent } from "src/app/info/info.component";
import { PaymentErrorViewComponent } from "src/app/payment/error-payment/payment-error.component";
import { PaymentViewComponent } from "src/app/payment/payment.component";
import { PaymentSuccessViewComponent } from "src/app/payment/success-payment/payment-success.component";
import { ShareComponent } from "src/app/share/share.component";
import { SliderViewComponent } from "src/app/start-view/slider/slider.component";
import { StartViewComponent } from "src/app/start-view/start-view.component";
import { ChangeBrowserPasswordViewComponent } from "src/browser/auth/change-password/change-password.component";
import { ForgotBrowserViewComponent } from "src/browser/auth/forgot-password/forgot.component";
import { LoginBrowserViewComponent } from "src/browser/auth/login/login.component";
import { RegisterBrowserViewComponent } from "src/browser/auth/register/register.component";
import { HelpBrowserViewComponent } from "src/browser/help/help.component";
import { LikedProductsBrowserViewComponent } from "src/browser/liked-products/liked-products.component";
import { CollectionsListBrowserViewComponent } from "src/browser/other-shops/collection-list/shops-list.component";
import { CollectionsBrowserViewComponent } from "src/browser/other-shops/collections/shops-collections.component";
import { ShopsBrowserViewComponent } from "src/browser/other-shops/shops.component";
import { ProductBrowserComponent } from "src/browser/product-modal/product.component";
import { EditProfileBrowserViewComponent } from "src/browser/profile/edit-profile/edit-profile.component";
import { InviteCodesBrowserViewComponent } from "src/browser/profile/invite-codes/invite-codes.component";
import { ProfileBrowserViewComponent } from "src/browser/profile/profile.component";
import { ChangeMobilePasswordViewComponent } from "src/mobile/auth/change-password/change-password.component";
import { ForgotMobileViewComponent } from "src/mobile/auth/forgot-password/forgot.component";
import { LoginMobileViewComponent } from "src/mobile/auth/login/login.component";
import { RegisterMobileViewComponent } from "src/mobile/auth/register/register.component";
import { LikedProductsMobileViewComponent } from "src/mobile/liked-products/liked-products.component";
import { CollectionsListMobileViewComponent } from "src/mobile/other-shops/collection-list/shops-list.component";
import { CollectionsMobileViewComponent } from "src/mobile/other-shops/collections/shops-collections.component";
import { ShopsMobileViewComponent } from "src/mobile/other-shops/shops.component";
import { ProductMobileComponent } from "src/mobile/product-modal/product.component";
import { EditProfileMobileViewComponent } from "src/mobile/profile/edit-profile/edit-profile.component";
import { InviteCodesMobileViewComponent } from "src/mobile/profile/invite-codes/invite-codes.component";
import { ProfileMobileViewComponent } from "src/mobile/profile/profile.component";
import { AuthGuardFullGuestService } from "../auth/auth-guard-full-guest";
import { AuthGuardGuestService } from "../auth/auth-guard-guest.service";
import { AuthGuardShareService } from "../auth/auth-guard-share.service";
import { AuthGuardService } from "../auth/auth-guard.service";
import { Guard, GuardEnum, RouteModel } from "./model/route.model";

export var routesMobile: RouteModel[] = [
    {id: 'dashboard', componentRef: ShopsMobileViewComponent, guard: GuardEnum.Auth },
    {id: '', componentRef: StartViewComponent, guard: GuardEnum.Guest },
    {id: 'start', componentRef: SliderViewComponent, guard: GuardEnum.Full },
    {id: 'share', componentRef: ShareComponent, guard: GuardEnum.Full },
    {id: 'login', componentRef: LoginMobileViewComponent, guard: GuardEnum.Guest },
    {id: 'register', componentRef: RegisterMobileViewComponent, guard: GuardEnum.Guest },
    {id: 'collection', componentRef: CollectionsMobileViewComponent, guard: GuardEnum.Auth },
    {id: 'list', componentRef: CollectionsListMobileViewComponent, guard: GuardEnum.Auth },
    {id: 'profile', componentRef: ProfileMobileViewComponent, guard: GuardEnum.Auth },
    {id: 'info', componentRef: InfoViewComponent, guard: GuardEnum.Auth },
    {id: 'edit', componentRef: EditProfileMobileViewComponent, guard: GuardEnum.Auth },
    {id: 'liked', componentRef: LikedProductsMobileViewComponent, guard: GuardEnum.Auth },
    {id: 'payment', componentRef: PaymentViewComponent, guard: GuardEnum.Auth },
    {id: 'invite', componentRef: InviteCodesMobileViewComponent, guard: GuardEnum.Auth },
    {id: 'payment-success', componentRef: PaymentSuccessViewComponent, guard: GuardEnum.Auth },
    {id: 'payment-error', componentRef: PaymentErrorViewComponent, guard: GuardEnum.Auth },
    {id: 'password-forgot', componentRef: ForgotMobileViewComponent, guard: GuardEnum.Full },
    {id: 'password-change', componentRef: ChangeMobilePasswordViewComponent, guard: GuardEnum.Full },
    {id: 'product/:id', componentRef: ProductMobileComponent, guard: GuardEnum.Share },
];

export var routesBrowser: RouteModel[] = [
    {id: 'dashboard', componentRef: ShopsBrowserViewComponent, guard: GuardEnum.Auth },
    {id: '', componentRef: StartViewComponent, guard: GuardEnum.Guest },
    {id: 'start', componentRef: SliderViewComponent, guard: GuardEnum.Full },
    {id: 'share', componentRef: ShareComponent, guard: GuardEnum.Full },
    {id: 'login', componentRef: LoginBrowserViewComponent, guard: GuardEnum.Guest },
    {id: 'register', componentRef: RegisterBrowserViewComponent, guard: GuardEnum.Guest },
    {id: 'collection', componentRef: CollectionsBrowserViewComponent, guard: GuardEnum.Auth },
    {id: 'list', componentRef: CollectionsListBrowserViewComponent, guard: GuardEnum.Auth },
    {id: 'profile', componentRef: ProfileBrowserViewComponent, guard: GuardEnum.Auth },
    {id: 'info', componentRef: InfoViewComponent, guard: GuardEnum.Auth },
    {id: 'edit', componentRef: EditProfileBrowserViewComponent, guard: GuardEnum.Auth },
    {id: 'liked', componentRef: LikedProductsBrowserViewComponent, guard: GuardEnum.Auth },
    {id: 'payment', componentRef: PaymentViewComponent, guard: GuardEnum.Auth },
    {id: 'invite', componentRef: InviteCodesBrowserViewComponent, guard: GuardEnum.Auth },
    {id: 'payment-success', componentRef: PaymentSuccessViewComponent, guard: GuardEnum.Auth },
    {id: 'payment-error', componentRef: PaymentErrorViewComponent, guard: GuardEnum.Auth },
    {id: 'password-forgot', componentRef: ForgotBrowserViewComponent, guard: GuardEnum.Full },
    {id: 'password-change', componentRef: ChangeBrowserPasswordViewComponent, guard: GuardEnum.Full },
    {id: 'product/:id', componentRef: ProductBrowserComponent, guard: GuardEnum.Share },
    {id: 'help', componentRef: HelpBrowserViewComponent, guard: GuardEnum.Auth },
];

export var guards: Guard[] = [
    { type: GuardEnum.Full, serviceRef: AuthGuardFullGuestService },
    { type: GuardEnum.Auth, serviceRef: AuthGuardService },
    { type: GuardEnum.Guest, serviceRef: AuthGuardGuestService },
    { type: GuardEnum.Share, serviceRef: AuthGuardShareService },
]