<div class="page-wrapper">
  <header class="page-header">
      <div class="page-header__inner">
          <div class="page-header__sidebar">
              <div class="page-header__menu-btn"><button class="menu-btn ico_menu"></button></div>
              <div class="page-header__logo" style="cursor: pointer;" [routerLink]="['/dashboard']"><img src="../../assets/imgs/logoDark.png" alt="logo"></div>
          </div>
          <div class="page-header__content">
              <div class="page-header__search">
                  <div class="search">
                      <div class="search__input"><i class="ico_search"></i><input (keyup.enter)="onEnter()" [(ngModel)]="searchTextValue" id="searchBar" (ionFocus)="focusSearch()"  (keyup)="onKeySearch($event)" (click)="startSearch()" type="text" placeholder="Wyszukaj..."></div>
                  </div>
              </div>
              <div class="page-header__action">
                <a class="action-btn" href="https://www.facebook.com/profile.php?id=100087825745624">
                    <ion-icon name="logo-facebook"></ion-icon>
                </a>
                <a class="action-btn" href="#!" [routerLink]="['/help']">
                    <i class="ico_help"></i>
                </a>
                <a class="action-btn" href="#!" [routerLink]="['/liked']">
                    <i class="ico_favourites"></i>
                </a>
                <a [routerLink]="['/profile']" class="profile" href="#!"><img src="assets/imgs/profile.png" alt="profile"></a>
            </div>
          </div>
      </div>
  </header>
  <div class="page-content">
    <aside class="sidebar is-show" id="sidebar">
        <div class="sidebar-box">
          <ul class="uk-nav">
            <li [routerLink]="['/dashboard']"><a href=""><i class="ico_home"></i><span>Strona Główna</span></a></li>
            <li class="uk-nav-header">Popularne</li>
            <li><a href="09_games-store.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnie', main: ''}"><i class="ico_store"></i><span>Ostatnio znalezione</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnidzien', main: 'undefined'}"><i class="ico_report"></i><span>Ostatni dzień</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne3dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 3 dni</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne7dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 7 dni</span></a></li>
            <li class="uk-nav-header">Sklepy</li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaExpert', main: 'undefined'}"><i class="ico_market"></i><span>MediaExpert</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'EuroRtvAgd', main: 'undefined'}"><i class="ico_market"></i><span>EuroRtvAgd</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaMarkt', main: 'undefined'}"><i class="ico_market"></i><span>MediaMarkt</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'XKom', main: 'undefined'}"><i class="ico_market"></i><span>X-Kom</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Amazon', main: 'undefined'}"><i class="ico_market"></i><span>Amazon</span></a></li>
            <li class="uk-nav-header">Pomoc</li>
            <li><a href="11_market.html" [routerLink]="['/help']" [queryParams]=""><i class="ico_market"></i><span>Kontakt</span></a></li>
        </ul>
        </div>
    </aside>
      <main class="page-main">
          <div class="uk-grid" data-uk-grid>
              <div class="uk-width-2-3@xl">
                  <h3 class="uk-text-lead">Wyróżniane informacje</h3>
                  <div class="js-recommend">
                      <div class="swiper">
                          <div class="swiper-wrapper">
                            <div *ngIf="(bannerList | async)?.length <= 0">
                              <div class="swiper-slide">
                                <div class="recommend-slide">
                                    <div class="recommend-slide__box">
                                      <div class="loadingSpinner">
                                        <ion-spinner></ion-spinner>
                                      </div>
                                      </div>
                                </div>
                            </div>
                            </div>
                              <div class="swiper-slide" *ngFor="let item of bannerList | async">
                                  <div class="recommend-slide">
                                      <div class="recommend-slide__box" style="background-size: cover;" [style.backgroundImage]="'url('+ item.img +')'">
                                          <div class="recommend-slide__info">
                                              <div class="recommend-slide__intro"><span><h3 style="color:white;">{{item.title}}</h3> <br>{{item.description}}</span></div>
                                              <div class="recommend-slide__btn"><button class="uk-button uk-button-danger" type="button" (click)="openApp()">Pobierz aplikacje na Android</button></div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="swipper-nav">
                              <div class="swiper-button-prev"></div>
                              <div class="swiper-button-next"></div>
                          </div>
                          <div class="swiper-pagination"></div>
                      </div>
                  </div>
              </div>
              <div class="uk-width-1-3@xl">
                  <h3 class="uk-text-lead">Popularne filtry</h3>
                  <div class="js-trending">
                      <div class="swiper" style="height: 320px;">
                          <div class="swiper-wrapper">
                            <div *ngIf="(popularCategory | async)?.length <= 0">
                              <div class="swiper-slide">
                                <div class="loadingSpinner">
                                  <ion-spinner></ion-spinner>
                                </div>
                              </div>
                            </div>
                            
                              <div class="swiper-slide" *ngFor="let item of (popularCategory | async) | slice:0:6">
                                  <div class="game-card --horizontal" [routerLink]="['/list']" 
                                  [queryParams]="{ type: ''+item.Category+'', main: ''+item.MainCategory+''}">
                                      <div class="game-card__box">
                                          <div class="game-card__media" style="background-size: cover;" [style.backgroundImage]="'url(assets/imgs/'+ prepareImage(item) +'.jpg)'"></div>
                                          <div class="game-card__info game-card__title">{{item.Category}}
                                            <div class="game-card__rating-and-price">
                                              <div class="game-card__rating"><span>5.0</span><i class="ico_star"></i></div>
                                              <div class="game-card__price"><span>Polecana</span></div>
                                          </div>
                                          <div class="recommend-slide__btn"><button class="uk-button uk-button-danger" type="button">Zobacz</button></div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>
                          <div class="swipper-nav">
                              <div class="swiper-button-prev"></div>
                              <div class="swiper-button-next"></div>
                          </div>
                          <div class="swiper-pagination"></div>
                      </div>
                  </div>
              </div>
              <div class="uk-width-1-1">
                <div style="margin-top: 50px;" *ngFor="let item of (categoryFactory.category | async)">
                  
                  <p [routerLink]="['/collection']" [queryParams]="{ type: ''+item.MainCategory+''}" style="cursor: pointer;"><span class="uk-text-lead">{{item.MainCategory}}</span> - Zobacz wszystkie</p>
                  <div class="uk-grid uk-child-width-1-6@xl uk-child-width-1-3@l uk-child-width-1-2@s uk-flex-middle uk-grid-small" data-uk-grid>
                    <div *ngFor="let item2 of item.Categories | slice:0:6">
                        <div class="game-card" [routerLink]="['/list']" style="cursor: pointer"
                        [queryParams]="{ type: ''+item2.Category+'', main: ''+item.MainCategory+''}">
                            <div class="game-card__box">
                                <div class="game-card__media" style="background-size: cover;" [style.backgroundImage]="'url(assets/imgs/'+ prepareImage(item2) +'.jpg)'"></div>
                                <div class="game-card__info">{{item2.Category}}</div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
              </div>
              <ng-template #loading>
                <div class="loadingSpinner">
                    <ion-spinner></ion-spinner>
                  </div>
              </ng-template>
          </div>
      </main>
  </div>
</div>