import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class PlatformService {
    public isMobile = new BehaviorSubject<boolean>(true);
    constructor(private platform: Platform){
        this.checkIsMobile();
    }

   public checkIsMobile(){
    if(this.platform.is('android' || 'mobile')){
      this.isMobile.next(true);
    }else {
        this.isMobile.next(false);
      }
   }
}