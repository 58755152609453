import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouteModel } from "./model/route.model";
import { NavigationFactoryService } from "./navigation-factory.service";

@Component({
    selector: 'controller',
    templateUrl: '../../global/ref.component.html',
  })
export class NavigationService {
    @ViewChild('processContainer', { read: ViewContainerRef }) container;
    private route;
    constructor(private activatedRoute: ActivatedRoute, private navigation: NavigationFactoryService){
        this.route = activatedRoute.snapshot?.url[0]?.path;
    }

    ngAfterViewInit(){
        var componentLoad: RouteModel = this.navigation.getComponent(this.route);
        if(componentLoad){
            this.container.createComponent(componentLoad.componentRef);
        }
    }
}