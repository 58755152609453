import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { ValidationHelper } from 'src/app/helpers/validation-helper';
import { AdMobService } from 'src/app/services/admob/admob.service';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'UserDataComponent',
  templateUrl: 'user-data.componenet.html',
  styleUrls: ['user-data.componenet.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDataMobileComponent implements OnInit {
  @Input() changingPropertyName: string;
  @Input() changingPropertyValue: string;

  public propertyValue = new BehaviorSubject<string>("");

  constructor(
    private modalCtr: ModalController,
    private alertHelper: AlertHelper,
    public modalCtrl: ModalController,
    private admob: AdMobService,
    private productService: ProductsService,
    private validationHelper: ValidationHelper) {
  }

  ngOnInit() {
    this.admob.hideBanner();
    this.propertyValue.next(this.changingPropertyValue);
  }

  public async cancel() {
    await this.modalCtr.dismiss({successful: false, newValue: ''});
  }

  public async change(){
    this.propertyValue.next(this.changingPropertyValue);
    if(this.checkInputs()){
      var result = await this.productService.changeUserData(this.changingPropertyName, this.propertyValue.getValue());
      
      if(result.status){
        this.alertHelper.presentDefaultAlert("Success", result.message, ["OK"])
        await this.modalCtr.dismiss({successful: true, newValue: this.changingPropertyName});
      }else{
        this.alertHelper.presentDefaultAlert("Incorrect data",  result.message,  ["Try again"]);
      }
    }
  }

  private checkInputs(){
    /*if( this.changingPropertyName == 'nick'){
      if(!this.validationHelper.validateNick(this.propertyValue.getValue()))
      {
        return false;
      }
      return true;
    }
    else*/ if( this.changingPropertyName == 'email'){
      if(!this.validationHelper.validateEmail(this.propertyValue.getValue()))
      {
        return false;
      }
      return true;
    }
    else if( this.changingPropertyName == 'password'){
      if(!this.validationHelper.validatePassword(this.propertyValue.getValue()))
      {
        return false;
      }
      return true;
    }
    this.alertHelper.presentDefaultAlert("Incorrect data", this.changingPropertyName + " can't be changed",  ["Try again"]);
    return false;
  }
}