import { AffiliateModel } from "../models/affiliate.model";

export class DefaultAffiliate implements AffiliateModel {
    private url: string;
    
    constructor(url: string){
        this.url = url;
    }

    public generateUrl(){
        window.open(this.url, "_blank");
    }
}