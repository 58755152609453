import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { SiteFilter, getSiteFilterWithAll, CountryFilter, CategoryModel, getCategories, SortByCategory, getSortBy, getCountryFilter, getSiteFilter } from 'src/app/models/dashboard/filter.model';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'search-filter',
  templateUrl: 'search-filter.component.html',
  styleUrls: ['search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFilterMobileComponent implements OnInit {
  public sites: SiteFilter[] = getSiteFilterWithAll();
  @Input() public filterByCountry: CountryFilter[] = [];
  @Input() public filterByPriceMin: number = 0;
  @Input() public filterByPriceMax: number = 0;
  @Input() public filterByOnlyAmazon: boolean;
  @Input() public filterShowAllProduct: boolean = false;
  @Input() public selectedDiscountRange: number = 0;
  @Input() public filterselectedSortBy: number = 0;
  @Input() public filterByCategory: CategoryModel;
  @Input() public filterBySite: SiteFilter[];
  @Input() public selectedSite: string[];
  @Input() public shopProduct: boolean = false;
  @Input() public isAmazon: boolean = false;
  @Input() public oneShop: boolean = false;
  @Input() public selectedfilterByDateProduct: string = "Wszystko";

  public filterByDateList: any[] = ["Wszystko", "Ostatni dzień", "Ostatnie 3 dni", "Ostatnie 7 dni"];
  public categories: CategoryModel[] = getCategories();
  public sortBy: SortByCategory[] = getSortBy();
  public filterByDiscountRange: number[] = [0, 1, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90];

  constructor(public modalCtrl: ModalController) {
  }

  ngOnInit() {
    this.selectedSite = this.filterBySite.map(x => x.site); 
  }

  changeSite(site: SiteFilter){
    site.checked = true;
    this.filterBySite.push(site);
  }
  
  close() {
    this.filterBySite = this.selectedSite.map(x =>{
      var ss: SiteFilter= {
        site: x,
        checked: true,
      }

      return ss;
    });
    if(this.filterByPriceMax == 0){
      this.filterByPriceMax = 999999;
    }

    return this.modalCtrl.dismiss({ filterByCountry: this.filterByCountry, 
                                    filterByPriceMin: this.filterByPriceMin,
                                    filterByPriceMax: this.filterByPriceMax, 
                                    filterByOnlyAmazon: this.filterByOnlyAmazon, 
                                    filterByDiscountRange: this.selectedDiscountRange,
                                    filterByCategory: this.filterByCategory,
                                    filterBySite: this.filterBySite,
                                    filterShowAllProduct: this.filterShowAllProduct,
                                    selectedSortBy: this.filterselectedSortBy,
                                    selectedfilterByDateProduct: this.selectedfilterByDateProduct,
                                  });
  }

  public categorySelectionChanged(newCategory: string){
    let selectedForm = this.categories.find((f)=>{
      return f.id === newCategory;
    });
    this.filterByCategory = selectedForm;
  }

  public checkAmazon() {
    return this.selectedSite.find(x => x == 'amazon') != undefined ? true : false;
  }

  resetFilter(){
    this.filterByOnlyAmazon =  false;
    this.filterByPriceMin =  0;
    this.filterByPriceMax =  999999;
    this.filterselectedSortBy =  0;
    this.selectedDiscountRange = 0;
    this.filterShowAllProduct = true;
    this.selectedfilterByDateProduct = "Wszystko";
    if(!this.oneShop){
      this.selectedSite = ["amazon", "mediamarkt", "mediaexpert", "xkom", "rtveuroagd"]; 
    }
    
    this.filterByCountry = getCountryFilter(); 
  }

  public reset(){
    return this.modalCtrl.dismiss({ reset: true
    });
  }
}