<ion-header mode="ios">
  <ion-toolbar>
      <ion-button (click)="resetFilter()" slot="start" fill="clear">
        <ion-icon name="refresh-outline"></ion-icon>
        <ion-label>reset</ion-label>
      </ion-button>
      <ion-title>Filters</ion-title>
      <ion-button (click)="close()" slot="end" fill="clear">
        <ion-icon name="save-outline"></ion-icon>
        <ion-label>Save</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-header>
<ion-content class="main-content">
  <div class="main_content_div">
    <div class="white_div">
      <ion-list *ngIf="!isAmazon && !oneShop">
        <ion-label style="padding: 10px;">Sklepy</ion-label>
        <ion-item>
          <ion-select [(ngModel)]="selectedSite" placeholder="Wybierz strony" [multiple]="true">
            <ion-select-option *ngFor="let site of sites">{{site.site}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    <div style="background: var(--ion-item-background, var(--ion-background-color, #fff));">
      <ion-label class="head_lbl padding-5px">Cena:</ion-label>
      <ion-grid fixed>
        <ion-row>
          <ion-col>
            <ion-input type="text" placeholder="Min" [(ngModel)]="filterByPriceMin"></ion-input>
          </ion-col>
          <ion-col>
            <ion-input type="text" placeholder="Max" [(ngModel)]="filterByPriceMax"></ion-input>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
      
      <ion-item *ngIf="shopProduct">
        <ion-label>Pokaż niedostępne produkty</ion-label>
        <ion-toggle [(ngModel)]="filterShowAllProduct"></ion-toggle>
      </ion-item>
        <ion-item *ngIf="shopProduct">
            <ion-label>Pokaż Verified</ion-label>
            <ion-toggle [(ngModel)]="filterShowAllProduct"></ion-toggle>
        </ion-item>
      <ion-item>
        <ion-label>Sortuj po:</ion-label>
        <ion-select [(ngModel)]="++filterselectedSortBy" placeholder="Sortowanie">
          <ion-select-option *ngFor="let item of sortBy" [value]="item.id">{{item.name}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>Minimalna zniżka:</ion-label>
        <ion-select [disabled]="filterShowAllProduct" [(ngModel)]="++selectedDiscountRange" placeholder="Min Discount Procentage">
          <ion-select-option *ngFor="let item of filterByDiscountRange" [value]="item">{{item}} %</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>Pokaż:</ion-label>
        <ion-select [(ngModel)]="selectedfilterByDateProduct" placeholder="Data">
          <ion-select-option *ngFor="let item of filterByDateList" [value]="item">{{item}}</ion-select-option>
        </ion-select>
      </ion-item>
      <div *ngIf="isAmazon || checkAmazon()" style="height: 72%;">
        <ion-label class="head_lbl padding-5px">Amazon:</ion-label>
        <ion-item>
          <ion-label>Dostawa przez Amazon</ion-label>
          <ion-toggle [(ngModel)]="filterByOnlyAmazon"></ion-toggle>
        </ion-item>
        <div>
          <ion-item *ngFor="let entry of filterByCountry">
            <ion-label>{{entry.country}}</ion-label>
            <ion-checkbox [(ngModel)]="entry.checked"></ion-checkbox>
          </ion-item> 
        </div>
        
      </div>
    </div>
  </div>
</ion-content>