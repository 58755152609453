import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ProductsModel } from 'src/app/models/products/product.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { ProductMobileComponent } from 'src/mobile/product-modal/product.component';
import { Sites, Countries } from '../../models/dashboard/filter.model';

@Component({
  selector: 'product-list',
  templateUrl: 'product-list.component.html',
  styleUrls: ['product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductListComponent implements OnInit {
  @Input() public productList: ProductsModel[];
  @Input() public addEvent = new BehaviorSubject<boolean>(false);
  @Input() public blockView = false;
  @Output() addLikeEvent = new EventEmitter<any>();
  public user_Plan = this.authService.isPremium;
  public isMobile = this.platform.isMobile;

  constructor(private navCtrl: NavController, 
    private modalCtrl: ModalController,
    private productService: ProductsService,
    private authService: AuthService,
    private platform: PlatformService) {

  }

  ngOnInit() {
  }
  
  async openProduct(product: ProductsModel){
    const modal = await this.modalCtrl.create({
      component: ProductMobileComponent,
      componentProps: {
        'product': product
      }
    });
  
    return await modal.present();
  }

  getPrice(product: ProductsModel){
    if(product.site == Sites.Amazon){
      switch(product.lowest.country){
        case Countries.PL:
          return product.prices.pl.price;
        case Countries.DE:
          return product.prices.de.price;
        case Countries.ES:
          return product.prices.es.price;
        case Countries.FR:
          return product.prices.fr.price;
        case Countries.IT:
          return product.prices.it.price;
        case "co." + Countries.UK:
          return product.prices.uk.price;
      }
    }
    else if(product.site == Sites.MediaMarkt){
      return product.price;
    }else if(product.site == Sites.MediaExpert){
      return product.price;
    }else if(product.site == Sites.xkom){
      return product.price;
    }else if(product.site == Sites.Euro){
      return product.price;
    }
  }

  shouldShowCountryForSite(product: ProductsModel) : boolean{
    if(product.site == Sites.Amazon) return true;
    return false;
  }

  iconSelect(product: ProductsModel){
    if(product.liked == 0){
      return "bookmark-outline";
    }
    return "bookmark";
  }

  async addLike(product: ProductsModel){
    await this.productService.addLikedProduct(product).then((response) =>{ 
      this.addLikeEvent.emit({response, product});
    });
  }

  public goUnlock(){
    this.authService.isPremium.next(1);
    this.navCtrl.navigateForward('payment');
  }
}