import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProductsModel } from '../models/products/product.model';
import { AuthService } from '../services/auth/auth.service';
import { ProductsGuestService } from '../services/guest/products-guest.service';
import { LoaderService } from '../services/loader/loader.service';
@Component({
  selector: 'share',
  templateUrl: 'share.component.html',
  styleUrls: ['share.component.scss']
})
export class ShareComponent implements OnInit {
  public subscriptionEvents: Subscription;
  public isGuest = true;
  public product = new BehaviorSubject<ProductsModel>(undefined);
    constructor(public loaderService: LoaderService, 
      private navCtrl: NavController, 
      private productService: ProductsGuestService,
      private toastController: ToastController,
      private _route: ActivatedRoute,
      public auth: AuthService) {
      
    }

  ngOnInit(): void {
    if (this.auth.isAuth.getValue()) {
        this.isGuest = false;
    }
  }

    ionViewDidEnter(){
      this.subscriptionEvents = this._route.queryParamMap
      .subscribe((params: any) => {
        var prod = undefined;
        if(params.get('id')){
          prod = params.get('id');
        }else if(params.params.prod){
          prod = params.params.prod;
        }

        if(prod != undefined){
          this.loaderService.createLoader();
          this.productService.getProductByToken(params.params.prod).then(x =>{
            if(x){
              this.loaderService.dimissLoader();
              this.product.next(x);
            }else{
              this.presentToast('bottom');
              this.navCtrl.navigateRoot('dashboard');
            }
          });
        }else{
          this.navCtrl.navigateRoot('dashboard');
        }
      });
    }

    ionViewDidLeave(): void {
      this.subscriptionEvents.unsubscribe();
    }

    async presentToast(position: 'top' | 'middle' | 'bottom') {
      const toast = await this.toastController.create({
        message: 'Produkt nie istnieje!',
        duration: 1500,
        position: position
      });
  
      await toast.present();
    }
  }