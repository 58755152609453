import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AlertController, IonSlides, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { IPayPalConfig } from 'ngx-paypal';
import { PayPalService } from '../services/paypal/paypal.service';
@Component({
  selector: 'payment',
  templateUrl: 'payment.component.html',
  styleUrls: ['payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentViewComponent implements OnInit {
  userList = [
    {
        img : 'assets/imgs/music-player.png',
        name : 'Reinstall OS',
    },
    {
        img : 'assets/imgs/keyboard.png',
        name : 'Keyboard Service',
    },
    {
        img : 'assets/imgs/harddisk.png',
        name : 'Harddidk Service',
    },
    {
        img : 'assets/imgs/wifi.png',
        name : 'Wifi Error',
    }
  ];

  public planPrice = new BehaviorSubject<number>(4.99);
  public planSelected = 1;

  constructor(private route: Router, private navCtrl: NavController, public paypal: PayPalService) { }

  ngOnInit() {
  }

  checkValue(value){
    if(this.planSelected == 1){
      this.planPrice.next(4.99);
      this.paypal.changePrice(4.99);
    }

    if(this.planSelected == 2){ 
      this.planPrice.next(8.99);
      this.paypal.changePrice(8.99);
    }
  }

  createOrder(): IPayPalConfig{
    return this.paypal.payPalConfig;
  }

  changePlan(){

  }

  goToBack() {
    this.navCtrl.back();
  }

  goToPayment() {
    
    this.route.navigate(['/payment-success'], {replaceUrl: true});
  }

  goToTrackOrder() {
      this.route.navigate(['/tracking-detail'], {replaceUrl: true});
  }

}