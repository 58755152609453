import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { AdMobService } from '../admob/admob.service';
import { DisallowedPage } from '../admob/page.model';
import { ModalManagerService } from '../modal/modal-manager.service';
import { PlatformService } from '../platform/platform.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService {  
    private clickCount = 0;
    constructor(private modal: ModalManagerService,private readonly modalController: ModalController,private platformService: PlatformService,private admob: AdMobService ,private navCtrl: NavController, public auth: AuthService, public router: Router) {
        this.clickCount = 0;
    }  
    async canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        return this.modalController.getTop().then(async (modal) => {
            if(modal){
                this.modalController.dismiss();
                return false;
            }
            
            var routeIndex = route.url.findIndex(x => DisallowedPage.includes(x.path));
            if(routeIndex > -1){
                this.admob.hideBanner();
            }else{
                if(this.admob.showingBanner.getValue() == false){
                    this.admob.showBaner();
                }
            }
            if(this.clickCount > 10){
                this.clickCount = 0;
                if(this.platformService.isMobile.getValue()){
                    this.admob.showInterstitial();
                }
            }
            
            this.clickCount++;
            if (!this.auth.isAuth.getValue()) {
                this.navCtrl.navigateRoot(['login']);
                return false;
            }
            return true;
          });
    }
}