import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { InviteCode } from 'src/app/models/products/invite-code.model';
import { AdMobService } from 'src/app/services/admob/admob.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'invite-codes',
  templateUrl: 'invite-codes.component.html',
  styleUrls: ['invite-codes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteCodesBrowserViewComponent implements OnInit{
  public loading = new BehaviorSubject(true);
  public inviteCodes = new BehaviorSubject<InviteCode[]>([]);
  constructor(public modalCtrl: ModalController,
    private productService: ProductsService,
    public loaderService: LoaderService,
    private admob: AdMobService,
    public toastController: ToastService,
    public clipboard: Clipboard, 
    private navCtrl: NavController) {
  }
  ngOnInit(): void {
    this.admob.hideBanner();
    this.loaderService.createLoader();
    this.productService.getInviteTokens().then(x =>{
      this.inviteCodes.next(x);
      this.loaderService.dimissLoader();
    });
  }

  async goBack() {
    await this.navCtrl.back();
  }

  copyLink(item: InviteCode){
    this.clipboard.copy(item.token);
    this.toastController.presentToast('bottom', 'Kod skopiowany');
  }
}