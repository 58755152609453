import { Injectable } from "@angular/core";
import { AlertHelper } from "./alert-helper"

@Injectable()
export class ValidationHelper {
    constructor(private alertHelper: AlertHelper){
    }

    private forbiddenWords: string[] = ["anal", "anus", "arse", "ass", "ballsack", "balls", "bastard", "bitch", "biatch", "bloody", "blowjob", "blow job", "bollock", "bollok", "boner", "boob", "bugger", "bum", "butt", "buttplug", "clitoris", "cock", 
    "coon", "crap", "cunt", "damn", "dick", "dildo", "dyke", "fag", "feck", "fellate", "fellatio", "felching", "fuck", "f u c k", "fudgepacker", "fudge packer", "flange", "Goddamn", "God damn", "hell", "homo", "jerk", "jizz", "knobend", "knob end", 
    "labia", "lmao", "lmfao", "muff", "nigger", "nigga", "omg", "penis", "piss", "poop", "prick", "pube", "pussy", "queer", "scrotum", "sex", "shit", "s hit", "sh1t", "slut", "smegma", "spunk", "tit", "tosser", "turd", "twat", "vagina", "wank", "whore", "wtf"];

    public validateNick(nickName: string){
        if(!nickName){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Nazwa użytkownika jest wymagana!", ["Spróbuj ponownie"]);
            return false;
        }

        if(!(nickName.length >= 6 && nickName.length <= 30)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Nazwa użytkownika mieć od 6 do 30 znaków.", ["Spróbuj ponownie"]);
            return false;
        }
    
        if(!this.isNickNameCorrect(nickName)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Nazwa użytkownika musi zaczynać się od liter (A-Z lub a-z).", ["Spróbuj ponownie"]);
            return false;
        }
        
        return true;
    }

    public validatePassword(password: string){
        if(!password){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Hasło jest wymagane!", ["Spróbuj ponownie"]);
            return false;
        }
      
        if(!(password.length >= 6 && password.length <= 30)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Hasło musi mieć od 6 do 30 znaków.", ["Spróbuj ponownie"]);
            return false;
        }
      
        if(!this.isPasswordCorrect(password)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Hasło jest nie poprawne, poprawne hasło musi posiadać przynjmnie jedną literę (A-Z) i jedną liczbę: (0-9).", ["Spróbuj ponownie"]);
            return false;
        }

        return true;
    }

    public validateEmail(email: string){
        if(!email){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "E-mail jest wymagany!",["Ok"]);
            return false;
        }

        if(!this.isEmailCorrect(email)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Email jest niepoprawny.", ["Ok"]);
            return false;
        }

        return true;
    }

    public validateComment(message: string){
        message = message.replace(/\s\s+/g, ' ');
        if(message == ' ' || message == ''){
            this.alertHelper.presentDefaultAlert("Zbyt mało danych", "Komentarz nie może być pusty.", ["Ok"]);
            return false;
          }
        if(message.length < 2 || message.length > 200){
            this.alertHelper.presentDefaultAlert("Zbyt mało danych", "Komentarz może mieć od 2 do 200 znaków.", ["Ok"]);
            return false;
        }
        var containsForbidenWord = this.checkMessageForForbidenWords(message);
        if(containsForbidenWord != false){
            this.alertHelper.presentDefaultAlert("", "Komentarz zawiera zakazane słowo " + containsForbidenWord + " lub podobne słowo, usuń je żeby go wysłać.", ["Ok"]);
            return false;
        }
      
        return true;
    }

    public validateSender(sender: string){
        if(!sender){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Imię i Nazwisko osoby wysyłającej jest wymagane.", ["Ok"]);
            return false;
        }

        if(!this.isSenderCorrect(sender)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Wymagane jest Imię oraz nazwisko ordzielone pojedyńczą spacją.", ["Ok"]);
            return false;
        }

        return true;
    }

    public validateMessage(message: string){
        message = message.replace(/\s\s+/g, ' ');
        if(message.trim() == ''){
            this.alertHelper.presentDefaultAlert("Zbyt mało danych", "Wiadomość nie może być pusta.", ["Ok"]);
            return false;
          }
        if(message.length < 2 || message.length > 200){
            this.alertHelper.presentDefaultAlert("Zbyt mało danych", "Wiadomość może mieć od 2 do 200 znaków.", ["Ok"]);
            return false;
        }
        var containsForbidenWord = this.checkMessageForForbidenWords(message);
        if(containsForbidenWord != false){
            this.alertHelper.presentDefaultAlert("", "Wiadomość zawiera zakazane słowo " + containsForbidenWord + " lub podobne słowo, usuń je żeby ją wysłać.", ["Ok"]);
            return false;
        }
      
        return true;
    }

    public isNickNameCorrect(nickName: string) : boolean {
        var correct = true;
        correct = correct && /^[a-zA-Z]{1}/i.test(nickName);
        //correct = correct && /([0-9]+)/i.test(nickName);
        //correct = correct && /([a-z]+)/i.test(nickName);
        //correct = correct && /([A-Z]+)/i.test(nickName);
        //correct = correct && /([,.!*+-]+)/i.test(nickName);

        return correct;
    }

    public isPasswordCorrect(password: string) : boolean {
        return /^(?=.*[a-z])(?=.*\d).{6,}$/.test(password);
    }

    public isEmailCorrect(email: string) : boolean {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(email);
    }

    private checkMessageForForbidenWords(message: string) : boolean|string{
        var flag: boolean|string = false;
        this.forbiddenWords.forEach(word => {
            if(message.includes(word)) {
                flag = word;
                this.alertHelper.presentDefaultAlert("Zakazane słowo", "Komentarz zawiera zakazane słowo " + word + " lub podobne słowo, usuń je żeby wysłać komentaż.", ["Ok"]);
                return;
            }
        });

        return flag;
    }

    public isSenderCorrect(sender: string) : boolean {
        var correct = true;
        correct = correct && /^[a-zA-Z]{1,} [a-zA-Z]{1,}/i.test(sender);

        return correct;
    }
}


