import { ShopSubCategoryResponse } from "./dbCategory.model";

export var popular: ShopSubCategoryResponse[] = [
    {
        img : 'one',
        Category: "Ostatnidzien",
    },
    {
        img : 'Ostatnie',
        Category: "Ostatnie",
    },
    {
        img : 'Amazon',
        Category: "Amazon",
    },
    {
        img : 'MediaExpert',
        Category: "MediaExpert",
    },
    {
        img : 'EuroRtvAgd',
        Category: "EuroRtvAgd",
    },
    {
        img : 'MediaMarkt',
        Category: "MediaMarkt",
    },
    {
        img : 'XKom',
        Category: "XKom",
    },
    {
        img : 'tree',
        Category: "Ostatne3dni",
    },
    {
        img : 'seven',
        Category: "Ostatne7dni",
    }
];