import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NavController } from '@ionic/angular';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { CategoryFactory } from 'src/app/models/category/category.model';
import { SearchTags, ShopSubCategoryResponse } from 'src/app/models/category/models/dbCategory.model';
import { BannerList } from 'src/app/models/dashboard/banner.model';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopsMobileViewComponent implements OnInit, OnDestroy {
  constructor(
    public categoryFactory: CategoryFactory,
    public loaderService: LoaderService,
    private navCtrl: NavController,
    private productService: ProductsService,
    private _route: ActivatedRoute) { }
  
  public searchTagsList = new BehaviorSubject<SearchTags[]>([]);
  public searchTags = new BehaviorSubject<SearchTags[]>([]);
  public searchTagsLoading = new BehaviorSubject<boolean>(true);
  public startSearching = new BehaviorSubject<boolean>(false);
  public searchCategoryLoading = new BehaviorSubject<boolean>(true);
  public popularCategory = new BehaviorSubject<ShopSubCategoryResponse[]>([]);
  public bannerList = new BehaviorSubject<BannerList[]>([]);
  public searchTextValue = "";
  public subscriptionEvents: Subscription;

  ngOnInit(){
    this.startSearching.next(false);
    this.searchTextValue = "";
    if(this.popularCategory.getValue().length <= 0){
      this.searchCategoryLoading.next(true);
      this.loaderService.createLoader();
      this.prepareStart();
    }else{
      this.searchTags.next(this.searchTagsList.getValue().slice(0, 10));
    }
  
    
    document.getElementById('searchList').style.visibility = "hidden";

    this.startSearching.next(false);

    this.subscriptionEvents = this._route.queryParamMap
        .subscribe((params: any) => {
          this.startSearching.next(false);
          document.getElementById('searchList').style.visibility = "hidden";
        });
  }

  ngOnDestroy(): void {
    this.subscriptionEvents.unsubscribe();
  }

  tagInfinite(ev){
    var len = this.searchTags.getValue().length;
    this.searchTags.next([...this.searchTags.getValue(),...this.searchTagsList.getValue().filter(x => x.Tag.toLowerCase().includes(this.searchTextValue.toLowerCase())).slice(len, len + 10)]);
    (ev as any).target.complete()
  }
  
  prepareStart(){
    this.searchTextValue = "";
    this.categoryFactory.category.subscribe(x => {
      if(x.length <= 0){
        return;
      }

      var tegs = this.categoryFactory.getAllTags();
      this.searchTagsList.next(tegs);
      this.searchTags.next(tegs.slice(0, 10));
      this.searchTagsLoading.next(false);
      this.loaderService.dimissLoader();
    });
    
    this.searchCategoryLoading.next(false);
    this.productService.getBannerList().then(x => {
      this.bannerList.next(x);
      this.loaderService.dimissLoader();
    });
    this.popularCategory.next(this.categoryFactory.getPopularCategory());
  }

  prepareImage(item){
    return (item.img ?? item.Category).replace(/\s/g, "");
  }

  startSearch(){
    document.getElementById("searchBar").classList.add("active");
  }

  focusSearch(){
    this.startSearching.next(true);
    document.getElementById('searchList').style.visibility = "visible";
  }

  unfocusSearch(){
    this.startSearching.next(false);
    document.getElementById('searchList').style.visibility = "hidden";
  }

  public onKeySearch(event: any) {
    this.searchTag(event.target.value);
  }

  public onEnter(){
    this.startSearching.next(true);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        type: "Wszystko",
        main: this.searchTextValue,
        tag: this.searchTextValue
      }
    };
    this.navCtrl.navigateForward(['list'], navigationExtras)
  }

  private searchTag(search: string){
    this.searchTags.next(this.searchTagsList.getValue().filter(x => x.Tag.toLowerCase().includes(search.toLowerCase())));
    if(this.searchTags.getValue().length <= 0){
      this.searchTags.next([{
        Category: "Wszystko",
        Tag: this.searchTextValue,
        Main: this.searchTextValue,
      }]);
    }
  }
}
