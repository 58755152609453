import { IonContent, ModalController, NavController, Platform  } from '@ionic/angular';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CategoryFactory } from 'src/app/models/category/category.model';
import { Countries, CountryFilter, getCountryFilter, getSiteFilter, getSiteFilterWithAll, getSortBy, SiteFilter, Sites, SortByCategory } from 'src/app/models/dashboard/filter.model';
import { ProductsModel } from 'src/app/models/products/product.model';
import { ProductsService } from 'src/app/services/products/products.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { AdMobService } from 'src/app/services/admob/admob.service';
import { Plugins } from '@capacitor/core'; 
import { SearchFilterMobileComponent } from 'src/mobile/search/search-filter/search-filter.component';
const { Keyboard } = Plugins;

@Component({
  selector: 'shops-list',
  templateUrl: './shops-list.component.html',
  styleUrls: ['../../assets/css/libs.min.css', '../../assets/css/main.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionsListBrowserViewComponent implements OnInit, OnDestroy, AfterViewChecked {

  tabID = 1;
  @Output() addLikeEvent = new EventEmitter<any>();
  @ViewChild(IonContent, { static: false }) content: IonContent;
  public title = new BehaviorSubject<string>("Loading...");

  public oneSiteFilter = ["MediaExpert", "EuroRtvAgd", "MediaMarkt", "XKom"];

  //Filter
  public sites: SiteFilter[] = getSiteFilterWithAll();
  public sortBy: SortByCategory[] = getSortBy();
  public filterByDiscountRangeList: any[] = [0, 1, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90];
  public filterByDateList: any[] = ["Wszystko", "Ostatni dzień", "Ostatnie 3 dni", "Ostatnie 7 dni"];
  public selectedSite: string[];
  public filterByPriceMin: number | string = 0;
  public filterByPriceMax: number | string = 999999;
  private oneShop = false;
  public shopProduct: boolean = this.title.getValue() == "Amazon" ? false : true;
  public filterShowAllProduct: boolean | string = false;
  public selectedSortBy: number | string = 0;
  public filterByDiscountRange: number | string = 30;
  public filterByDateProduct: string = "Wszystko";
  public selectedDiscountRange: number = 50;
  public filterByDate: Date = undefined;
  public filterByOnlyAmazon = localStorage.getItem('filterByOnlyAmazon') ?? false;
  public filterByCountry: CountryFilter[] = undefined;

  public subscriptionEvents: Subscription;
  public subCategory = new BehaviorSubject<string[]>([]);
  public selectedSubCategory = new BehaviorSubject<string[]>([]);
  public mainCategory = "";
  public showSubCategory = new BehaviorSubject(true);
  public keyword = "";
  private lastCategory = "";
  private filterBySite: SiteFilter[] = undefined ; 
  public endProduct = new BehaviorSubject<boolean>(false);
  public addClick = false;
  public isAmazon = false;
  private forceLoadScrool = false;
  public scrollPosition = 0;
  public productList = new BehaviorSubject<ProductsModel[]>([]);
  constructor(private productService: ProductsService,
    public loaderService: LoaderService,
    public modalCtrl: ModalController, 
    private route: Router, 
    private navCtrl: NavController, 
    private _route: ActivatedRoute, 
    private admob: AdMobService,
    private platform: Platform,
    private categoryFactory: CategoryFactory) {
    this.loadFilter();
   }
   ionViewDidLeave(){
  }

  public listContentScrollEvent(event){
    this.scrollPosition = event?.detail?.scrollTop ?? 0;
  }

  ngOnDestroy(): void {
    this.subscriptionEvents.unsubscribe();
  }

  loadFilter(){
    this.filterByOnlyAmazon = localStorage.getItem('filterByOnlyAmazon') ?? false;
    this.filterByPriceMin = localStorage.getItem('Shop_filterByPriceMin') ?? 0;
    this.filterByPriceMax = localStorage.getItem('Shop_filterByPriceMax') ?? 999999;
    this.selectedSortBy = localStorage.getItem('Shop_selectedSortBy') ?? 0;
    this.filterByDiscountRange = localStorage.getItem('Shop_filterByDiscountRange') ?? 0;
    this.filterByDateProduct = localStorage.getItem('Shop_filterByDateProduct') ?? "Wszystko";
    this.filterShowAllProduct = localStorage.getItem('Shop_filterShowAllProduct') ?? true;
    this.filterBySite = !localStorage.getItem('Shop2_filterSites5') == null || localStorage.getItem('Shop2_filterSites5') == undefined ? getSiteFilter() : JSON.parse(localStorage.getItem('Shop2_filterSites5')); 
    this.filterByCountry = !localStorage.getItem('Shop_filterCountry') == null || localStorage.getItem('Shop_filterCountry') == undefined ? getCountryFilter() : JSON.parse(localStorage.getItem('Shop_filterCountry')); 
  
  }

  ngOnInit() {
    this.selectedSite = this.filterBySite.map(x => x.site); 
    this.prepare();
  }

  ngAfterViewChecked() {        
    if(this.forceLoadScrool && this.scrollPosition != 0 && this.productList?.getValue()?.length > 0){
      this.forceLoadScrool = false;
      this.content.scrollToPoint(0, this.scrollPosition);
      return;
    }     
  } 

  prepare(){
    this.productList.next([]);
    this.title.next("Loading...");
    this.keyword = "";
    this.subscriptionEvents = this._route.queryParamMap
        .subscribe((params: any) => {
          if(this.lastCategory == params.params.type && this.productList?.getValue()?.length > 0){
            this.forceLoadScrool = true;
            return;
          }

          this.lastCategory = params.params.type;
          this.endProduct.next(false);
          if(params.params.prod){
            this.loaderService.createLoader();
            this.productService.getProductByToken(params.params.prod).then(x =>{
              if(x){
                this.navCtrl.navigateRoot('dashboard');
                this.loaderService.dimissLoader();
                this.openProduct(x);
              }else{
                this.navCtrl.navigateRoot('dashboard');
              }
            });
            
            return;
          }

          this.loadFilter();
          this.selectedSubCategory?.next([]);
          this.subCategory.next([]);
          this.loaderService.createLoader();

          this.keyword = "";
          this.productList.next([]);
          this.title.next(params.params.type);
          this.mainCategory = params.params.main;

          if(params.params.type == "Amazon"){
            this.isAmazon = true;
            this.subCategory.next(["Ostatnio znalezione", "Zniżka powyżej 60%"]);
            this.selectedSubCategory?.next(["Ostatnio znalezione"]);
            this.categoryFactory.downloadProduct("Amazon", 
            this.selectedSubCategory?.getValue(), 0, 30, 0, 
            this.filterByPriceMin, 
            this.filterByPriceMax, this.filterByDiscountRange, ["amazon"], 
            false, this.selectedSortBy, this.filterByCountry, this.filterByOnlyAmazon, this.keyword.length <= 0 ? undefined : this.keyword, this.mapDate()).then((response) =>{ 
              this.productList.next(response.filter((c, index) => {
                return response.indexOf(c) === index;
              }));
              this.checkProductIsset(true);
              this.loaderService.dimissLoader();
          });
          }else if(this.title.getValue() == "Najlepiej oceniane"){
            this.selectedSortBy = 5;
            this.isAmazon = false;
            this.categoryFactory.downloadProduct(undefined, 
              undefined, this.productList.getValue().length, 30, 0, 
              this.filterByPriceMin, 
              this.filterByPriceMax, this.filterByDiscountRange, 
              this.filterBySite ? this.filterBySite?.filter(x => x.checked).map(x => x.site) : undefined, 
              this.filterShowAllProduct, 5,
              this.filterByCountry, this.filterByOnlyAmazon,
              undefined, this.mapDate())
              .then(item => {
                this.productList.next(item.filter((c, index) => {
                  return item.indexOf(c) === index;
                }));
                this.checkProductIsset(true);
                this.loaderService.dimissLoader();
              });
          }else{
            this.isAmazon = false;
            this.categoryFactory.getTags(params.params.main, params.params.type).then(x =>{
              this.subCategory.next(x);
              if(params.params.tag){
                this.selectedSubCategory?.next(x.filter(item => item.includes(params.params.tag)));
              }

              this.filterByDate = undefined;
              if(params.params.type == "Wszystko"){
                this.oneShop = false;
                this.showSubCategory.next(false);
                if(params.params.tag){
                  this.keyword = params.params.tag;
                  this.selectedSubCategory?.next(undefined);
                }else{
                  this.title.next(params.params.type + "-" + params.params.main);
                  this.selectedSubCategory?.next(undefined);
                }
              }else if(params.params.type == "Ostatnidzien"){
                this.oneShop = false;
                this.filterByDateProduct = "Ostatni dzień";
              }else if(params.params.type == "Ostatne3dni"){
                this.oneShop = false;
                this.filterByDateProduct = "Ostatnie 3 dni";
              }else if(params.params.type == "Ostatne7dni"){
                this.oneShop = false;
                this.filterByDateProduct = "Ostatnie 7 dni";
              }else if(params.params.type == "MediaExpert"){
                this.oneShop = true;
                this.filterBySite = this.filterBySite.map(x => {
                  if(x.site == Sites.MediaExpert){
                    x.checked = true;
                  }else{
                    x.checked = false;
                  }

                  return x;
                });
              }else if(params.params.type == "EuroRtvAgd"){
                this.oneShop = true;
                this.filterBySite = this.filterBySite.map(x => {
                  if(x.site == Sites.Euro){
                    x.checked = true;
                  }else{
                    x.checked = false;
                  }

                  return x;
                });
              }else if(params.params.type == "MediaMarkt"){
                this.oneShop = true;
                this.filterBySite = this.filterBySite.map(x => {
                  if(x.site == Sites.MediaMarkt){
                    x.checked = true;
                  }else{
                    x.checked = false;
                  }

                  return x;
                });
              }else if(params.params.type == "XKom"){
                this.oneShop = true;
                this.filterBySite = this.filterBySite.map(x => {
                  if(x.site == Sites.xkom){
                    x.checked = true;
                  }else{
                    x.checked = false;
                  }

                  return x;
                });
              }else{
                this.oneShop = false;
              }

              this.categoryFactory.downloadProduct(
                params.params.type == "Ostatnie" || 
                params.params.type == "Ostatnidzien" ||
                params.params.type == "Ostatne3dni" ||
                params.params.type == "Ostatne7dni" ||
                params.params.type == "MediaExpert" || 
                params.params.type == "EuroRtvAgd" || 
                params.params.type == "MediaMarkt" || 
                params.params.type == "XKom" ? undefined : params.params.type, 
                this.subCategory?.getValue() ?? undefined, this.productList.getValue().length, 30, 0, 
                this.filterByPriceMin, 
                this.filterByPriceMax, this.filterByDiscountRange, 
                this.filterBySite ? this.filterBySite?.filter(x => x.checked).map(x => x.site) : undefined, 
                this.filterShowAllProduct, this.selectedSortBy,
                this.filterByCountry, this.filterByOnlyAmazon,
                this.keyword == "" ? undefined : this.keyword, this.mapDate())
              .then(item => {
                this.productList.next(item.filter((c, index) => {
                  return item.indexOf(c) === index;
                }));
                this.checkProductIsset(true);
                this.loaderService.dimissLoader();
              });
            });
          }
        }
      );
  }

  checkProductIsset(initStart: boolean = false, last: number = 0){
    if(this.productList.getValue().length == last){
      this.endProduct.next(true);
    }else{
      this.endProduct.next(false);
    }
  }

  checkIsOneSite(){
    if(this.oneSiteFilter.includes(this.title.getValue())){
      return true;
    }

    return false;
  }

  doInfinite(event) {
    this.getNextItems(event, false, false);
  }

  startChangeSubCategory(index: string){
    if(this.title.getValue() == "Amazon"){
      this.selectedSubCategory?.next([index]);
    }else{
      var check = this.selectedSubCategory?.getValue().find(x => x == index);
      if(!check){
        this.selectedSubCategory?.next([...this.selectedSubCategory?.getValue(), index])
      }else{
        this.selectedSubCategory?.next(this.selectedSubCategory?.getValue().filter(x => x != index));
      }
    }
    
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(() => {
      $this.scrollUp();
      $this.changeSubCategory(index);
    }, 700);
  }

  changeSubCategory(index: string){
    this.loaderService.createLoader();
    this.endProduct.next(false);
    var lastProductLength = 0;
    if(this.title.getValue() == "Amazon"){
      this.loaderService.createLoader();
      this.categoryFactory.downloadProduct("Amazon", 
            this.selectedSubCategory?.getValue(), 0, 30, 0, 
            this.filterByPriceMin, 
            this.filterByPriceMax, index == "Zniżka powyżej 60%" ? 60 : this.filterByDiscountRange, ["amazon"], 
            false, this.selectedSortBy, this.filterByCountry, this.filterByOnlyAmazon, this.keyword.length <= 0 ? undefined : this.keyword, this.mapDate()).then((response) =>{ 
              this.productList.next(response.filter((c, index) => {
                return response.indexOf(c) === index;
              }));
              this.checkProductIsset(false, lastProductLength);
              this.loaderService.dimissLoader();
          });
    }else{
      this.categoryFactory.downloadProduct(this.title.getValue(), this.selectedSubCategory?.getValue().length == 0 ? this.subCategory.getValue() : this.selectedSubCategory?.getValue(), 0, 30, 0, 
      this.filterByPriceMin, 
      this.filterByPriceMax, this.filterByDiscountRange, this.filterBySite?.filter(x => x.checked).map(x => x.site), 
      this.filterShowAllProduct, this.selectedSortBy, this.filterByCountry, this.filterByOnlyAmazon, this.keyword == "" ? undefined : this.keyword, this.mapDate())
      .then(item => {
          this.productList.next(item.filter((c, index) => {
            return item.indexOf(c) === index;
          }));
          this.checkProductIsset(false, lastProductLength);
          this.loaderService.dimissLoader();
      });
    }
  }

  checkSubCategory(item: string){
    if(this.showSubCategory){
      return this.selectedSubCategory?.getValue()?.find(x => x == item) ? true : false;
    }
  }

  getPrice(product: ProductsModel){
    if(product.site == Sites.Amazon){
      switch(product.lowest.country){
        case Countries.PL:
          return product.prices.pl.price;
        case Countries.DE:
          return product.prices.de.price;
        case Countries.ES:
          return product.prices.es.price;
        case Countries.FR:
          return product.prices.fr.price;
        case Countries.IT:
          return product.prices.it.price;
        case "co." + Countries.UK:
          return product.prices.uk.price;
      }
    }
    else if(product.site == Sites.MediaMarkt){
      return product.price;
    }else if(product.site == Sites.MediaExpert){
      return product.price;
    }else if(product.site == Sites.xkom){
      return product.price;
    }else if(product.site == Sites.Euro){
      return product.price;
    }
  }

  async getNextItems(event: any, isRefresh: boolean, forceLoad: boolean = true) {
    var lastProductLength = this.productList.getValue().length;
    if(forceLoad){
      this.loaderService.createLoader();
    }

    if(isRefresh){
      this.loaderService.createLoader();
      lastProductLength = 0;
      this.content.scrollToTop(400);
    }

    this.endProduct.next(false);
    if(this.title.getValue() == "Amazon"){
      this.categoryFactory.downloadProduct("Amazon", 
      this.selectedSubCategory?.getValue(), isRefresh == true ? 0 : this.productList.getValue().length, 30, 0, 
      this.filterByPriceMin, 
      this.filterByPriceMax, this.selectedSubCategory?.getValue()[0] == "Zniżka powyżej 60%" ? 60 : this.filterByDiscountRange, ["amazon"], 
      false, this.selectedSortBy, this.filterByCountry, this.filterByOnlyAmazon, this.keyword == "" ? undefined : this.keyword, this.mapDate()).then((response) =>{ 
        if(isRefresh){
                this.productList.next(response.filter((c, index) => {
                  return response.indexOf(c) === index;
                }));
              }else{
                this.productList.next([...this.productList.getValue(),...response.filter((c, index) => {
                  return response.indexOf(c) === index;
                })]);
              }

              this.loaderService.dimissLoader();
              if(event){
                event?.target?.complete();
              }
          });
    }else if(this.title.getValue() == "Najlepiej oceniane"){
      this.categoryFactory.downloadProduct(this.title.getValue() == "Ostatnie" ||
      this.title.getValue() == "Ostatnidzien" ||
      this.title.getValue() == "Ostatne3dni" ||
      this.title.getValue() == "Ostatne7dni" ||
      this.title.getValue() == "MediaExpert" || 
      this.title.getValue() == "EuroRtvAgd" || 
      this.title.getValue() == "MediaMarkt" || 
      this.title.getValue() == "XKom" ? undefined : this.title.getValue(), 
      this.selectedSubCategory?.getValue(), isRefresh == true ? 0 : this.productList.getValue().length, 30, 0, 
      this.filterByPriceMin, 
      this.filterByPriceMax, this.filterByDiscountRange, this.filterBySite ? this.filterBySite?.filter(x => x.checked).map(x => x.site) : undefined, 
      this.filterShowAllProduct, 5, this.filterByCountry, this.filterByOnlyAmazon, this.keyword == "" ? undefined : this.keyword, this.mapDate())
      .then(item => {
        if(isRefresh){
          this.productList.next(item.filter((c, index) => {
            return item.indexOf(c) === index;
          }));
        }else{
          this.productList.next([...this.productList.getValue(), ...item.filter((c, index) => {
            return item.indexOf(c) === index;
          })]);
        }
        this.checkProductIsset(false, lastProductLength);
        this.loaderService.dimissLoader();
        if(event?.target){
          event?.target?.complete();
        }
        if(forceLoad){
          this.loaderService.dimissLoader();
        }
      });
    }else{
      this.categoryFactory.downloadProduct(this.title.getValue() == "Ostatnie" || 
      this.title.getValue() == "Ostatnidzien" || 
      this.title.getValue() == "Ostatne3dni" ||
      this.title.getValue() == "Ostatne7dni" ||
      this.title.getValue() == "MediaExpert" || 
      this.title.getValue() == "EuroRtvAgd" || 
      this.title.getValue() == "MediaMarkt" || 
      this.title.getValue() == "XKom" ? undefined : this.title.getValue(), 
      this.selectedSubCategory?.getValue()?.length == 0 ? this.subCategory.getValue() : this.selectedSubCategory?.getValue(), isRefresh == true ? 0 : this.productList.getValue().length ?? 0, 30, 0, 
      this.filterByPriceMin, 
      this.filterByPriceMax, this.filterByDiscountRange, this.filterBySite ? this.filterBySite?.filter(x => x.checked).map(x => x.site) : undefined, 
      this.filterShowAllProduct, this.selectedSortBy, this.filterByCountry, this.filterByOnlyAmazon, this.keyword == "" ? undefined : this.keyword, this.mapDate())
      .then(item => {
        if(isRefresh){
          this.productList.next(item.filter((c, index) => {
            return item.indexOf(c) === index;
          }));
        }else{
          this.productList.next([...this.productList.getValue(), ...item.filter((c, index) => {
            return item.indexOf(c) === index;
          })]);
        }
        this.checkProductIsset(false, lastProductLength);
        this.loaderService.dimissLoader();
        if(event?.target){
          event?.target?.complete();
        }
        if(forceLoad){
          this.loaderService.dimissLoader();
        }
      });
    }
  }

  iconSelect(product: ProductsModel){
    if(product.liked == 0){
      return "bookmark-outline";
    }
    return "bookmark";
  }

  async addLike(product: ProductsModel){
    this.addClick = true;
    await this.productService.addLikedProduct(product).then((response) =>{ 
      var productFind = this.productList.getValue().find(item => item.title === product.title && item.url == product.url);
      if(productFind){
        if(productFind.liked == 0){
          productFind.liked = 1;
        }else{
          productFind.liked = 0;
        }

        this.productList.next(this.productList.getValue());
      }
      this.addClick = false;
      this.addLikeEvent.emit({response, product});
    });
  }

  goToBack() {
      this.navCtrl.back();
  }

  goToCart() {
      this.route.navigate(['/cart']);
  }

  async openProduct(product: ProductsModel){
    this.route.navigate(['product', product._id]);
  }
  @ViewChild('searchBox') input ;
  searchBar(){
    
  }
  timeout: any = 1000;

  public onEnter(){
    this.getNextItems(undefined, true, true);
  }

  public onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(() => {
      if (event.keyCode != 13) {
        $this.executeListing(event.target.value);
      }
    }, 1000);
  }

  private executeListing(value: string) {
    this.keyword = value;
    if(this.platform.is('mobile' || 'mobileweb')){
      Keyboard.hide();
    }
    
    this.getNextItems(undefined, true);
  }
  
  async openFilter(){
    this.admob.hideBanner();
    const modalView = await this.modalCtrl.create({
      component: SearchFilterMobileComponent,
      componentProps: {
        'filterByCountry': this.filterByCountry,
        'filterByPriceMin': this.filterByPriceMin,
        'filterByPriceMax': this.filterByPriceMax,
        'filterByOnlyAmazon': this.filterByOnlyAmazon,
        'selectedDiscountRange': this.filterByDiscountRange,
        'selectedfilterByDateProduct': this.filterByDateProduct,
        'filterBySite': this.filterBySite,
        'filterShowAllProduct': this.filterShowAllProduct,
        'filterselectedSortBy': this.selectedSortBy,
        'shopProduct': this.title.getValue() == "Amazon" ? false : true,
        'isAmazon': this.isAmazon
      }
    });

    await modalView.present();
    const { data } = await modalView.onWillDismiss();
    this.admob.showBaner();
    if(data){
      this.filterByCountry = data.filterByCountry;
      this.filterByPriceMin = data.filterByPriceMin;
      this.filterByPriceMax = data.filterByPriceMax;
      this.filterByOnlyAmazon = data.filterByOnlyAmazon;
      this.filterByDiscountRange = data.filterByDiscountRange;
      this.filterBySite = data.filterBySite;
      this.filterShowAllProduct = data.filterShowAllProduct;
      this.selectedSortBy = data.selectedSortBy;

      localStorage.setItem('Shop_filterCountry', JSON.stringify(data.filterByCountry));
      localStorage.setItem('Shop_filterByPriceMin', data.filterByPriceMin);
      localStorage.setItem('Shop_filterByPriceMax', data.filterByPriceMax);
      localStorage.setItem('filterByOnlyAmazon', data.filterByOnlyAmazon);
      localStorage.setItem('Shop_filterByDiscountRange', data.filterByDiscountRange);
      localStorage.setItem('Shop_filterShowAllProduct', data.filterShowAllProduct);
      localStorage.setItem('Shop2_filterSites5', JSON.stringify(data.filterBySite));
      localStorage.setItem('Shop_selectedSortBy', data.selectedSortBy);
      this.loaderService.createLoader();
      this.endProduct.next(false);
      this.getNextItems(undefined, true);
      this.content.scrollToTop(400);
    }
  }

  startFiltr(){
      if(!this.oneShop){
        this.filterBySite = this.filterBySite.map(x => {
          if(this.selectedSite.find(y => y.includes(x.site))){
            x.checked = true;
          }else{
            x.checked = false;
          }

          return x;
        });
      }

      this.saveFilter();
      this.loaderService.createLoader();
      this.endProduct.next(false);
      this.getNextItems(undefined, true);
      this.content.scrollToTop(400);
  }

  saveFilter(){
    localStorage.setItem('Shop_filterCountry', JSON.stringify(this.filterByCountry));
    localStorage.setItem('Shop_filterByPriceMin', this.filterByPriceMin.toString());
    localStorage.setItem('Shop_filterByPriceMax', this.filterByPriceMax.toString());
    localStorage.setItem('filterByOnlyAmazon', this.filterByOnlyAmazon.toString());
    localStorage.setItem('Shop_filterByDiscountRange', this.filterByDiscountRange.toString());
    localStorage.setItem('Shop_filterByDateProduct', this.filterByDateProduct.toString());
    localStorage.setItem('Shop_filterShowAllProduct', this.filterShowAllProduct.toString());
    localStorage.setItem('Shop2_filterSites5', JSON.stringify(this.filterBySite));
    localStorage.setItem('Shop_selectedSortBy', this.selectedSortBy.toString());
  }

  resetFilter(){
    this.filterByOnlyAmazon =  false;
    this.filterByPriceMin =  0;
    this.filterByPriceMax =  999999;
    this.selectedSortBy =  0;
    this.filterByDateProduct = "Wszystko";
    this.filterByDiscountRange = 0;
    this.filterShowAllProduct = true;
    if(!this.oneShop){
      this.filterBySite = getSiteFilter(); 
    }
    
    this.sites = getSiteFilter();
    this.selectedSite = this.sites.map(x => x.site); 
    this.filterByCountry =getCountryFilter(); 
    this.saveFilter();
  }

  mapDate(){
    switch(this.filterByDateProduct){
      case "Wszystko":
        return 0;
        break;
      case "Ostatni dzień":
        return 1;
        break;
      case "Ostatnie 3 dni":
        return 3;
        break;
      case "Ostatnie 7 dni":
        return 7;
        break;
    }
  }

  scrollUp(){
    this.content.scrollToTop(400);
  }

  public checkAmazon() {
    return this.selectedSite.find(x => x == 'amazon') != undefined ? true : false;
  }

  private uniqueArray(target: Array<any>, property: any): Array<any> {
    return target.filter((item, index) =>
      index === target.findIndex(t => 
        t[property] === item[property]
      )
    );
  }
}
