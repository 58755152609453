import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { ValidationHelper } from '../../helpers/validation-helper'
import { CommentModel } from 'src/app/models/products/product.model';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'edit-comment',
  templateUrl: 'edit-comment.component.html',
  styleUrls: ['edit-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCommandViewComponent{
  @Input() comment: CommentModel;
  @Input() public onSuccess = (comment: CommentModel) => {};
  @Input() public onBack = () => {};
  public isSending = new BehaviorSubject<boolean>(true);
  public isSending$ = new Observable<boolean>();
  constructor(public modalCtrl: ModalController,
    private alertHelper: AlertHelper,
    private modalCtr: ModalController,
    private productService: ProductsService,
    private validationHelper: ValidationHelper) {
  }

  async goBack() {
    this.onBack();
  }

  async edit(){
    if(this.validationHelper.validateComment(this.comment.message)){
      this.comment.isEdited = true;
      this.comment.editDate = new Date();
      var result = await this.productService.editComment(this.comment);
      
      if(result == true){
        this.alertHelper.presentDefaultAlert("", "Komentaż został zedytowany", ["OK"]);
        this.onSuccess(this.comment);
      }else if(result == false){
        this.comment.isEdited = false;
        this.comment.editDate = undefined;
        this.alertHelper.presentDefaultAlert("Błąd edycji komentaża",  "Cos poszlo nie tak...",  ["Spróbuj ponownie"]);
      }
      else{
          this.alertHelper.presentDefaultAlert("Błąd edycji komentaż", result);
      }
    }
  }

  private checkInputs(){
    return true;
  }
}