import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { ValidationHelper } from 'src/app/helpers/validation-helper';
import { MongoDBRequest } from 'src/app/models/auth/mongodb-response.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { RegisterBrowserViewComponent } from 'src/browser/auth/register/register.component';

@Component({
  selector: 'register',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.scss']
})
export class RegisterMobileViewComponent implements AfterViewInit {
  public userModel = new MongoDBRequest();
  public isSending = new BehaviorSubject<boolean>(true);
  public isSending$ = new Observable<boolean>();
  public isMobile = true;
  @ViewChild('processContainer', { read: ViewContainerRef }) container;
  constructor(
    private navCtrl: NavController, 
    private authService: AuthService,
    private alertHelper: AlertHelper,
    private validationHelper: ValidationHelper,
    public platformService: PlatformService) {
  }

  ionViewDidEnter() {
    if(this.authService.isAuth.getValue() == true){
      this.navCtrl.navigateRoot('dashboard');
    }

    this.isSending$ = this.isSending.asObservable();
  }

  ngAfterViewInit(){
    if(!this.platformService.isMobile.getValue()){
      this.isMobile = false;
      this.loadBrowserComponent();
    }
  }

  loadBrowserComponent(){
    this.container.createComponent(RegisterBrowserViewComponent);
  }
  
  goLogin()  {
    this.navCtrl.navigateForward('login');
  }

  public async sendRegisterRequest(){
    if(!this.checkInputs()){
      return;
    }
    this.isSending.next(!this.isSending.getValue());
    var result = await this.authService.createRegisterRequest(this.userModel);

    if(result === true){
      this.navCtrl.navigateForward('dashboard');
    }else if(result === false || result === null){
      this.alertHelper.presentDefaultAlert("Something is wrong", "Check all data or connection", ["Try again"]);
    }
    else{
      this.alertHelper.presentDefaultAlert("Insufficient data", result as string, ["Try again"]);
    }
    this.isSending.next(!this.isSending.getValue());
  }

  private checkInputs(){

    if(!this.validationHelper.validateNick(this.userModel.nickname))
    {
      return false;
    }
    
    if(!this.validationHelper.validateEmail(this.userModel.email))
    {
      return false;
    }

    if(!this.validationHelper.validatePassword(this.userModel.password))
    {
      return false;
    }
  
    if(!this.userModel.inviteCode){
      this.alertHelper.presentDefaultAlert("Insufficient data", "Invite code is required!", ["Try again"]);
      return false;
    }

    return true;
  }
}