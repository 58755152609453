import { AffiliateModel } from "../models/affiliate.model";

export class EuroAffiliate implements AffiliateModel {
    private url: string;
    private pattern = 'https://clkpl.tradedoubler.com/click?p(322104)a(3286179)g(25084526)url($s)';
    
    constructor(url: string){
        this.url = url;
    }

    public generateUrl(){
        window.open(this.pattern.replace("$s", this.url), "_blank");
    }
}