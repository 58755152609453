<ion-content>
  <div class="main_content_div">   
    <ion-label class="main_head">Forgot Password</ion-label>
    
    <div class="form_div" *ngIf="isSending$ | async;else isSending">

      <div class="tri"></div>

      <ion-label>Email</ion-label>
      <ion-input type="text" placeholder="Email" [(ngModel)]="email"></ion-input>
      <ion-button expand="block" (click)="sendForgotRequest()">
        Send code
      </ion-button>
      <div *ngIf="!(isLoged | async)">
        <ion-label class="lbl">Don't have an account ? <span (click)="goRegister()" class="signup_span">Sign Up</span></ion-label>
        <ion-label class="lbl">Back to login <span (click)="goLogin()" class="signup_span">Login</span></ion-label>
      </div>
    </div>
    <ng-template #isSending>
      <div class="form_div">
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </div>
    </ng-template>
    
  </div>
</ion-content>