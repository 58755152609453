import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { BehaviorSubject, Subject } from 'rxjs';
import { Sites } from 'src/app/models/dashboard/filter.model';
import { ArchivProduct } from 'src/app/models/products/archivProduct.model';
import { CommentModel, ProductsModel } from 'src/app/models/products/product.model';
import { VoteProductModel } from 'src/app/models/products/vote-product.model';
import { GlobalDiagramService } from 'src/app/services/global/product/diagram.service';
import { ProductsGuestService } from 'src/app/services/guest/products-guest.service';

@Component({
  selector: 'productGuest',
  templateUrl: '../../../mobile/product-modal/product.component.html',
  styleUrls: ['../../../mobile/product-modal/product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGuestComponent implements OnInit {
  comments_number = 0;
  commnets_limit = 10;

  public loadingProductIfToken = new BehaviorSubject(true);
  public sitesEnum: typeof Sites = Sites;
  public likeIcon = new BehaviorSubject<string>('bookmark-outline');
  public message: string = "";
  public showComments = new BehaviorSubject<boolean>(false);
  public selectedChartFilter = "m";
  public selectedChartFilterMonth = "Promocyjna cena";
  public chartMonths = ['Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Pażdziernik',
  'Listopad',
  'Grudzień'];
  public iswebMobile = this.platform.is('mobileweb');
  public searchRange = [7, 30, 183, 356]
  public chartOptions = ['Orginalna cena', 'Promocyjna cena'];
  plt;
  private voted = false;
  public voteModel = new BehaviorSubject<VoteProductModel>(new VoteProductModel);
  public connectionCrash = new BehaviorSubject<boolean>(false);
  public commentsList = new BehaviorSubject<CommentModel[]>([]);
  public loadingChart = new BehaviorSubject<boolean>(true);
  public archivProduct = new BehaviorSubject<ArchivProduct[] | undefined>([]);
  public addComments = new BehaviorSubject<boolean>(false);
  public addCommentMainEmit = new Subject<string>();
  @Input() public product: ProductsModel;
  @Input() public isGuest: boolean;
  @Output() public addVote = new EventEmitter<ProductsModel>();
  public selectedSegment = 'about';
  basicAlertCard: any;
  constructor(private platform: Platform,private toastController: ToastController,public alertCtrl: AlertController, 
    private navCtrl: NavController, 
    public diagramService: GlobalDiagramService) {
      this.plt = localStorage.getItem('platform');
  }
  ngOnInit() {

  }

  changeFilterChart(){
    
  }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  showBasicAlert(fruits) {
    
  }

  getImage(){
    return this.product.image;
  }

  async presentAlert(headerMsg, msg) {
    const alert = await this.alertCtrl.create({
      header: headerMsg,
      message: msg,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: (Cancle) => {
          }
        }, {
          text: 'OK',
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }

  goBack() {
    this.navCtrl.back();
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  public openUrl(url: string){
    window.open(url, "_blank");
  }

  public selectedChartFilterMonthOption(item: any){
    this.selectedChartFilterMonth = item;
    this.changeFilterChart();
  }

  public generateToken(){
        
  }

  public async sendVote(status: number){
  }

  getFilterMainData(current: Date){

  }
  
  async close() {
    this.navCtrl.navigateRoot(['dashboard']);
  }

  public async addComment(){

  }

  public getCountrFlag(country: string){
    return 'assets/icon/country/' + country + '.svg';
  }

  public coptToClibord(text: string){

  }

  public getSite():string{
    return this.product.site;
  }

  async presentToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Link copied',
      duration: 1500,
      position: position
    });

    await toast.present();
  }

  tabID = 'Styczeń';

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [
      'Orginalna cena', 'Promocyjna cena'
    ],
    datasets: []
  };

  public lineChartLabels: Array<any> = ['0', '1', '2', '3', '4', '5', '6'];

  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false,
    plugins: {
      title: {
        display: true,
        text: 'Wykres cen'
      }
    }
  };

  public lineChartLegend = false;
  public lineChartType = 'line';

  public  async addLike(product: ProductsModel){

  }
}