import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { ValidationHelper } from 'src/app/helpers/validation-helper';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'change-password-mobile',
  templateUrl: 'change-password.component.html',
  styleUrls: ['change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeMobilePasswordViewComponent implements OnInit {

  plt;
  public code: string;
  public password: string;
  public isSending = new BehaviorSubject<boolean>(true);
  public isSending$ = new Observable<boolean>();
  constructor(private navCtrl: NavController,  
    private authService: AuthService,
    private alertHelper: AlertHelper,
    private validationHelper: ValidationHelper) {

    this.plt = localStorage.getItem('platform');
  }

  ngOnInit() {
    this.isSending$ = this.isSending.asObservable();
  }

  async sendForgotRequest(){
    if(!this.checkInputs()){
      return;
    }

    this.isSending.next(!this.isSending.getValue());
    var result = await this.authService.createChangePasswordRequest(this.code, this.password);

    if(result === true){
      this.navCtrl.navigateRoot('login');
    }else if(result === false || result === null){
      this.alertHelper.presentDefaultAlert("Something is wrong", "Check all data or connection", ["Try again"]);
    }
    else{
      this.alertHelper.presentDefaultAlert("Insufficient data", result as string, ["Try again"]);
    }

    this.isSending.next(!this.isSending.getValue());
  }

  goRegister()  {
    this.navCtrl.navigateForward('register');
  }

  async handleButtonClick() {
    this.alertHelper.presentDefaultAlert("Something broken", "Your username or password seem to be broken :(", ["Register again"]);
  }

  checkInputs(){

    if(this.code == "")
    {
      return false;
    }
    
    if(!this.validationHelper.validatePassword(this.password)){
      return false;
    }

    return true;
  }
}