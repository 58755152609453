import { Directive, ViewContainerRef } from "@angular/core";

@Directive({
  selector:'[appAdHost]'
})

export class AnchorDirective{
  constructor(public viewContainerRef: ViewContainerRef){
    
  }
}