<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button mode="md"></ion-back-button>
    </ion-buttons>
    <ion-title slot="start">Poznaj aplikację</ion-title>
    <ion-buttons slot="end">
      <ion-button color='dark' (click)="dashboard()">Pomiń </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class="bg">
  <ion-slides #slides pager="true" [options]="slideOpts">
    <ion-slide>
      <img src="assets/imgs/slider1.png" class="slide-image" />
      <h2 class="slide-title" style="font-size:x-large;">
        Setki tysięcy produktów w jednym miejscu z realną historią cen!
      </h2>
      <p style="font-size:initial;">
        <b>Codziennie</b> wszystkie produkty są aktualizowane przez co masz pewność, że przeglądasz aktualne oferty sklepów!
        Dzięki temu zawsze masz <b>realną</b> historię cen! 
      </p>
    </ion-slide>

    <ion-slide>
      <img src="assets/imgs/slider2.png" class="slide-image" />
      <h2 style="font-size:x-large;" class="slide-title">Realna historia cen</h2>
      <p style="font-size:initial;">
        Wykres cen jest codziennie sprawdzany przez co zawsze masz realną historię cen produktów!
      </p>
    </ion-slide>
    <ion-slide>
      <img src="assets/imgs/slider3.png" class="slide-image" />
      <h2 style="font-size:x-large;" class="slide-title">Zapisz - Powiadomienie!</h2>
      <p style="font-size:initial;">
        Zapisz produkt do ulubionych... a gdy jego cena spadnie otrzymasz powiadomienie!</p>
    </ion-slide>

    <ion-slide>
      <img src="assets/imgs/slider4.png" class="slide-image" />
      <h2 tyle="font-size:x-large;" class="slide-title">Uczestnicz w społeczności</h2>
      <p style="font-size:initial;">W łatwy sposób możesz komentować produkty jak i oceniać okazję. Wspomagaj innych i twórz z Nami super zespół
      Rekinów Promocji</p>
      <ion-button fill="clear" color="dark" (click)="dashboard()">
        Zaczynam łowy
        <ion-icon slot="end" name="arrow-forward"></ion-icon>
      </ion-button>
    </ion-slide>
  </ion-slides>
</ion-content>