import { AlertController } from '@ionic/angular';
import { Injectable } from "@angular/core";

@Injectable()
export class AlertHelper {
    constructor(private alertController: AlertController){
    }

    public async presentDefaultAlert(header: string, message: string, buttons: string[] = ['OK']){
        const alert = await this.alertController.create({
          header: header,
          message: message,
          buttons: buttons,
        });
    
        await alert.present();
    }
}