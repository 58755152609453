import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavController, ModalController, ToastController } from '@ionic/angular';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'info',
  templateUrl: 'info.component.html',
  styleUrls: ['info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoViewComponent {
  
  constructor(private navCtrl: NavController, 
    public modalCtrl: ModalController,
    public loaderService: LoaderService,
    private toastController: ToastController,
    private clipboard: Clipboard) {
    
  }

  ionViewWillEnter() {
    
  }

  goBack()  {
    this.navCtrl.back();
  }

  copyEmail(){
    this.clipboard.copy("biuro@darkfusion.pl");
    this.presentToast('bottom');
  }

  async presentToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Link copied',
      duration: 1500,
      position: position
    });

    await toast.present();
  }

  openFb(){
    window.open("https://www.facebook.com/profile.php?id=100087825745624", "_blank");
  }

  openStart(){
    this.navCtrl.navigateRoot("start");
  }
}