<div class="page-wrapper">
    <main class="page-first-screen">
        <div class="uk-grid uk-grid-small uk-child-width-1-2@s uk-flex-middle uk-width-1-1" data-uk-grid>
            <div class="logo-big">
                <img src="assets/imgs/logoDark.png" alt="logo">
                <span>Amazpep</span>
                <h1 style="margin-top: 35px;">Bo wszystko sprowadza się do jednego...</h1>
            </div>
            <div *ngIf="isSending$ | async;else isSending">
                <div class="form-login">
                    <div class="form-login__social">
                        <ul class="social">
                            <li><a href="https://www.facebook.com/profile.php?id=100087825745624"><ion-icon style="font-size: 30px;" name="logo-facebook"></ion-icon></a></li>
                        </ul>
                    </div>
                    <div class="form-login__box">
                        <div class="uk-heading-line uk-text-center"><span>Rejestracja</span></div>
                        <form action="#!">
                            <div class="uk-margin"><input class="uk-input" type="email" placeholder="Email" name="email" [(ngModel)]="userModel.email" (keyup.enter)="sendRegisterRequest()"></div>
                            <div class="uk-margin"><input class="uk-input" type="text" placeholder="Twój pseudonim" name="nickname" [(ngModel)]="userModel.nickname" (keyup.enter)="sendRegisterRequest()"></div>
                            <div class="uk-margin"><input class="uk-input" type="password" [(ngModel)]="userModel.password" name="password" placeholder="Hasło" (keyup.enter)="sendRegisterRequest()"></div>
                            <div class="uk-margin"><input class="uk-input" type="text" placeholder="Kod zaproszenia" name="inviteCode" [(ngModel)]="userModel.inviteCode" (keyup.enter)="sendRegisterRequest()"></div>
                            <div class="uk-margin"><button class="uk-button uk-button-danger uk-width-1-1" (click)="sendRegisterRequest()" (keyup.enter)="sendRegisterRequest()" type="button">Rejestracja</button></div>
                            <div class="uk-text-center uk-margin-small-left" style="cursor: pointer;" (click)="goLogin()"><span>Masz już konto?</span> Zaloguj</div>
                        </form>
                    </div>
                </div>
            </div>
            <ng-template #isSending>
                <div class="form_div">
                  <ion-progress-bar type="indeterminate"></ion-progress-bar>
                </div>
              </ng-template>
        </div>
    </main>
</div>
