<div class="page-login">
  <div class="page-wrapper">
    <main class="page-first-screen">
        <div class="uk-grid uk-grid-small uk-child-width-1-2@s uk-flex-middle uk-width-1-1" data-uk-grid>
            <div class="logo-big">
                <img src="assets/imgs/logoDark.png" alt="logo">
                <span>Amazpep</span>
                <h1 style="margin-top: 35px;">Bo wszystko sprowadza się do jednego...</h1>
            </div>
            <div>
                <div class="form-login">
                    <div class="form-login__social">
                        <ul class="social">
                            <li><a href="https://www.facebook.com/profile.php?id=100087825745624"><ion-icon style="font-size: 30px;" name="logo-facebook"></ion-icon></a></li>
                        </ul>
                    </div>
                    <div class="form-login__box">
                        <div class="uk-heading-line uk-text-center"><span>Zaloguj się</span></div>
                        <form action="#!">
                            <div class="uk-margin"><input class="uk-input" type="text" name="nickname" placeholder="Nickname" [(ngModel)]="nickname" (keyup.enter)="sendLoginRequest()"></div>
                            <div class="uk-margin"><input class="uk-input" type="password" name="password" placeholder="Password" [(ngModel)]="password" (keyup.enter)="sendLoginRequest()"></div>
                            <div class="uk-margin uk-button uk-button-danger uk-width-1-1" (click)="sendLoginRequest()" (keyup.enter)="sendLoginRequest()">Zaloguj</div>
                            <div class="uk-margin uk-text-center" style="cursor: pointer;" (click)="forgot()">Nie pamiętam hasła?</div>
                            <hr>
                            <div class="uk-text-center" style="cursor: pointer;" (click)="goRegister()"><span>Nie posiadam konta?</span> Rejestracja</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
  </div>
</div>