<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-button (click)="goBack()" fill="clear" slot="start">
            <ion-icon name="arrow-back" style="font-size: 26px;color: black"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
  <div class="main_content_div">

      <ion-label class="welcome_lbl">Edit Profile</ion-label>

      <div [style.backgroundImage]="'url(assets/imgs/profile.png)'" class="user_back"></div>

      <ion-item>
          <ion-label position="floating">Nick</ion-label>
          <ion-input disabled [(ngModel)]="userData.nick"></ion-input>
      </ion-item>

      <ion-item>
          <ion-label position="floating">Email</ion-label>
          <ion-input [(ngModel)]="userData.email"></ion-input>
      </ion-item>

      <ion-button expand="block" (click)="edit()">
          Edit Profile
      </ion-button>

  </div>
</ion-content>
