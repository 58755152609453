import { Injectable } from "@angular/core";
import { SocketIOService } from "../socket-io/socket-io-client.service";
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { NavController } from "@ionic/angular";

@Injectable()
export class PayPalService {
    public payPalConfig?: IPayPalConfig;
    private price: number = 4.99;
    private plan: string = "1";
    
    public changePrice(price: number){
        this.price = price;
    }

    public changePlan(plan: number){
        this.plan = plan.toString();
    }

    private async sendOrderToBridge(data: any){
        var result = await new Promise(resolve => {
            this.socketService?.getSocket().emit('createOrder', data, () => {
            
                resolve(0);
            });
        });
    };

    constructor(private socketService: SocketIOService, private navCtrl: NavController){
        this.payPalConfig = {
            currency: 'EUR',
            
            clientId: 'AQpjnu3oMf1FbDPjlstooTdbg1nrgphwua2nZLUlr4-BqaNJ6eKllIrcUE-HPpHsDXlV_0l-LCxb9ElB',
            createOrderOnClient: (data) => <ICreateOrderRequest>{
              intent: 'CAPTURE',
              purchase_units: [
                {
                  amount: {
                    currency_code: 'EUR',
                    value: this.price.toString(),
                    breakdown: {
                      item_total: {
                        currency_code: 'EUR',
                        value: this.price.toString()
                      }
                    }
                  },
                  items: [
                    {
                      name: this.plan + ' month PREMIUM Subscription',
                      quantity: '1',
                      category: 'DIGITAL_GOODS',
                      unit_amount: {
                        currency_code: 'EUR',
                        value: this.price.toString(),
                      },
                    }
                  ]
                }
              ]
            },
            advanced: {
              commit: 'true',
            },
            style: {
                shape: 'pill',
                color: 'blue',
                layout: 'horizontal',
                label: 'pay',
                tagline: true
            },
            vault: "true",
            onApprove: (data, actions) => {
              //console.log('onApprove - transaction was approved, but not authorized', data, actions);
              actions.order.get().then(details => {
                //console.log('onApprove - you can get full order details inside onApprove: ', details);
              });
            },
            onClientAuthorization: async (data) => {
              //console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
              await this.sendOrderToBridge(data);
              this.navCtrl.navigateForward('payment-success', {replaceUrl: true});
            },
            onCancel: async (data, actions) => {
              //console.log('OnCancel', data, actions);
              this.navCtrl.navigateForward('payment-error', {replaceUrl: true});
            },
            onError: err => {
              //console.log('OnError', err);
              this.navCtrl.navigateForward('payment-error', {replaceUrl: true});
            },
            onClick: (data, actions) => {
              //console.log('onClick', data, actions);
            },
          };
    }
}