import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ProductsService } from 'src/app/services/products/products.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ChartConfiguration, ChartOptions } from "chart.js";
import { BaseChartDirective } from 'ng2-charts';
import { Sites } from 'src/app/models/dashboard/filter.model';
import { ArchivProduct } from 'src/app/models/products/archivProduct.model';
import { CommentModel, ProductsModel } from 'src/app/models/products/product.model';
import { VoteProductModel } from 'src/app/models/products/vote-product.model';
import { AdMobService } from 'src/app/services/admob/admob.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AffiliateService } from 'src/app/services/affiliate/affiliate.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalManagerService } from 'src/app/services/modal/modal-manager.service';
import { PreviousRouteService } from 'src/app/services/route/previous.service';

@Component({
  selector: 'product',
  templateUrl: 'product.component.html',
  styleUrls: ['product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMobileComponent implements OnInit, OnDestroy {
  comments_number = 0;
  commnets_limit = 10;

  public sitesEnum: typeof Sites = Sites;
  public message: string = "";
  public showComments = new BehaviorSubject<boolean>(false);
  public selectedChartFilter = "w";
  public selectedChartFilterMonth = "Promocyjna cena";
  public chartMonths = ['Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Pażdziernik',
  'Listopad',
  'Grudzień'];

  public searchRange = [14, 30, 183, 356]
  public chartOptions = ['Orginalna cena', 'Promocyjna cena'];
  plt;
  public loadingProductIfToken = new BehaviorSubject(true);
  private voted = false;
  public voteModel = new BehaviorSubject<VoteProductModel>(new VoteProductModel);
  public connectionCrash = new BehaviorSubject<boolean>(false);
  public commentsList = new BehaviorSubject<CommentModel[]>([]);
  public loadingChart = new BehaviorSubject<boolean>(true);
  public subscriptionEvents: Subscription;
  public likeIcon = new BehaviorSubject<string>('bookmark-outline');
  public archivProduct = new BehaviorSubject<ArchivProduct[] | undefined>([]);
  public addComments = new BehaviorSubject<boolean>(false);
  public addCommentMainEmit = new Subject<string>();
  public iswebMobile = this.platform.is('mobileweb');
  @Input() public product: ProductsModel;
  @Input() public isGuest: boolean;
  @Output() public addVote = new EventEmitter<ProductsModel>();
  @Input() public onLike = (product: ProductsModel) => {};
  public selectedSegment = 'about';
  basicAlertCard: any;
  constructor(private admob: AdMobService, 
    private toastController: ToastService,
    private clipboard: Clipboard, 
    public alertCtrl: AlertController, 
    private modal: ModalManagerService,
    private prevroute: PreviousRouteService,
    private productService: ProductsService, 
    private navCtrl: NavController, 
    private _route: ActivatedRoute,
    private platform: Platform,
    private affiliate: AffiliateService,
    private authService: AuthService,
    private modalCtr: ModalController) {
      this.plt = localStorage.getItem('platform');
  }
  
  ngOnDestroy(): void {
    this.subscriptionEvents?.unsubscribe();
  }

  ngOnInit() {
    if(this.product){
      this.loadingProductIfToken.next(false);
      this.admob.showBaner();
      this.likeIcon.next(this.product.liked == 0 ? 'bookmark-outline' : 'bookmark');
      this.getArchivData();
      this.getVoteCount();
    }else{
      this.subscriptionEvents = this._route.params.subscribe((params: Params) => {
        var token = params['id']?.toString();
        if(token){
          this.productService.getProductByToken(token).then(prod => {
            if(prod){
              this.modal.create({
                component: ProductMobileComponent,
                componentProps: {
                  onLike: (product: ProductsModel) => {
                    this.addLike(product);
                  },
                  'product': prod,
                  'isGuest': false
                }
              });
            }else{
              this.presentAlert("Brak", "Nie znaleziono produktu");
            }
          });
        }
      });
    }
  }

  changeFilterChart(){
    this.loadingChart.next(true);
    var current = new Date();
    var chartData = this.getFilterMainData(current);
    this.lineChartData.labels = chartData.labels;
    this.chart.chart.data.datasets[0].data = chartData.normal;
    this.chart.chart.data.datasets[1].data = chartData.discounts;
    
    this.loadingChart.next(false);
    this.chart.chart.update();
  }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  showBasicAlert(fruits) {
    this.presentAlert(fruits.name, fruits.descriptions);
  }

  getImage(){
    return this.product.image;
  }

  async presentAlert(headerMsg, msg) {
    const alert = await this.alertCtrl.create({
      header: headerMsg,
      message: msg,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: (Cancle) => {
          }
        }, {
          text: 'OK',
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }

  goBack() {
    this.navCtrl.back();
  }

  public async getVoteCount(){
    await this.productService.getVoteCount(this.product._id).then((response) =>{
      this.voteModel.next(response);
    });
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  public openUrl(url: string){
    this.affiliate.createStrategy(url).generateUrl();
  }

  public selectedChartFilterMonthOption(item: any){
    this.selectedChartFilterMonth = item;
    this.changeFilterChart();
  }

  getFilterMainData(current: Date){
    var range = 0;
    var searchDateBorder =  new Date(current);
    var priceNormalArray: number[] = [];
    var priceDiscountArray: number[] = [];
    var labels: string[] = [];
    switch(this.selectedChartFilter){
      case "w":
        range = this.searchRange[0];
        searchDateBorder =  new Date(current.setMonth(current.getMonth()-1));
        break;
      case "m":
        range = this.searchRange[1];
        searchDateBorder =  new Date(current.setMonth(current.getMonth()-1));
        break;
      case "6m":
        range = this.searchRange[2];
        searchDateBorder =  new Date(current.setMonth(current.getMonth()-6));
        break;
      case "r":
        range = this.searchRange[3];
        searchDateBorder =  new Date(current.setMonth(current.getMonth()-12));
        break;
    }
  
    var searchDateBorder =  new Date(current.setMonth(current.getMonth()-1));
    var lastDay = current?.getUTCDate();
    var position = range;
    var lastPriceAsignmentPosition = range;

    var iterationLastDay = current.getDate();
    var iterationDate = new Date(current);

    for(var q=0; q < range; q++){
      priceNormalArray.push(0);
      priceDiscountArray.push(0);
      labels.push(iterationLastDay.toString());
      iterationLastDay--;
      if(iterationLastDay <= 0){
        iterationDate = new Date(iterationDate.setMonth(iterationDate.getMonth()-1))
        iterationLastDay = this.daysInMonth(current.getFullYear(), current.getMonth() + 1);
      }
    }
    labels = labels.reverse();

    this.archivProduct.getValue()
    .filter(x => new Date(x.searchDate) > searchDateBorder)
    .sort((a,b) => new Date(b.searchDate).getTime() - new Date(a.searchDate).getTime())
    .slice(0, range)
    .forEach(item =>{ 
      if(position <= 0) return;
      var day = new Date(item.searchDate)?.getUTCDate();
      var diverenceBetweenDays = lastDay - day;
      while(position > 0 && diverenceBetweenDays > 0){
        priceNormalArray[position - 1] = item.available == 0 ? 0 : item.oryginalPrice;
        priceDiscountArray[position - 1] = item.available == 0 ? 0 : item.price;
        position--;
        diverenceBetweenDays--;
      }
      if(position <= 0) return;
      lastPriceAsignmentPosition = position - 1
      priceNormalArray[lastPriceAsignmentPosition] = item.available == 0 ? 0 : item.oryginalPrice;
      priceDiscountArray[lastPriceAsignmentPosition] = item.available == 0 ? 0 : item.price;
      position--;
      lastDay = day;
    });

    return { discounts: priceDiscountArray, normal: priceNormalArray, labels: labels};
  }

  public async getArchivData(){
    await this.productService.getArchivData(this.product).then((response) =>{
      this.archivProduct.next(response);
      if(response != undefined){
        var current = new Date();
        var chartData = this.getFilterMainData(current);
        this.lineChartData.labels = chartData.labels;
        this.lineChartData.datasets.push(
          {
          data: chartData.normal,
          label: 'Orginalna cena',
          tension: 0,
          backgroundColor: 'rgba(128,128,128,0.7)',
          borderColor: 'rgba(128,128,128,0.7)',
          pointBackgroundColor: 'rgba(28,128,128,0.7)',
          pointBorderColor: 'rgba(28,128,128,0.7)',
          pointStyle: 'circle',
          pointRadius: 3,
          pointHoverRadius: 4.5
        });
        this.lineChartData.datasets.push(
          {
          data: chartData.discounts,
          label: 'Promocyjna cena',
          tension: 0,
          backgroundColor: 'rgba(255,0,0,0.7)',
          borderColor: 'rgba(255,0,0,0.7)',
          pointBackgroundColor: 'rgba(255,0,0,0.3)',
          pointBorderColor: 'rgba(255,0,0,0.7)',
          pointStyle: 'circle',
          pointRadius: 3,
          pointHoverRadius: 4.5,
        });
      }
      this.loadingChart.next(false);
    });
  }

  public async sendVote(status: number){
    if(this.voteModel.getValue().voted != -1 || this.voted){
      return;
    }
    
    this.voted = true;
    await this.productService.addVote(this.product._id, status).then(async (response: string) =>{  
      if(response == "1"){
        var newModel = this.voteModel.getValue();
        newModel.voted = status;
        this.voteModel.next(newModel);
        this.product.voteSum = newModel?.voteSum?.sum;
        this.addVote.emit(this.product);
        await this.getVoteCount();
      }else{
        this.voted = false;
      }
    });
  }
  
  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalCtr.dismiss(closeModal);
    if(this.prevroute?.getPreviousUrl().includes("/product/")){
      this.navCtrl.navigateForward('dashboard');
    }
  }

  public async addComment(){
    this.addCommentMainEmit.next(this.message);
    this.message = "";
  }

  public async getComments(){
    await this.productService.getComments(this.product._id, this.comments_number, this.commnets_limit).then((response) =>{
      if(response == null){
        this.connectionCrash.next(true);
        return;
      }

      this.connectionCrash.next(false);
      this.commentsList.next([...this.commentsList.getValue(),...response]);
      this.comments_number = this.commentsList.getValue().length;
      if(this.commentsList.getValue().length > 0){
        this.showComments.next(true);
      }else{
        this.showComments.next(false);
      }
    });
  }

  public getCountrFlag(country: string){
    return 'assets/icon/country/' + country + '.svg';
  }

  public coptToClibord(text: string){
    this.clipboard.copy(text);
  }

  public getSite():string{
    return this.product.site;
  }

  public generateToken(){
    this.productService.generateProductToken(this.product._id).then(async x =>{
      this.clipboard.copy("http://amazpep.com/#/product/" + x);
      this.toastController.presentToast('bottom', 'Link skopiowany');
    });
  }

  tabID = 'Styczeń';

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [
      'Orginalna cena', 'Promocyjna cena'
    ],
    datasets: [],
  };

  public lineChartLabels: Array<any> = ['0', '1', '2', '3', '4', '5', '6'];

  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false,
    plugins: {
      title: {
        display: true,
        text: 'Wykres cen'
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Cena w zł'
        }
      },
      x: {
        title: {
          display: true,
          text: 'dni'
        }
      }
    }
  };

  public lineChartLegend = {
    display: true,
    position: 'top',
    labels: {
      pointStyle: 'circle'
    }
  };
  public lineChartType = 'line';

  public  async addLike(product: ProductsModel){
    this.onLike(product);
    if(this.likeIcon.getValue() == 'bookmark'){
      this.likeIcon.next('bookmark-outline');
    }else{
      this.likeIcon.next('bookmark');
    }
  }
}