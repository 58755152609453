<ion-tabs>
    <ion-tab-bar slot="bottom" *ngIf="isMobile && (showTabs | async)">
      <ion-tab-button tab="dashboard">
        <ion-icon class="icon-inactive" name="cart-outline"></ion-icon>
        <ion-icon class="icon-active" name="cart-sharp"></ion-icon>
      </ion-tab-button>
      <ion-tab-button tab="list?type=Amazon&main=undefined">
        <ion-icon class="icon-inactive" name="logo-amazon"></ion-icon>
        <ion-icon class="icon-active" name="logo-amazon"></ion-icon>
      </ion-tab-button>
  
      <ion-tab-button tab="liked">
        <ion-icon class="icon-inactive" name="flame-outline"></ion-icon>
        <ion-icon class="icon-active" name="flame"></ion-icon>
      </ion-tab-button>
  
      <ion-tab-button tab="profile">
        <ion-icon class="icon-inactive" name="person-outline"></ion-icon>
        <ion-icon class="icon-active" name="person"></ion-icon>
      </ion-tab-button>

      <ion-tab-button tab="info">
        <ion-icon class="icon-inactive" name="help-outline"></ion-icon>
        <ion-icon class="icon-active" name="help"></ion-icon>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
  