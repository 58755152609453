import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { AlertController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ProductsService } from 'src/app/services/products/products.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ChartConfiguration, ChartOptions } from "chart.js";
import { BaseChartDirective } from 'ng2-charts';
import { Sites } from 'src/app/models/dashboard/filter.model';
import { ArchivProduct } from 'src/app/models/products/archivProduct.model';
import { CommentModel, CommentVote, ProductsModel } from 'src/app/models/products/product.model';
import { VoteProductModel } from 'src/app/models/products/vote-product.model';
import { AdMobService } from 'src/app/services/admob/admob.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AffiliateService } from 'src/app/services/affiliate/affiliate.service';
import { UserData } from 'src/app/models/user.model';
import { ValidationHelper } from 'src/app/helpers/validation-helper';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { EditCommandViewComponent } from 'src/app/comment-list/edit-comment/edit-comment.component';
import { CommentMenuViewComponent } from 'src/app/comment-list/comment-menu/comment-menu.component';

@Component({
  selector: 'product-browser',
  templateUrl: 'product.component.html',
  styleUrls: ['../assets/css/main.css','../assets/css/libs.min.css', './product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductBrowserComponent implements OnInit, OnDestroy {
  comments_number = 0;
  commnets_limit = 10;

  public sitesEnum: typeof Sites = Sites;
  public message: string = "";
  public messageComment = "";
  public showComments = new BehaviorSubject<boolean>(false);
  public selectedChartFilter = "w";
  public selectedChartFilterMonth = "Promocyjna cena";
  public chartMonths = ['Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Pażdziernik',
  'Listopad',
  'Grudzień'];

  public searchRange = [14, 30, 183, 356]
  public chartOptions = ['Orginalna cena', 'Promocyjna cena'];
  plt;
  private voted = false;
  public voteModel = new BehaviorSubject<VoteProductModel>(new VoteProductModel);
  public connectionCrash = new BehaviorSubject<boolean>(false);
  public commentsList = new BehaviorSubject<CommentModel[]>([]);
  public loadingChart = new BehaviorSubject<boolean>(true);
  public likeIcon = new BehaviorSubject<string>('bookmark-outline');
  public archivProduct = new BehaviorSubject<ArchivProduct[] | undefined>([]);
  public addComments = new BehaviorSubject<boolean>(false);
  public addCommentMainEmit = new Subject<string>();
  public subscriptionEvents: Subscription;
  public countryPrices;
  private liked = 0;
  public userData = new BehaviorSubject<UserData>(new UserData());
  public product = new BehaviorSubject<ProductsModel>(new ProductsModel);
  public isGuest: boolean = this.authService.isAuth.getValue();
  @Output() public addVote = new EventEmitter<ProductsModel>();
  @Input() public onLike = (product: ProductsModel) => {};
  public selectedSegment = 'about';
  basicAlertCard: any;
  constructor(private admob: AdMobService, 
    private toastController: ToastService,
    private _route: ActivatedRoute,
    private route: Router,
    public loaderService: LoaderService,
    private clipboard: Clipboard, 
    private alertHelper: AlertHelper,
    public alertCtrl: AlertController, 
    private authService: AuthService,
    private popoverCtrl: PopoverController,
    private validationHelper: ValidationHelper,
    private productService: ProductsService, 
    private navCtrl: NavController, 
    private affiliate: AffiliateService,
    private modalCtr: ModalController) {
      this.plt = localStorage.getItem('platform');
  }

  ngOnDestroy(): void {
    this.subscriptionEvents.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.createLoader();
    this.subscriptionEvents = this._route.params.subscribe((params: Params) => {
      var token = params['id']?.toString();
      this.liked = this._route.snapshot.params.liked;
      if(token){
        this.productService.getUserData().then((response) =>{
          this.userData.next(response);
          this.productService.getProductLikedUser(token, this.userData.getValue().nick).then(x => {
            this.likeIcon.next(x == true ? 'bookmark' : 'bookmark-outline');
          });
        });
        this.productService.getProductByToken(token).then(prod => {
          if(prod){
            this.product.next(prod);
          }else{
            this.presentAlert("Brak", "Nie znaleziono produktu");
          }

          if(this.authService.isAuth.getValue()){
            this.getArchivData();
            this.getVoteCount();
            this.getComments(true, undefined);
          }

          if(this.product.getValue().site == "amazon"){
            this.countryPrices = [{
              price: this.product.getValue().prices.de.price,
              name: 'DE',
              fullName: "Germany"
             },
             {
              price: this.product.getValue().prices.es.price,
              name: 'ES',
              fullName: "Spain"
             },
             {
              price: this.product.getValue().prices.fr.price,
              name: 'FR',
              fullName: "France"
             },
             {
              price: this.product.getValue().prices.it.price,
              name: 'IT',
              fullName: "Italy"
             },
             {
              price: this.product.getValue().prices.pl.price,
              name: 'PL',
              fullName: "Poland"
             },
             {
              price: this.product.getValue().prices.uk.price,
              name: 'UK',
              fullName: "United Kingdom"
             }
             ].sort((a, b) => a.price - b.price);
          }
          
          this.loaderService.dimissLoader();
        });
      }
    });
  }

  doInfinite(event: any) {
    this.getNextItems(false, event);
  }

  async getNextItems(isRefresh: boolean, event: any) {
    await this.getComments(isRefresh, event);
  }

  changeFilterChart(){
    this.loadingChart.next(true);
    var current = new Date();
    var chartData = this.getFilterMainData(current);
    this.lineChartData.labels = chartData.labels;
    this.chart.chart.data.datasets[0].data = chartData.normal;
    this.chart.chart.data.datasets[1].data = chartData.discounts;
    
    this.loadingChart.next(false);
    this.chart.chart.update();
  }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  showBasicAlert(fruits) {
    this.presentAlert(fruits.name, fruits.descriptions);
  }

  getImage(){
    return this.product.getValue().image;
  }

  async presentAlert(headerMsg, msg) {
    const alert = await this.alertCtrl.create({
      header: headerMsg,
      message: msg,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.route.navigate(['dashboard']);
          }
        }
      ]
    });

    await alert.present();
  }

  goBack() {
    this.navCtrl.back();
  }

  public async getVoteCount(){
    await this.productService.getVoteCount(this.product.getValue()._id).then((response) =>{
      this.voteModel.next(response);
    });
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  public openUrl(prod: BehaviorSubject<ProductsModel>){
    this.affiliate.createStrategy(prod.getValue().url).generateUrl();
  }

  public selectedChartFilterMonthOption(item: any){
    this.selectedChartFilterMonth = item;
    this.changeFilterChart();
  }

  getFilterMainData(current: Date){
    var range = 0;
    var searchDateBorder =  new Date(current);
    var priceNormalArray: number[] = [];
    var priceDiscountArray: number[] = [];
    var labels: string[] = [];
    switch(this.selectedChartFilter){
      case "w":
        range = this.searchRange[0];
        searchDateBorder =  new Date(current.setMonth(current.getMonth()-1));
        break;
      case "m":
        range = this.searchRange[1];
        searchDateBorder =  new Date(current.setMonth(current.getMonth()-1));
        break;
      case "6m":
        range = this.searchRange[2];
        searchDateBorder =  new Date(current.setMonth(current.getMonth()-6));
        break;
      case "r":
        range = this.searchRange[3];
        searchDateBorder =  new Date(current.setMonth(current.getMonth()-12));
        break;
    }
  
    var searchDateBorder =  new Date(current.setMonth(current.getMonth()-1));
    var lastDay = current?.getUTCDate();
    var position = range;
    var lastPriceAsignmentPosition = range;

    var iterationLastDay = current.getDate();
    var iterationDate = new Date(current);

    for(var q=0; q < range; q++){
      priceNormalArray.push(0);
      priceDiscountArray.push(0);
      labels.push(iterationLastDay.toString());
      iterationLastDay--;
      if(iterationLastDay <= 0){
        iterationDate = new Date(iterationDate.setMonth(iterationDate.getMonth()-1))
        iterationLastDay = this.daysInMonth(current.getFullYear(), current.getMonth() + 1);
      }
    }
    labels = labels.reverse();

    this.archivProduct.getValue()
    .filter(x => new Date(x.searchDate) > searchDateBorder)
    .sort((a,b) => new Date(b.searchDate).getTime() - new Date(a.searchDate).getTime())
    .slice(0, range)
    .forEach(item =>{ 
      if(position <= 0) return;
      var day = new Date(item.searchDate)?.getUTCDate();
      var diverenceBetweenDays = lastDay - day;
      while(position > 0 && diverenceBetweenDays > 0){
        priceNormalArray[position - 1] = item.available == 0 ? 0 : item.oryginalPrice;
        priceDiscountArray[position - 1] = item.available == 0 ? 0 : item.price;
        position--;
        diverenceBetweenDays--;
      }
      if(position <= 0) return;
      lastPriceAsignmentPosition = position - 1
      priceNormalArray[lastPriceAsignmentPosition] = item.available == 0 ? 0 : item.oryginalPrice;
      priceDiscountArray[lastPriceAsignmentPosition] = item.available == 0 ? 0 : item.price;
      position--;
      lastDay = day;
    });

    return { discounts: priceDiscountArray, normal: priceNormalArray, labels: labels};
  }

  public async getArchivData(){
    await this.productService.getArchivData(this.product.getValue()).then((response) =>{
      this.archivProduct.next(response);
      if(response != undefined){
        var current = new Date();
        var chartData = this.getFilterMainData(current);
        this.lineChartData.labels = chartData.labels;
        this.lineChartData.datasets.push(
          {
          data: chartData.normal,
          label: 'Orginalna cena',
          tension: 0,
          backgroundColor: 'rgba(128,128,128,0.7)',
          borderColor: 'rgba(128,128,128,0.7)',
          pointBackgroundColor: 'rgba(28,128,128,0.7)',
          pointBorderColor: 'rgba(28,128,128,0.7)',
          pointStyle: 'circle',
          pointRadius: 3,
          pointHoverRadius: 4.5
        });
        this.lineChartData.datasets.push(
          {
          data: chartData.discounts,
          label: 'Promocyjna cena',
          tension: 0,
          backgroundColor: 'rgba(255,0,0,0.7)',
          borderColor: 'rgba(255,0,0,0.7)',
          pointBackgroundColor: 'rgba(255,0,0,0.3)',
          pointBorderColor: 'rgba(255,0,0,0.7)',
          pointStyle: 'circle',
          pointRadius: 3,
          pointHoverRadius: 4.5,
        });
      }
      this.loadingChart.next(false);
    });
  }

  public async sendVote(status: number){
    if(this.voteModel.getValue().voted != -1 || this.voted){
      return;
    }
    
    this.voted = true;
    await this.productService.addVote(this.product.getValue()._id, status).then(async (response: string) =>{  
      if(response == "1"){
        var newModel = this.voteModel.getValue();
        newModel.voted = status;
        this.voteModel.next(newModel);
        this.product.getValue().voteSum = newModel?.voteSum?.sum;
        this.addVote.emit(this.product.getValue());
        await this.getVoteCount();
      }else{
        this.voted = false;
      }
    });
  }
  
  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalCtr.dismiss(closeModal);
  }

  public async addComment(){
    if(this.messageComment.length <= 0){
      return;
    }

    let comment: CommentModel = {
      _id: "",
      message: this.messageComment,
      userId: "",
      productId: this.product.getValue()?._id,
      postDate: new Date(),
      blocked: false,
      votes: new CommentVote(),
      isEdited: false,
      editDate: undefined,
    };

    if(this.validationHelper.validateComment(comment.message)){
      await this.productService.addComment(comment).then((response: boolean | string) =>{
        if(response == true){
          this.messageComment = "";
          this.getComments(true, undefined);
        }
        else if(response == false){
          this.alertHelper.presentDefaultAlert("Błąd dodania komentaż",  "Cos poszlo nie tak...",  ["Spróbuj ponownie"]);
        }
        else{
          this.alertHelper.presentDefaultAlert("Błąd dodania komentaż", response);
        }
      });
    }
  }

  public async getComments(isRefresh: boolean, event: any){
    this.loaderService.createLoader();
    var commentsLength = isRefresh ? 0 : this.commentsList.getValue().length;
    await this.productService.getComments(this.product.getValue()?._id, commentsLength, this.commnets_limit).then((response) =>{
      if(response == null){
        this.connectionCrash.next(true);
        return;
      }
    
      this.connectionCrash.next(false);
      if(response.length > 0){
        if(isRefresh){
          this.commentsList.next([...response]);
        }
        else{
          this.commentsList.next([...this.commentsList.getValue(), ...response]);
        }
        this.showComments.next(this.commentsList.getValue().length > 0);
      }
      this.loaderService.dimissLoader();
      event?.target?.complete();
    });
  }

  public getCountrFlag(country: string){
    return 'assets/icon/country/' + country + '.svg';
  }

  public coptToClibord(text: string){
    this.clipboard.copy(text);
  }

  public getSite():string{
    return this.product.getValue().site;
  }

  public generateToken(){
    this.productService.generateProductToken(this.product.getValue()._id).then(async x =>{
      const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = "http://amazpep.com/#/product/" + x;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
      this.toastController.presentToast('bottom', 'Link skopiowany');
    });
  }

  tabID = 'Styczeń';

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [
      'Orginalna cena', 'Promocyjna cena'
    ],
    datasets: [],
  };

  public lineChartLabels: Array<any> = ['0', '1', '2', '3', '4', '5', '6'];

  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false,
    plugins: {
      title: {
        display: true,
        text: 'Wykres cen'
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Cena w zł'
        }
      },
      x: {
        title: {
          display: true,
          text: 'dni'
        }
      }
    }
  };

  public lineChartLegend = {
    display: true,
    position: 'top',
    labels: {
      pointStyle: 'circle'
    }
  };
  public lineChartType = 'line';

  public  async addLike(){
    await this.productService.addLikedProduct(this.product.getValue());
    if(this.likeIcon.getValue() == 'bookmark'){
      this.likeIcon.next('bookmark-outline');
    }else{
      this.likeIcon.next('bookmark');
    }
  }

  public openUrlAmazon(prod: BehaviorSubject<ProductsModel>, country?: string){
    if(country){
      if(country.toLocaleLowerCase() === "ukf"){
        window.open("https://amazon.co." + country + prod.getValue().url, "_blank");
      }else{
        window.open("https://amazon." + country + prod.getValue().url, "_blank");
      }
    }else{
      if(prod.getValue().lowest.country.toLocaleLowerCase() === "ukf"){
        window.open("https://amazon.co." + prod.getValue().lowest.country + prod.getValue().url, "_blank");
      }else{
        window.open("https://amazon." + prod.getValue().lowest.country + prod.getValue().url, "_blank");
      }
    }
    
  }

  async showPopover(event, comment: CommentModel) {
    const popover = await this.popoverCtrl.create({
      component: CommentMenuViewComponent,
      event,
      componentProps: {
        comment: comment,
        onEdit: (comment: CommentModel) => {
          this.showPopoverEdit(event, comment);
          popover.dismiss();
        },
        onDelete: (comment: CommentModel) => {
          this.deleteComment(comment);
          popover.dismiss();
        },
      },
    });
    await popover.present();
  }

  async showPopoverEdit(event, comment: CommentModel) {
    const popover = await this.popoverCtrl.create({
      component: EditCommandViewComponent,
      event,
      componentProps: {
        comment: comment,
        onSuccess: (comment: CommentModel) => {
          this.editComment(comment);
          popover.dismiss();
        },
        onBack: () => {
          popover.dismiss();
        },
      },
    });
    await popover.present();
  }

  deleteComment(comment: CommentModel){
    this.commentsList.next(this.commentsList.getValue().filter(x => x != comment));
    this.showComments.next(this.commentsList.getValue().length > 0);
  }

  editComment(comment: CommentModel){
    this.commentsList.next(this.commentsList.getValue().map(x => {
      if(x._id == comment._id){
        x.message = comment.message;
        x.isEdited = comment.isEdited;
        x.editDate = comment.editDate;
      }

      return x;
    }));
  }
}