<ion-content>
    <div class="main_content_div">
        <ion-img src="assets/imgs/success.jpg"></ion-img>
        <div class="abs_lbl">
            <ion-label class="bold_lbl">Order Error</ion-label>
            <ion-label class="simp_lbl">
                Payment was cancel. Let's check the detail. 
            </ion-label>
            <ion-button (click)="goOrder()">
              Order again
            </ion-button>
        </div>
    </div>
</ion-content>
