import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable()
export class LoadingService {

    constructor(private loadingCtrl: LoadingController){

    }

    async showLoading(): Promise<HTMLIonLoadingElement> {
        const loading = await this.loadingCtrl.create({
          message: 'Loading...',
          cssClass: 'custom-loading',
          backdropDismiss: true,
          translucent: true,
        });
        
        loading.present();
        const { role, data } = await loading.onDidDismiss();
        return loading;
    }
}