import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductsService } from 'src/app/services/products/products.service';
import { CommentModel, CommentVote } from '../../models/products/product.model';
import { AlertHelper } from '../../helpers/alert-helper'

@Component({
  selector: 'comment',
  templateUrl: 'comment.component.html',
  styleUrls: ['comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentComponent implements OnInit, AfterViewInit {

  plt;

  public commentVote = new BehaviorSubject<CommentVote>(new CommentVote());

  public canEdit = new BehaviorSubject<boolean>(false);
  public editCommentMessage = new BehaviorSubject<boolean>(false);
  public displayCommentMessage = new BehaviorSubject<boolean>(true);
  public isEditingCommentMessage = new BehaviorSubject<boolean>(false);
  public isDelitingCommentMessage = new BehaviorSubject<boolean>(false);
  public isNotEditingDeletingCommentMessage = new BehaviorSubject<boolean>(true);

  public commentEditmessage: string = "";

  @Input() public comment: CommentModel;
  @Output() deleteComment = new EventEmitter<any>();

  constructor(private productService: ProductsService, private alertHelper: AlertHelper) {
    this.plt = localStorage.getItem('platform');
  }

  async ngOnInit() {
    this.commentEditmessage = this.comment.message;
 
  }

  async ngAfterViewInit(){
    await this.canEditComment();
    await this.getCommnetVotes();
  }

  public async toggleDeleteComment(){
    var value: boolean = this.isDelitingCommentMessage.getValue();

    this.isDelitingCommentMessage.next(!value);
    this.isNotEditingDeletingCommentMessage.next(value);
  }

  public async abortDeleteComment(){
    this.toggleDeleteComment();
  }

  public async confirmDeleteComment(){
    await this.productService.deleteComment(this.comment).then((response) => {
      if(response){
        let comment = this.comment;
        this.deleteComment.emit({response, comment});
      }else{
        this.alertHelper.presentDefaultAlert("Delete error", "Comment couldn't be deleted");
      }
      this.toggleDeleteComment();
    });
  }

  public async toggleEditComment(){
    var value: boolean = this.editCommentMessage.getValue();

    this.editCommentMessage.next(!value);
    this.displayCommentMessage.next(value);

    this.isEditingCommentMessage.next(!value);
    this.isNotEditingDeletingCommentMessage.next(value);
  }

  public async abortEditComment(){
    this.toggleEditComment();
  }

  public async confirmEditComment(){
    /*if(this.commentEditmessage != this.comment.message){
      let comment: CommentModel = {
        _id: this.comment._id,
        message: this.commentEditmessage,
        userId: this.comment.userId,
        productId: this.comment.productId,
        postDate: new Date(),
        blocked: this.comment.blocked,
        votes: null
      };
  
      await this.productService.editComment(comment).then((response) => {
        if(response){
          this.comment.message = this.commentEditmessage;
          this.commentEditmessage = this.comment.message;
        }
        else{
          this.alertHelper.presentDefaultAlert("Edit error", "Comment couldn't be edited");
        }
        this.toggleEditComment();
      });
    }
    else{
      this.toggleEditComment();
    }*/
  }

  public async canEditComment() {
    this.canEdit.next(await this.productService.canEditComment(this.comment));
  }

  public async getCommnetVotes(){
    await this.productService.getCommentVoteCount(this.comment._id).then((response) => {
      if(response != undefined){
        this.commentVote.next(response as CommentVote);
      }
      else{
        
      }
    });
  }

  public async sendCommentVote(vote: number){
    if(this.commentVote.getValue().voted > 0 && vote > 0 || this.commentVote.getValue().voted == 0 && vote != 0 || this.commentVote.getValue().voted < 0 && vote < 0){
      await this.productService.addCommentVote( this.comment._id, vote).then((response) => {
        if(response){
          this.getCommnetVotes();
        }
        else{
          
        }
      });
    }
  }
}