<div class="main_list_div">
  <div *ngFor="let item of productList; let index = index" [class.desktop-view]="!(isMobile | async)">
    <div class="card_div" [class.section-blur]="blockView && index < 1 && (user_Plan | async) === 0">
      <div class="image_div bg_image" [style.backgroundImage]="'url(data:image/png;base64,' + item.image + ' )'" (click)="openProduct(item)"></div>
      <div [class]="item.voteSum == 0 ? 'vote' : item.voteSum < 0 ? 'vote votes-down' : 'vote votes-up'">{{ item.voteSum }}</div>
      <div class="detail_div">
        <ion-label class="city" (click)="openProduct(item)">{{item.title | slice:0:80}}</ion-label>
        <ion-grid fixed>
          <ion-row>
            <ion-col size="3">
              <ion-icon [name]="iconSelect(item)" class="icon-like" (click)="addLike(item)"></ion-icon>
            </ion-col>
            <ion-col size="4" (click)="openProduct(item)" *ngIf="shouldShowCountryForSite(item)"> 
              <ion-icon name="planet-outline"></ion-icon>
              <ion-label>{{item.lowest.country | uppercase}}</ion-label>
            </ion-col>
            <ion-col size="4" style="border-right: none; z-index: 999;" (click)="openProduct(item)">
              <ion-icon name="cash-outline"></ion-icon>
              <ion-label>{{getPrice(item)}} zł</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>

    <div class="card_div" style="width: 90%; background-color: #27273c;" *ngIf="blockView && index < 1 && (user_Plan | async) === 0">
      <div class="detail_div">
        <ion-icon class="icon_more_product" name="copy-outline"></ion-icon>
        <ion-label class="more_product" (click)="openProduct(item)">+ 5 produktów</ion-label>
        <ion-button class="button_unlock" size="small" slot="end" (click)="goUnlock()">Unlock</ion-button>
      </div>
    </div>
  </div>
</div>