import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { ValidationHelper } from 'src/app/helpers/validation-helper';
import { MongoDBRequest } from 'src/app/models/auth/mongodb-response.model';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'register-browser',
  templateUrl: 'register.component.html',
  styleUrls: ['../../assets/css/main.css','../../assets/css/libs.min.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterBrowserViewComponent implements OnInit {
  public userModel = new MongoDBRequest();
  public isSending = new BehaviorSubject<boolean>(true);
  public isSending$ = new Observable<boolean>();
  constructor(
    private navCtrl: NavController, 
    private authService: AuthService,
    private alertHelper: AlertHelper,
    private validationHelper: ValidationHelper) {
  }

  ngOnInit() {
    if(this.authService.isAuth.getValue() == true){
      this.navCtrl.navigateRoot('dashboard');
    }

    this.isSending$ = this.isSending.asObservable();
  }
  
  goLogin()  {
    this.navCtrl.navigateForward('login');
  }

  public async sendRegisterRequest(){
    if(!this.checkInputs()){
      return;
    }
    this.isSending.next(!this.isSending.getValue());
    var result = await this.authService.createRegisterRequest(this.userModel);

    if(result === true){
      this.navCtrl.navigateForward('dashboard');
    }else if(result === false || result === null){
      this.alertHelper.presentDefaultAlert("Something is wrong", "Check all data or connection", ["Try again"]);
    }
    else{
      this.alertHelper.presentDefaultAlert("Insufficient data", result as string, ["Try again"]);
    }
    this.isSending.next(!this.isSending.getValue());
  }

  private checkInputs(){

    if(!this.validationHelper.validateNick(this.userModel.nickname))
    {
      return false;
    }
    
    if(!this.validationHelper.validateEmail(this.userModel.email))
    {
      return false;
    }

    if(!this.validationHelper.validatePassword(this.userModel.password))
    {
      return false;
    }
  
    if(!this.userModel.inviteCode){
      this.alertHelper.presentDefaultAlert("Insufficient data", "Invite code is required!", ["Try again"]);
      return false;
    }

    return true;
  }
}