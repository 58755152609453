<ion-content>
    <div class="main_content_div">
        <ion-img src="assets/imgs/success.jpg"></ion-img>
        <div class="abs_lbl">
            <ion-label class="bold_lbl">Order Success</ion-label>
            <ion-label class="simp_lbl">
                Now, You're Connected and can continue explore. Let's check the detail. 
            </ion-label>
            <ion-button (click)="goDashboard()">
              Back To Home
            </ion-button>
        </div>
    </div>
</ion-content>
