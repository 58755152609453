<div class="page-wrapper">
  <header class="page-header">
      <div class="page-header__inner">
          <div class="page-header__sidebar">
              <div class="page-header__menu-btn"><button class="menu-btn ico_menu"></button></div>
              <div class="page-header__logo" style="cursor: pointer;" [routerLink]="['/dashboard']"><img src="../../assets/imgs/logoDark.png" alt="logo"></div>
          </div>
          <div class="page-header__content">
              <div class="page-header__search">
                  <div class="search">
                      <div class="search__input"><i class="ico_search"></i><input (keyup.enter)="onEnter()" [(ngModel)]="searchTextValue" id="searchBar" (ionFocus)="focusSearch()"  (keyup)="onKeySearch($event)" (click)="startSearch()" type="text" placeholder="Wyszukaj..."></div>
                  </div>
              </div>
              <div class="page-header__action">
                <a class="action-btn" href="https://www.facebook.com/profile.php?id=100087825745624">
                    <ion-icon name="logo-facebook"></ion-icon>
                </a>
                <a class="action-btn" href="#!" [routerLink]="['/help']">
                    <i class="ico_help"></i>
                </a>
                <a class="action-btn" href="#!" [routerLink]="['/liked']">
                    <i class="ico_favourites"></i>
                </a>
                <a [routerLink]="['/profile']" class="profile" href="#!"><img src="assets/imgs/profile.png" alt="profile"></a>
            </div></div>
      </div>
  </header>
  <div class="page-content">
    <aside class="sidebar is-show" id="sidebar">
        <div class="sidebar-box">
          <ul class="uk-nav">
            <li [routerLink]="['/dashboard']"><a href=""><i class="ico_home"></i><span>Strona Główna</span></a></li>
            <li class="uk-nav-header">Popularne</li>
            <li><a href="09_games-store.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnie', main: ''}"><i class="ico_store"></i><span>Ostatnio znalezione</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnidzien', main: 'undefined'}"><i class="ico_report"></i><span>Ostatni dzień</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne3dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 3 dni</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatne7dni', main: 'undefined'}"><i class="ico_report"></i><span>Ostatnie 7 dni</span></a></li>
            <li class="uk-nav-header">Sklepy</li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaExpert', main: 'undefined'}"><i class="ico_market"></i><span>MediaExpert</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'EuroRtvAgd', main: 'undefined'}"><i class="ico_market"></i><span>EuroRtvAgd</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'MediaMarkt', main: 'undefined'}"><i class="ico_market"></i><span>MediaMarkt</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'XKom', main: 'undefined'}"><i class="ico_market"></i><span>X-Kom</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Amazon', main: 'undefined'}"><i class="ico_market"></i><span>Amazon</span></a></li>
            <li class="uk-nav-header">Pomoc</li>
            <li class="uk-active"><a href="11_market.html" [routerLink]="['/help']" [queryParams]=""><i class="ico_market"></i><span>Kontakt</span></a></li>
        </ul>
        </div>
    </aside>
      <main class="page-main">
            <div class="contact-form-header">
                <ion-label >Skontaktuj się z nami</ion-label>
                
            </div>
            <ul class="social">
                <li><a href="https://www.facebook.com/profile.php?id=100087825745624"></a></li>
            </ul>
            <div class="uk-grid help-contact-form-1" data-uk-grid>
                <ion-grid fixed>
                    <ion-row>
                        <ion-col>
                            <ion-label>Imię i Nazwisko</ion-label>
                            <ion-input type="text" placeholder="Imię i Nazwisko" required [(ngModel)]="NameAndSurname"></ion-input>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>Email</ion-label>
                            <ion-input type="email" placeholder="Email" required [(ngModel)]="Email"></ion-input>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>Wiadomość</ion-label>
                            <ion-textarea type="text" placeholder="Wiadomość" required [(ngModel)]="Message" rows="5"></ion-textarea>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>   
                            <ion-button style="--background: #F46119;" expand="block" (click)="sendMessage()">
                                Wyślij
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
          </div>
      </main>
  </div>
</div>