import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen'
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { StorageService } from '../services/storage/storeage.service';
@Component({
  selector: 'start-view',
  templateUrl: 'start-view.component.html',
  styleUrls: ['start-view.component.scss']
})
export class StartViewComponent implements OnChanges {

    plt;
    public subscriptionEvents: Subscription;
    constructor(private navCtrl: NavController, private authService: AuthService, private _route: ActivatedRoute) {
      this.plt = localStorage.getItem('platform');
    }

    ionViewDidLeave(): void {
      this.subscriptionEvents.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
      if(this.authService.isAuth.getValue() === true){
        this.navCtrl.navigateRoot('dashboard');
      }
    }
  
    ngOnInit() {
      this.subscriptionEvents = this._route.queryParamMap
        .subscribe((params: any) => {
          if(this.authService.isAuth.getValue() == true){
            this.navCtrl.navigateRoot('dashboard');
          }
        });
        if(this.authService.isAuth.getValue() === true){
            this.navCtrl.navigateRoot('dashboard');
        }
    }
  
    goBack() {
      this.navCtrl.back();
    }
  
    goLogin(){
        this.navCtrl.navigateRoot("start");
    }
  }