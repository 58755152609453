import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ProductsService } from "src/app/services/products/products.service";
import { CountryFilter } from "../dashboard/filter.model";
import { agd } from "./models/agd.model";
import { Category } from "./models/categorysEnum.model";
import { SearchTags, ShopCategoryResponse, ShopSubCategoryResponse } from "./models/dbCategory.model";
import { electronic } from "./models/electronic.model";
import { popular } from "./models/popular.model";

@Injectable()
export class CategoryFactory{
    
    public category = new BehaviorSubject<ShopCategoryResponse[]>([]);
    private firstLoad = true;
    constructor(public productService: ProductsService){
        this.productService.getCategory().then(item => {
            this.category.next(item);
            this.firstLoad = false;
        });
    }

    public getMainCategory(){
        return this.category.getValue()?.map(x => x.MainCategory);
    }

    public getPopularCategory(): ShopSubCategoryResponse[]{
        return popular as ShopSubCategoryResponse[];
    }

    public getAllTags(): SearchTags[]{
        var newData: SearchTags[] = [];
        this.category.getValue().forEach(x => {
            x.Categories.forEach(y => {
                y.Tags.forEach(z => {
                    newData.push({Category: y.Category, Tag: z, Main: x.MainCategory});
                });
            });
        });

        return newData;
    }

    public async getCategory(category: string): Promise<ShopSubCategoryResponse[]>{
        return await new Promise(resolve => {
            this.category.subscribe(x => {
                if(x){
                    if(category == "Popular"){
                        resolve(popular);
                        return;
                    }

                    if(x.length > 0){
                        resolve(x?.find(x => x.MainCategory == category).Categories);
                    }else{
                        resolve([]);
                    }
                    
                    return;
                }
            });
        });
    }

    public async getTags(mainCategory: string, category: string){
        var result = await new Promise(resolve => {
            if(!this.firstLoad){
                var main = this.category.getValue()?.find(x => x.MainCategory == mainCategory)?.Categories;
                if(category == "Wszystko"){
                    var newData = [];
                    main?.forEach(x => {
                        x.Tags.forEach(y => {
                            newData.push(y);
                        });
                    });
                    resolve(newData);
                }

                resolve(main?.find(x => x.Category == category).Tags);
            }else{
                setTimeout(() => {
                    this.category.subscribe(x => {
                        if(x){
                            var main = x?.find(x => x.MainCategory == mainCategory)?.Categories;
                            if(category == "Wszystko"){
                                var newData = [];
                                main?.forEach(x => {
                                    x.Tags.forEach(y => {
                                        newData.push(y);
                                    });
                                });
                                resolve(newData);
                            }

                            resolve(main?.find(x => x.Category == category)?.Tags);
                        }
                    });
                }, 5000);
            }
        });
        
        return result as string[];
    }

    public downloadProduct(category: string, tags: string[], page: number = 1, limit: number = 10, page_to_skip: number = 0, 
        minPrice: string | number, maxPrice: string | number, 
        discountRange: string | number, site: string[], allProduct: boolean | string = undefined, 
        sortBy: number | string, countryList: CountryFilter[], onlyAmazon: string |boolean, searchItem?: undefined | string, date?: number){
        return this.productService.getShopCategoryProducts(category, tags, page, limit, page_to_skip, 
            minPrice, maxPrice, discountRange, site, allProduct, sortBy, searchItem,countryList, onlyAmazon, date);
    }
}