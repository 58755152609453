import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { ValidationHelper } from 'src/app/helpers/validation-helper';
import { UserData } from 'src/app/models/user.model';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'edit-profile',
  templateUrl: 'edit-profile.component.html',
  styleUrls: ['edit-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProfileBrowserViewComponent{
  public tabs: string[] = [];
  public tabIndex: number;
  @Input() userData: UserData;
  public isSending = new BehaviorSubject<boolean>(true);
  public isSending$ = new Observable<boolean>();
  constructor(public modalCtrl: ModalController,
    private alertHelper: AlertHelper,
    private modalCtr: ModalController,
    private productService: ProductsService,
    private validationHelper: ValidationHelper) {
  }

  async goBack() {
    await this.modalCtr.dismiss();
  }

  async edit(){
    if(this.checkInputs()){
      await this.productService.changeUserData("email", this.userData.email).then(async result =>{
        if(result == undefined){
          this.alertHelper.presentDefaultAlert("Sukces", "Poprawnie wykonano operacje", ["Ok"])
          await this.modalCtr.dismiss();
        }else{
          this.alertHelper.presentDefaultAlert("Cos poszło nie tak",  "Sprawdź dane jeszcze raz!",  ["Spróbuj ponownie"]);
        }
      });
    }
  }

  private checkInputs(){
    if(!this.validationHelper.validateEmail(this.userData.email))
    {
      return false;
    }else{
      return true;
    }
  }
}