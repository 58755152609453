import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { io } from "socket.io-client";

@Injectable({
    providedIn: 'root'
  })
export class SocketIOService {
    public socket;
    public pause = false;
    public serverStatus = new BehaviorSubject<boolean>(true);
    public firstRun = new BehaviorSubject<boolean>(true);
    constructor(){
    }

    public getSocket(): any{
        return this.socket;
    }

    public newConnect(token?: string){
        this.socket.disconnect()
        this.socket.connect();
    }

    public disconnect(){
        console.log("Socket disconnect");
        this.socket.emit('disconnectSocket');
        this.socket.disconnect();
    }

    public async createConnection(){
        this.socket = io("https://api.amazpep.com:3001", {
            query: {
                token: localStorage.getItem("ACCESS_TOKEN")
            },
            transports: ['websocket'],
            upgrade: false
        });

        return await this.addEndpoint();
    }

    private async addEndpoint(){
        return await new Promise((resolve, reject) => {
            this.socket.on("connect_error", (err) => {
                if(this.pause){
                   return; 
                }
                this.newConnect();
                //console.log(err instanceof Error); // true
                //console.log(err.message); // not authorized
                this.firstRun.next(false);
                resolve(false);
              });
    
            this.socket.on("connect", () => {
                this.firstRun.next(false);
                this.serverStatus.next(false);
                
                //console.log("Connect");
                resolve(true);
            });
            this.socket.on("disconnected", () => {
                this.firstRun.next(false);
                this.newConnect();
                resolve(false);
            });
              this.socket.io.on("error", () => {
                if(this.pause){
                    return; 
                }
                this.newConnect();
                this.firstRun.next(false);
                this.serverStatus.next(false);
                resolve(false);
            });
        });
    }
}