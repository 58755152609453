<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-button (click)="goBack()" fill="clear" slot="start">
            <ion-icon name="arrow-back" style="font-size: 26px;color: black"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
  <div class="main_content_div">

      <ion-label class="welcome_lbl">Edytuj komentarz</ion-label>

      <ion-item>
          <ion-label position="floating">Wiadomość</ion-label>
          <ion-input [(ngModel)]="comment.message"></ion-input>
      </ion-item>
      <ion-button expand="block" (click)="edit()">
          Zapisz
      </ion-button>

  </div>
</ion-content>
