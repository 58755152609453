<div class="main_content_div">
  <div class="">
    <ul class="timeline">
      <li>
        <div class="timeline-item">
          <h5>{{comment.userId}}</h5>
          <p>{{comment.message | slice:0:400}}</p>
          <div class="timeline-stats">
            <span><i class="ion-ios-clock"></i>{{comment.postDate | dateAsAgo}}</span>
            <span class="comment-votes pos-absolute-right">
              <span class="comment-upvotes">{{(commentVote | async).ups}}</span>
              <button color="primary" class="button-upvote" (click)="sendCommentVote(1)" [ngClass]="(commentVote | async).voted <= 0 ? 'notactive':'active'">
                <ion-icon color="ion-comment-up-vote-color" *ngIf="(commentVote | async).voted <= 0" name="thumbs-up-outline"></ion-icon>
                <ion-icon color="ion-comment-standard-vote-color" *ngIf="(commentVote | async).voted == 1" name="thumbs-up-outline"></ion-icon>
              </button>
              <span class="comment-downvotes">{{(commentVote | async).downs}}</span>
              <button color="primary" class="button-downvote" (click)="sendCommentVote(-1)" [ngClass]="(commentVote | async).voted >= 0 ? 'notactive':'active'">
                <ion-icon color="ion-comment-down-vote-color" *ngIf="(commentVote | async).voted >= 0" name="thumbs-down-outline"></ion-icon>
                <ion-icon color="ion-comment-standard-vote-color" *ngIf="(commentVote | async).voted == -1" name="thumbs-down-outline"></ion-icon>
              </button>
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>