<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-button (click)="goBack()" fill="clear" slot="start">
            <ion-icon name="arrow-back" style="font-size: 26px;color: black"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
  <div class="main_content_div">
    <ion-label class="welcome_lbl">Kody</ion-label>
    <ion-list>
        <div class="shopping_div" *ngFor="let item of (inviteCodes | async)">
            <div class="color_box" [ngClass]="{'used': item.used.length > 0}">
                <ion-icon name="person-add-outline"></ion-icon>
            </div>
            <div class="content_div" (click)="copyLink(item)">
                <ion-label class="name">{{item.token}}</ion-label>
                <ion-label *ngIf="item.used.length > 0" class="date">Użyty przez: {{item.used}}</ion-label>
            </div>
        </div>
      </ion-list>
  </div>
</ion-content>
