import { Injectable } from "@angular/core";
import { AffiliateModel } from "./models/affiliate.model";
import { DefaultAffiliate } from "./strategy/default.service";
import { EuroAffiliate } from "./strategy/euro.service";
import { KomputronikAffiliate } from "./strategy/komputronik.service";

@Injectable({
    providedIn: 'root'
  })
  export class AffiliateService {
    createStrategy(url: string) : AffiliateModel{
        if(url.indexOf("euro.com.pl") != -1){
            return new EuroAffiliate(url) as AffiliateModel;
        }else if(url.indexOf("komputronik.pl") != -1){
            return new KomputronikAffiliate(url) as AffiliateModel;
        }

        return new DefaultAffiliate(url) as AffiliateModel;
    }
  }