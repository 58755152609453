import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'login-browser',
  templateUrl: 'login.component.html',
  styleUrls: ['../../assets/css/main.css', '../../assets/css/libs.min.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginBrowserViewComponent {
  public nickname: string;
  public password: string;
  public isMobile = true;
  public isSendingRequest = new BehaviorSubject<boolean>(false);
  constructor(private navCtrl: NavController, 
    private authService: AuthService,
    private alertHelper: AlertHelper,){}
  async sendLoginRequest(){
    if(!this.checkInputs()){
      return;
    }

    this.isSendingRequest.next(true);
    var result = await this.authService.createLoginRequest(this.nickname, this.password);
    
    if(result === true){
      this.navCtrl.pop();
      this.navCtrl.navigateRoot('dashboard');
    }else if(result === false || result === null){
      this.alertHelper.presentDefaultAlert("Something is wrong", "Check all data or connection", ["Try again"]);
    }
    else{
      this.alertHelper.presentDefaultAlert("Insufficient data", result as string, ["Try again"]);
    }

    this.isSendingRequest.next(false);
  }

  goRegister()  {
    this.navCtrl.navigateForward('register');
  }

  async handleButtonClick() {
    this.alertHelper.presentDefaultAlert("Something broken", "Your username or password seem to be broken :(", ["Register again"]);
  }

  forgot(){
    this.navCtrl.navigateForward('password-forgot');
  }

  checkInputs(){

    if(!this.nickname){
      this.alertHelper.presentDefaultAlert("Insufficient data", "Nick name is required!", ["Try again"]);
      return false;
    }
    
    if(!this.password){
      this.alertHelper.presentDefaultAlert("Insufficient data", "Password is required!", ["Try again"]);
      return false;
  }

    return true;
  }
}