import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardShareService {  
    constructor(public auth: AuthService, public router: Router, private navCtrl: NavController) {}  
    canActivate(route: ActivatedRouteSnapshot): boolean {
        var routeIndex = route.url.findIndex(x => x.path.includes("product") || x.path.includes("share"));
        
        if(routeIndex != -1){
            return true;
        }else{
            this.navCtrl.navigateRoot(['login']);
        }
    }
}