<ion-content>
    <div class="main_content_div">
  
      <div class="waveWrapper waveAnimation">
        <ion-icon name="arrow-back-outline" class="back_btn" (click)="cancel()"></ion-icon>
        <div class="waveWrapperInner bgTop">
          <div class="wave waveTop" style="background-image: url('assets/imgs/wave-mid.png')"></div>
        </div>
        <div class="waveWrapperInner bgMiddle">
          <div class="wave waveMiddle" style="background-image: url('assets/imgs/wave-mid.png')"></div>
        </div>
        <div class="waveWrapperInner bgBottom">
          <div class="wave waveBottom" style="background-image: url('assets/imgs/wave-mid.png')"></div>
        </div>
      </div>
      <div class="lower_div">
        <ion-list-header class="head_lbl padding-5px">Change yours {{changingPropertyName}}:</ion-list-header>
        <ion-input [(ngModel)]="changingPropertyValue" placeholder="{{changingPropertyName}}"></ion-input>
        <ion-button color="primary" class="button" (click)="change()">Change {{changingPropertyName}}</ion-button>
        <ion-button color="primary" class="button" (click)="cancel()">Cancel</ion-button>
      </div>
    </div>
  </ion-content>