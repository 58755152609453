<ion-content>
  <div class="main_content_div" *ngIf="isMobile">   
    <ion-label class="main_head">Login</ion-label>
    
    <div class="form_div" *ngIf="!(isSendingRequest | async);else isSending">

      <div class="tri"></div>

      <ion-label>Username</ion-label>
      <ion-input type="text" placeholder="Username" [(ngModel)]="nickname"></ion-input>
      <ion-label>Password</ion-label>
      <ion-input type="password" placeholder="Password" [(ngModel)]="password"></ion-input>
      <ion-label class="forgot" (click)="forgot()">Zapomniałem hasła ?</ion-label>
      <ion-button expand="block" (click)="sendLoginRequest()">
        Login
      </ion-button>

      <ion-label class="lbl">Nie masz konta ? <span (click)="goRegister()" class="signup_span">Rejestracja</span></ion-label>
    </div>
    <ng-template #isSending>
      <div class="form_div">
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </div>
    </ng-template>
    
  </div>
  
</ion-content>